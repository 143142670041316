@import "./conf/variables";

.p-dropdown,
.p-calendar,
.p-component:not(.p-checkbox):not(.p-radiobutton):not(.p-button):not(.p-dialog):not(.p-calendar-timeonly),
.p-inputtext,
.main-tab-view {
    width: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
    font-weight: 400;
    font-size: 0.9em;
    text-align: center;
}

.p-datatable .p-datatable-tbody > tr > td {
    font-weight: 400;
    font-size: 0.9em;
}

.action-item {
    a {
        color: #007bff !important;
    }
}

.certacure-color {
    &.bg {
        background-color: $primary-color !important;
    }

    &.text {
        color: $primary-color !important;
    }
}

.p-selectbutton {
    .p-button {
        width: 25%;
    }
}

.p-selectbutton {
    display: flex;
    .p-button {
        flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    }
}

.p-tabview {
    .p-tabview-nav {
        li {
            flex: 1;
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
        }
    }
}

.p-dropdown .p-dropdown-label {
    text-align: left;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-align: left;
    white-space: initial;
    padding: 0.125em 0.45em 0.125em 0.45em;
}

.modal-header {
    padding: 1rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    .close {
        position: absolute;
        right: 15px;
        font-size: 15px;
        opacity: 1;
        text-shadow: none;
        top: 10px;

        span {
            font-size: 2rem;
        }
    }

    .close:not(:disabled):not(.disabled):hover,
    .close:not(:disabled):not(.disabled):focus {
        opacity: 1;
    }

    .modal-title {
        color: $primeng-primary-color-text;
        font-weight: 700;
        font-size: 1rem;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", Roboto, "Helvetica Neue", sans-serif;
    }
}

button {
    outline: none !important;
}

.p-datatable-thead {
    .p-datepicker {
        min-width: 400px;
    }
}

.p-calendar .p-datepicker {
    min-width: 400px !important;
}

.p-datepicker table th {
    text-align: center;
}

.p-radiobutton {
    margin: 0 0.45rem;
}

.p-panel .p-panel-header {
    background-color: $primeng-primary-color;
    color: $primeng-primary-color-text;
    border: 1px solid $primeng-primary-color-border;
}

.p-panel .p-panel-header .p-panel-header-icon,
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: $primeng-primary-color-text;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
    border-color: transparent;
    color: #00000080;
    justify-content: center;
    border-bottom: 2px solid silver;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background-color: transparent;
    border-color: transparent;
    color: $primeng-primary-color;
    border-bottom: 2px solid $primary-color;
    font-weight: 600;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.p-datatable .p-datatable-header {
    background-color: $primeng-primary-color-border;
    border: 1px solid #d5d8dc;
    color: #586069;
}

.p-selectbutton .p-button {
    border-color: silver;
    background-color: #eee;
    color: #212121;
}

.p-selectbutton .p-button.p-highlight {
    background-color: #409ace;
    border-color: #409ace;
    color: $primeng-primary-color;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: $primary-color;
    border-color: $primary-color;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    border: 2px solid $primeng-primary-color-border;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    color: $primeng-primary-color;
    background: $primary-color;
}

.p-dialog .p-dialog-header {
    border-bottom: 1px solid $primeng-primary-color-border;
    background: $primeng-primary-color;
    color: $primeng-primary-color-text;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: $primeng-primary-color-text;
}

button {
    outline: none !important;
    box-shadow: none !important;
}

.p-button.p-button-text.p-button-plain:enabled:active {
    background: transparent;
    color: #848484;
    border-color: transparent;
    box-shadow: none;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
    background: transparent !important;
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: 0.257rem 1rem;
}

.p-dialog-mask.p-component-overlay {
    z-index: 1050 !important;
}

.confirm-dialog {
    .p-dialog-mask.p-component-overlay {
        z-index: 9999 !important;
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: $primary-color !important;
}

.static-dialog {
    .p-dialog {
        width: 90% !important;
        left: 0 !important;
        right: 0 !important;
        min-height: 550px !important;
    }

    .p-dialog-content {
        min-height: 550px;
    }

    .p-dialog .p-dialog-content {
        min-height: 500px;
    }
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: $primary-color;
}

.p-steps .p-steps-item:before {
    // border-top: 10px solid $primary-color;
    // margin-top: -1.4rem;
}

.p-steps-item .p-menuitem-link {
    width: 100%;
}

.p-treenode-label {
    width: 100%;
}

.p-tree .p-tree-container .p-treenode,
.p-tree .p-tree-container .p-treenode .p-treenode-content {
    padding: 0;
}

.action.dropdown {
    text-align: center;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-datatable .p-datatable-tbody > tr.p-highlight {
    .fa-bars {
        color: #fff !important;
    }
}

.p-datatable .p-sortable-column.p-highlight:hover,
.p-datatable .p-sortable-column.p-highlight {
    background: $primary-color;
}

.p-steps ul {
    background-color: #fff;
}

.p-steps .p-steps-item .p-menuitem-link {
    box-shadow: none !important;
}

.p-tree .p-tree-container .p-treenode,
.p-tree .p-tree-container .p-treenode .p-treenode-content {
    box-shadow: none !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: $primary-color;
    border-color: $primary-color;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    background: $primary-color;
    border-color: $primary-color;
}

span.p-highlight,
.p-highlight {
    background: $primary-color !important;
}

.p-autocomplete-panel,
.p-dropdown-panel {
    min-width: 200px !important;
    text-align: left;

    li.p-autocomplete-item {
        width: 100%;
        white-space: pre-line;
    }
}

.p-selectable-row {
    &.p-highlight {
        td > a {
            i {
                color: #fff;
            }
        }
    }
}

button.ui-state-disabled {
    pointer-events: none;
}

.header-checkbox {
    .p-checkbox .p-checkbox-box {
        width: 1.2em;
        line-height: 1.2em;
        height: 1.2rem;
    }
}

.p-editor-container .p-editor-toolbar {
    background: #fff;
    &.ql-snow {
        border: 1px solid #ccc;
    }
}

.p-dropdown,
.p-inputtext {
    border: 1px solid rgba(0, 0, 0, 0.07);
}

.list-group-item-danger {
    color: #721c24 !important;
    background-color: #f5c6cb !important;
}

.list-group-item-success {
    color: #155724 !important;
    background-color: #c3e6cb !important;
}

.p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}
