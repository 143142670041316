body .ui-tree {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  width: 100%;
}

body .ui-tree .ui-widget {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  text-decoration: none;
  widows: 100%;
}

body .ui-tree .ui-tree-container {
  padding: 0.286em;
  margin: 0;
}
body .ui-tree .ui-tree-container .ui-treenode {
  padding: 0.143em 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  padding: 0;
  border: 1px solid transparent;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #848484;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #848484;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
  margin: 1px 0 0 0;
}
body
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content.ui-treenode-selectable
  .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
  line-height: inherit;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
  background-color: #eaeaea;
  color: #333333;
}
body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
  background-color: #007ad9;
}
body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
  background-color: #007ad9;
  color: #ffffff;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
  color: #007ad9;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body
  .ui-tree.ui-tree-horizontal
  .ui-treenode
  .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #eaeaea;
  color: #333333;
}
