$default: #f4f4f4; // default colors for layout
/*$body-bg: #F0F3F4; // default bg color*/
$body-bg: $default; // default bg color

$default-text: #212121; // default text color layout (usually is the same as $default)
$content-text: $default-text; // content text color on card, panels, form, etc
$help-text: $default-text; // additional text color for hits

$label-text: $default-text; // labels color
$disabled: rgba(255, 255, 255, 0.4); // color for disabled state
$disabled-bg: tint($disabled, 15%); // background color for disable state
$border: #d6d6d6; // border color
$border-light: rgba(255, 255, 255, 0.3); // lighter version of border color (for hovers)
$input-border: rgba(255, 255, 255, 0.6); // input border color
$input-background: rgba(255, 255, 255, 0.1); // input border color for hover
$dropdown-text: #7d7d7d; // dropdown text color

// sidebar colors
$sidebar: #f5f5f5;
$sidebar-text: rgb(80, 97, 120);

// topnav colors
$topnav: #007ad9;
$topnav-text: #fff;

// specific components colors
$mail-box: whitesmoke;
$auth-panel-background: #ffffff;
$progress-background: rgba(0, 0, 0, 0.15);
$progress-default: rgba($default-text, 0.95);

// bootstrap card panel styles
$bootstrap-panel-radius: 7px;
$bootstrap-panel-text: #ffffff;
$bootstrap-panel-bg: rgb(255, 255, 255);
$bootstrap-panel-header-bg: transparent;
$bootstrap-panel-header-border: 1px solid $border-light;
$bootstrap-panel-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);

// color scheme
$primary: #00abff !default;
$info: #40daf1 !default;
$success: #82e27d !default;
$warning: #e7ba08 !default;
$danger: #f95372 !default;
$inverse: #212121 !default;

$primary-light: tint($primary, 30%);
$info-light: tint($info, 30%);
$success-light: tint($success, 30%);
$warning-light: tint($warning, 30%);
$danger-light: tint($danger, 30%);

$primary-dark: shade($primary, 15%);
$info-dark: shade($info, 15%);
$success-dark: shade($success, 15%);
$warning-dark: shade($warning, 15%);
$danger-dark: shade($danger, 15%);

$primary-bg: tint($primary, 20%);
$info-bg: tint($info, 20%);
$success-bg: tint($success, 20%);
$warning-bg: tint($warning, 20%);
$danger-bg: tint($danger, 20%);

// link colors
$activelink: #285eb8;
$hoverlink: darken($activelink, 20%);

// main background
@mixin body-bg() {
    background-color: $body-bg;
    // $mainBgUrl: $images-root + 'light-gray-bg.jpg';
    &::before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background: url($mainBgUrl) no-repeat center center;
        background-size: cover;
        will-change: transform;
        z-index: 0;
    }
}

// additional background if you want another layer
@mixin additional-bg() {
    //display: block; // additional background layer, hidden by default
}
