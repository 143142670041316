@font-face {
    src: url("#{$fonts-root}socicon.eot");
    src: url("#{$fonts-root}socicon.eot?#iefix") format("embedded-opentype"),
        url("#{$fonts-root}socicon.woff") format("woff"), url("#{$fonts-root}socicon.woff2") format("woff2"),
        url("#{$fonts-root}socicon.ttf") format("truetype"),
        url("#{$fonts-root}socicon.svg#sociconregular") format("svg");
    font-style: normal;
    text-transform: initial;
}

.socicon {
}

.socicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-style: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.socicon:empty {
    width: 1em;
}

@mixin socicon($background, $content) {
    background-color: $background;

    &:before {
        content: $content;
    }
}
