$default-font-size: 14px;
$default-font-family: "Segoe UI light", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
  Roboto, "Helvetica Neue", sans-serif;
$resXXL: 1280px;
$resXL: 1170px;
$resL: 991px;
$resM: 768px;
$resS: 660px;
$resXS: 500px;
$resXXS: 435px;
$resMin: 320px;

$top-height: 75px;
$sidebar-width: 300px;

$small-card-height: 114px;
$xsmall-card-height: 187px;
$medium-card-height: 400px;
$extra-medium-card-height: 550px;
$large-card-height: 974px;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$assets-root: "/assets";
$images-root: "/img";
$images-system: "/app/system/";
$fonts-root: "/fonts/";
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 700;
$font-bolder: 500;
$font-ultraBold: 900;

$primary-color: #409ace;
$secondary-color: #5faee3;
$sidebar-width-1200: 300px;
$top-height-1200: 65px;

$main-collapsed: 45px;
$main-collapsed-1200: 45px;

$primeng-primary-color: #fff;
$primeng-primary-color-text: #000;
$primeng-primary-color-border: #ddd;
