@import "./conf/conf";

$left-space: $sidebar-width;

html {
    position: relative;
    min-width: 320px;
}

html,
body {
    min-height: 100%;
    height: 100%;
    min-width: $resMin;
}

main {
    min-height: 100%;
    position: relative;
    color: $default-text;

    @include body-bg();

    .additional-bg {
        display: none;
        @include additional-bg();
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    html {
        overflow: hidden;
        height: 100%;
    }

    body {
        overflow: auto;
        height: 100%;
    }
}

a {
    transition: color 0.5s ease;
    outline: 0 !important;
}

.body-bg {
    display: none;
}

.al-header {
    display: block;
    height: 49px;
    margin: 0;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
    color: #444444;
}

.al-main {
    margin-left: $left-space;
    padding-top: 25px;
    min-height: 500px;
    position: relative;
    transition: margin 0.7s;
}

.al-footer {
    height: 34px;
    padding: 0 18px 0 ($left-space + 20px);
    width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
    color: $default-text;
    transition: padding-left 0.5s ease;
}

.al-footer-main {
    float: left;
    margin-left: 15px;
}

.al-copy {
    float: left;
}

.al-footer-right {
    float: right;
    margin-right: 12px;

    i {
        margin: 0 4px;
        color: $danger;
    }

    a {
        margin-left: 4px;
        color: $default-text;

        &:hover {
            color: $danger;
        }
    }
}

.al-share {
    margin: -6px 0 0 12px;
    padding: 0;
    list-style: none;
    float: left;

    li {
        list-style: none;
        float: left;
        margin-left: 16px;

        i {
            cursor: pointer;
            transition: all 0.1s ease;
            color: white;
            padding: 6px;
            box-sizing: content-box;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

.al-content {
    padding: 20px 30px 8px 30px;
}

@media screen and (max-width: $resXS) {
    .al-content {
        padding: 20px 20px;
    }
}

.vis-hidden {
    visibility: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.icon-up,
.icon-down {
    width: 5px;
    height: 13px;
    display: block;
}

// .icon-up {
//     @include bg-nr("arrow-green-up.svg");
// }

// .icon-down {
//     @include bg-nr("arrow-red-down.svg");
// }

.disable-text-selection {
    -webkit-touch-callout: none;
    user-select: none;
}

.align-right {
    text-align: right;
}

.amcharts-chart-div > a {
}

.content-panel {
    padding-left: 22px;
    padding-top: 26px;
}

@media (max-width: 590px) {
    .al-footer-right {
        float: none;
        margin-bottom: 19px;
        margin-right: 0;
    }

    .al-footer {
        height: 76px;
        text-align: center;
    }

    .al-main {
        padding-bottom: 76px;
    }

    .al-footer-main {
        float: none;
        display: inline-block;
    }
}

.full-invisible {
    visibility: hidden !important;

    * {
        visibility: hidden !important;
    }
}

.irs-grid-text {
    color: $default-text;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}
