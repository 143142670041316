﻿@import "./conf/_variables";

.rtl {
  * {
    direction: rtl;
  }

  .d-flex-ar-row-reverse {
    flex-direction: row-reverse !important;
  }

  .d-flex-row-reverse-rtl {
    flex-direction: row-reverse !important;

    input {
      direction: ltr;
    }
  }

  .d-flex-row-reverse {
    flex-direction: row !important;

    input {
      direction: rtl;
    }
  }

  .p-dropdown .p-dropdown-label {
    text-align: right !important;
  }

  .al-main {
    margin-left: auto !important;
    margin-right: $sidebar-width !important;
  }

  .menu-collapsed .al-main {
    margin-left: auto !important;
    margin-right: $main-collapsed !important;
  }

  .al-content {
    float: right;
    width: 100%;
  }

  .breadcrumb {
    .breadcrumb-item {
      float: right;
    }
  }

  .multiLingual {
    right: auto !important;
    left: 220px;
  }

  #search-item {
    left: auto !important;
    right: 350px !important;
  }

  #shortcut-item {
    right: auto !important;
    left: 30px;
  }

  #favorite-item {
    right: auto !important;
    left: 80px;
  }

  #notification-item {
    right: auto !important;
    left: 130px;
  }

  #sql-builder-item {
    right: auto !important;
    left: 180px;
  }

  #sidebar-icon {
    left: auto !important;
    right: 280px;

    .hamburger span {
      margin-left: auto !important;
      margin-right: 5px;
    }

    .hamburger {
      left: auto !important;
      right: 285px;
    }
  }

  .bar {
    right: auto;
    left: 0;
  }

  .user-profile {
    float: left;
  }

  .user-image {
    padding: 20px 30px 20px 0px !important;
  }

  .user-Profile .user-name button > i {
    padding: 0 10px 0 0 !important;
  }

  .absolute-calendar-icon {
    .ui-datepicker-trigger {
      position: absolute;
      left: 5px !important;
      top: 5px;
      right: auto !important;
    }
  }

  aside.al-sidebar {
    left: auto !important;
    right: 0;
  }

  .page-top {
    padding: 0 0 0 32px;
    right: 0;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6 {
    float: right;
  }

  .offset-sm-1,
  .offset-sm-2,
  .offset-sm-3,
  .offset-sm-4,
  .offset-sm-5,
  .offset-sm-6 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-right: 8.33333333%;
  }

  .offset-sm-2 {
    margin-right: 16.66666667%;
  }

  .offset-sm-3 {
    margin-right: 25%;
  }

  .offset-sm-4 {
    margin-right: 33.33333333%;
  }

  .offset-sm-5 {
    margin-right: 41.66666667%;
  }

  .offset-sm-6 {
    margin-right: 50%;
  }

  .ui-dropdown-label.ui-inputtext.ui-corner-all {
    text-align: left;
    direction: ltr;
  }

  a.al-sidebar-list-link {
    padding-left: 0 !important;
    padding-right: 25px !important;

    i {
      margin-left: 25px !important;
      margin-right: 0 !important;
    }

    b {
      left: 10px !important;
      right: auto !important;
      margin-left: 20px !important;
      margin-right: 0 !important;
    }
  }

  .al-sidebar-sublist {
    ba-menu-item > li a {
      padding-left: 0 !important;
      padding-right: 85px !important;
    }

    > .dialog-center > .ui-dialog ba-menu-item > li a {
      padding-left: 0 !important;
      padding-right: 52px !important;
    }
  }

  .user-menu {
    left: auto;
    right: 0;
    text-align: right;

    > li {
      text-align: right !important;

      a > i {
        padding: 0 !important;
      }
    }
  }

  .more-datails-menu {
    left: 0;
    right: auto;
    text-align: right;

    &:after {
      content: "";
      border-color: transparent #f2f2f2 transparent transparent;
      left: -20px;
      right: auto;
    }
  }

  a.collapse-menu-link {
    margin: 11px $sidebar-width 0 0 !important;
  }

  .top-dropdown-menu {
    right: auto !important;
    left: -47px !important;

    .profile-dropdown {
      right: auto !important;
      left: 5px !important;

      a {
        text-align: right;
        padding: 4px 16px 4px 20px;

        i {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }

  .business-card {
    .title {
      padding: 15px 30px 0 0;
    }

    .more-icon {
      right: auto;
      left: 0;
    }

    .content {
      float: right;
      padding: 10px 20px 0 0px;
    }
  }

  .notification-hidden-icon {
    right: auto !important;
    left: 15px !important;

    .dropdown-notification-icon {
      left: 0 !important;
      right: auto !important;

      li {
        padding: 10px 0;
        text-align: right;
      }
    }
  }

  #user-image {
    padding: 20px 30px 20px 0 !important;
  }

  .multiLingual-mobile {
    top: 15px;
    left: 60px !important;
    right: auto !important;
  }

  .icon {
    left: 0;
    right: auto !important;
  }

  .occupancy-rate-card {
    float: right;
  }

  .settings {
    right: auto !important;
    left: -80px !important;
    transform: rotate(270deg) !important;

    &.active-result {
      left: 290px !important;
      right: auto !important;
    }
  }

  .action-menu {
    float: right !important;
    text-align: center !important;
    left: 0px !important;
    margin-right: -150px !important;
    right: auto !important;
  }

  sidebar-notification-cmp #search-bar form {
    .row > div {
      float: right;
    }
  }

  .progress-result {
    float: right;
  }

  .search-title {
    left: auto !important;
    right: 50px !important;
  }

  #close-search {
    left: 20px !important;
    right: auto !important;
  }

  #patient-status li {
    float: right !important;
  }

  .text-left {
    text-align: right !important;
  }

  .auto-refresh {
    float: right;

    div {
      float: right !important;
    }
  }

  .form-horizontal .form-group.row.form-group-default label {
    margin-top: 5px !important;
  }

  .control-label {
    margin-top: 10px !important;
  }

  .list-group.vertical-steps .list-group-item {
    border-left: 0 !important;
    border-right: 3px solid #4791cd !important;
    padding-right: 21px !important;
    padding-left: 0px !important;
  }

  .list-group.vertical-steps .list-group-item::before {
    float: right !important;
    margin-right: -35px !important;
    margin-left: 0 !important;
  }

  .ui-paginator-bottom {
    .ui-paginator-element > span {
      transform: rotate(180deg) !important;
    }
  }

  .collapsed {
    .menu-panel-title {
      a {
        padding-left: 0 !important;
        padding-right: 75px !important;
      }
    }
  }

  .arow-item {
    right: auto !important;
    left: 10px;
    margin-right: 0 !important;
    margin-left: 20px;
  }

  .menu-panel-title {
    i {
      margin-left: 15px !important;
      margin-right: 0 !important;
    }
  }

  .fab {
    left: 25px;
    right: auto !important;
  }

  .dropdown-menu {
    right: auto !important;
    left: -20px !important;
  }
}
