/*$body-bg: #F0F3F4; // default bg color*/
/* font-size */
.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* font-size */
/* font-weight */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* font-weight */
/* text-decoration */
.decoration-dashed {
  text-decoration: dashed !important;
}

.decoration-dotted {
  text-decoration: dotted !important;
}

.decoration-double {
  text-decoration: double !important;
}

.decoration-line-through {
  text-decoration: line-through !important;
}

.decoration-overline {
  text-decoration: overline !important;
}

.decoration-solid {
  text-decoration: solid !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.decoration-wavy {
  text-decoration: wavy !important;
}

.decoration-none {
  text-decoration: none !important;
}

/* text-decoration */
/* font-family */
.ff-opensans {
  font-family: "Open Sans" !important;
}

.ff-segoeui {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.ff-ubunto {
  font-family: "ubunto" !important;
}

.ff-cormorantunicase {
  font-family: "Cormorant Unicase" !important;
}

/* font-family */
/* element style */
.no-border {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-none {
  display: none !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

/* element style */
/* padding */
.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.plr-5 {
  padding: 0 5px !important;
}

.plr-10 {
  padding: 0 10px !important;
}

.plr-15 {
  padding: 0 15px !important;
}

.plr-20 {
  padding: 0 20px !important;
}

.plr-25 {
  padding: 0 25px !important;
}

.plr-30 {
  padding: 0 30px !important;
}

.plr-35 {
  padding: 0 35px !important;
}

.plr-40 {
  padding: 0 40px !important;
}

.plr-45 {
  padding: 0 45px !important;
}

.plr-50 {
  padding: 0 50px !important;
}

.plr-55 {
  padding: 0 55px !important;
}

.plr-60 {
  padding: 0 60px !important;
}

.plr-65 {
  padding: 0 65px !important;
}

.plr-70 {
  padding: 0 70px !important;
}

.plr-75 {
  padding: 0 75px !important;
}

.plr-80 {
  padding: 0 80px !important;
}

.plr-85 {
  padding: 0 85px !important;
}

.plr-90 {
  padding: 0 90px !important;
}

.plr-95 {
  padding: 0 95px !important;
}

.plr-100 {
  padding: 0 100px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

.ptb-55 {
  padding: 55px 0 !important;
}

.ptb-60 {
  padding: 60px 0 !important;
}

.ptb-65 {
  padding: 65px 0 !important;
}

.ptb-70 {
  padding: 70px 0 !important;
}

.ptb-75 {
  padding: 75px 0 !important;
}

.ptb-80 {
  padding: 80px 0 !important;
}

.ptb-85 {
  padding: 85px 0 !important;
}

.ptb-90 {
  padding: 90px 0 !important;
}

.ptb-95 {
  padding: 95px 0 !important;
}

.ptb-100 {
  padding: 100px 0 0 !important;
}

.padding-5 {
  padding: 5px !important;
}
.padding-5.padding-top {
  padding: 5px 0 0 0 !important;
}
.padding-5.padding-left {
  padding: 0 0 0 5px !important;
}
.padding-5.padding-right {
  padding: 0 5px 0 0 !important;
}
.padding-5.padding-bottom {
  padding: 0 0 5px 0 !important;
}

.padding-10 {
  padding: 10px !important;
}
.padding-10.padding-top {
  padding: 10px 0 0 0 !important;
}
.padding-10.padding-left {
  padding: 0 0 0 10px !important;
}
.padding-10.padding-right {
  padding: 0 10px 0 0 !important;
}
.padding-10.padding-bottom {
  padding: 0 0 10px 0 !important;
}

.padding-15 {
  padding: 15px !important;
}
.padding-15.padding-top {
  padding: 15px 0 0 0 !important;
}
.padding-15.padding-left {
  padding: 0 0 0 15px !important;
}
.padding-15.padding-right {
  padding: 0 15px 0 0 !important;
}
.padding-15.padding-bottom {
  padding: 0 0 15px 0 !important;
}

.padding-20 {
  padding: 20px !important;
}
.padding-20.padding-top {
  padding: 20px 0 0 0 !important;
}
.padding-20.padding-left {
  padding: 0 0 0 20px !important;
}
.padding-20.padding-right {
  padding: 0 20px 0 0 !important;
}
.padding-20.padding-bottom {
  padding: 0 0 20px 0 !important;
}

.padding-25 {
  padding: 25px !important;
}
.padding-25.padding-top {
  padding: 25px 0 0 0 !important;
}
.padding-25.padding-left {
  padding: 0 0 0 25px !important;
}
.padding-25.padding-right {
  padding: 0 25px 0 0 !important;
}
.padding-25.padding-bottom {
  padding: 0 0 25px 0 !important;
}

.padding-30 {
  padding: 30px !important;
}
.padding-30.padding-top {
  padding: 30px 0 0 0 !important;
}
.padding-30.padding-left {
  padding: 0 0 0 30px !important;
}
.padding-30.padding-right {
  padding: 0 30px 0 0 !important;
}
.padding-30.padding-bottom {
  padding: 0 0 30px 0 !important;
}

.padding-35 {
  padding: 35px !important;
}
.padding-35.padding-top {
  padding: 35px 0 0 0 !important;
}
.padding-35.padding-left {
  padding: 0 0 0 35px !important;
}
.padding-35.padding-right {
  padding: 0 35px 0 0 !important;
}
.padding-35.padding-bottom {
  padding: 0 0 35px 0 !important;
}

.padding-40 {
  padding: 40px !important;
}
.padding-40.padding-top {
  padding: 40px 0 0 0 !important;
}
.padding-40.padding-left {
  padding: 0 0 0 40px !important;
}
.padding-40.padding-right {
  padding: 0 40px 0 0 !important;
}
.padding-40.padding-bottom {
  padding: 0 0 40px 0 !important;
}

.padding-45 {
  padding: 45px !important;
}
.padding-45.padding-top {
  padding: 45px 0 0 0 !important;
}
.padding-45.padding-left {
  padding: 0 0 0 45px !important;
}
.padding-45.padding-right {
  padding: 0 45px 0 0 !important;
}
.padding-45.padding-bottom {
  padding: 0 0 45px 0 !important;
}

.padding-50 {
  padding: 50px !important;
}
.padding-50.padding-top {
  padding: 50px 0 0 0 !important;
}
.padding-50.padding-left {
  padding: 0 0 0 50px !important;
}
.padding-50.padding-right {
  padding: 0 50px 0 0 !important;
}
.padding-50.padding-bottom {
  padding: 0 0 50px 0 !important;
}

.padding-55 {
  padding: 55px !important;
}
.padding-55.padding-top {
  padding: 55px 0 0 0 !important;
}
.padding-55.padding-left {
  padding: 0 0 0 55px !important;
}
.padding-55.padding-right {
  padding: 0 55px 0 0 !important;
}
.padding-55.padding-bottom {
  padding: 0 0 55px 0 !important;
}

.padding-60 {
  padding: 60px !important;
}
.padding-60.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-60.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-60.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-60.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-65 {
  padding: 65px !important;
}
.padding-65.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-65.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-65.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-65.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-70 {
  padding: 70px !important;
}
.padding-70.padding-top {
  padding: 70px 0 0 0 !important;
}
.padding-70.padding-left {
  padding: 0 0 0 70px !important;
}
.padding-70.padding-right {
  padding: 0 70px 0 0 !important;
}
.padding-70.padding-bottom {
  padding: 0 0 70px 0 !important;
}

.padding-75 {
  padding: 75px !important;
}
.padding-75.padding-top {
  padding: 75px 0 0 0 !important;
}
.padding-75.padding-left {
  padding: 0 0 0 75px !important;
}
.padding-75.padding-right {
  padding: 0 75px 0 0 !important;
}
.padding-75.padding-bottom {
  padding: 0 0 75px 0 !important;
}

.padding-80 {
  padding: 80px !important;
}
.padding-80.padding-top {
  padding: 80px 0 0 0 !important;
}
.padding-80.padding-left {
  padding: 0 0 0 80px !important;
}
.padding-80.padding-right {
  padding: 0 80px 0 0 !important;
}
.padding-80.padding-bottom {
  padding: 0 0 80px 0 !important;
}

.padding-85 {
  padding: 85px !important;
}
.padding-85.padding-top {
  padding: 85px 0 0 0 !important;
}
.padding-85.padding-left {
  padding: 0 0 0 85px !important;
}
.padding-85.padding-right {
  padding: 0 85px 0 0 !important;
}
.padding-85.padding-bottom {
  padding: 0 0 85px 0 !important;
}

.padding-90 {
  padding: 90px !important;
}
.padding-90.padding-top {
  padding: 90px 0 0 0 !important;
}
.padding-90.padding-left {
  padding: 0 0 0 90px !important;
}
.padding-90.padding-right {
  padding: 0 90px 0 0 !important;
}
.padding-90.padding-bottom {
  padding: 0 0 90px 0 !important;
}

.padding-95 {
  padding: 95px !important;
}
.padding-95.padding-top {
  padding: 95px 0 0 0 !important;
}
.padding-95.padding-left {
  padding: 0 0 0 95px !important;
}
.padding-95.padding-right {
  padding: 0 95px 0 0 !important;
}
.padding-95.padding-bottom {
  padding: 0 0 95px 0 !important;
}

.padding-100 {
  padding: 100px !important;
}
.padding-100.padding-top {
  padding: 100px 0 0 0 !important;
}
.padding-100.padding-left {
  padding: 0 0 0 100px !important;
}
.padding-100.padding-right {
  padding: 0 100px 0 0 !important;
}
.padding-100.padding-bottom {
  padding: 0 0 100px 0 !important;
}

/* padding */
/* margin */
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mlr-5 {
  margin: 0 5px !important;
}

.mlr-10 {
  margin: 0 10px !important;
}

.mlr-15 {
  margin: 0 15px !important;
}

.mlr-20 {
  margin: 0 20px !important;
}

.mlr-25 {
  margin: 0 25px !important;
}

.mlr-30 {
  margin: 0 30px !important;
}

.mlr-35 {
  margin: 0 35px !important;
}

.mlr-40 {
  margin: 0 40px !important;
}

.mlr-45 {
  margin: 0 45px !important;
}

.mlr-50 {
  margin: 0 50px !important;
}

.mlr-55 {
  margin: 0 55px !important;
}

.mlr-60 {
  margin: 0 60px !important;
}

.mlr-65 {
  margin: 0 65px !important;
}

.mlr-70 {
  margin: 0 70px !important;
}

.mlr-75 {
  margin: 0 75px !important;
}

.mlr-80 {
  margin: 0 80px !important;
}

.mlr-85 {
  margin: 0 85px !important;
}

.mlr-90 {
  margin: 0 90px !important;
}

.mlr-95 {
  margin: 0 95px !important;
}

.mlr-100 {
  margin: 0 100px !important;
}

.mtb-5 {
  margin: 5px 0 !important;
}

.mtb-10 {
  margin: 10px 0 !important;
}

.mtb-15 {
  margin: 15px 0 !important;
}

.mtb-20 {
  margin: 20px 0 !important;
}

.mtb-25 {
  margin: 25px 0 !important;
}

.mtb-30 {
  margin: 30px 0 !important;
}

.mtb-35 {
  margin: 35px 0 !important;
}

.mtb-40 {
  margin: 40px 0 !important;
}

.mtb-45 {
  margin: 45px 0 !important;
}

.mtb-50 {
  margin: 50px 0 !important;
}

.mtb-55 {
  margin: 55px 0 !important;
}

.mtb-60 {
  margin: 60px 0 !important;
}

.mtb-65 {
  margin: 65px 0 !important;
}

.mtb-70 {
  margin: 70px 0 !important;
}

.mtb-75 {
  margin: 75px 0 !important;
}

.mtb-80 {
  margin: 80px 0 !important;
}

.mtb-85 {
  margin: 85px 0 !important;
}

.mtb-90 {
  margin: 90px 0 !important;
}

.mtb-95 {
  margin: 95px 0 !important;
}

.mtb-100 {
  margin: 100px 0 0 !important;
}

.margin-5 {
  margin: 5px !important;
}
.margin-5.margin-top {
  margin: 5px 0 0 0 !important;
}
.margin-5.margin-left {
  margin: 0 0 0 5px !important;
}
.margin-5.margin-right {
  margin: 0 5px 0 0 !important;
}
.margin-5.margin-bottom {
  margin: 0 0 5px 0 !important;
}

.margin-10 {
  margin: 10px !important;
}
.margin-10.margin-top {
  margin: 10px 0 0 0 !important;
}
.margin-10.margin-left {
  margin: 0 0 0 10px !important;
}
.margin-10.margin-right {
  margin: 0 10px 0 0 !important;
}
.margin-10.margin-bottom {
  margin: 0 0 10px 0 !important;
}

.margin-15 {
  margin: 15px !important;
}
.margin-15.margin-top {
  margin: 15px 0 0 0 !important;
}
.margin-15.margin-left {
  margin: 0 0 0 15px !important;
}
.margin-15.margin-right {
  margin: 0 15px 0 0 !important;
}
.margin-15.margin-bottom {
  margin: 0 0 15px 0 !important;
}

.margin-20 {
  margin: 20px !important;
}
.margin-20.margin-top {
  margin: 20px 0 0 0 !important;
}
.margin-20.margin-left {
  margin: 0 0 0 20px !important;
}
.margin-20.margin-right {
  margin: 0 20px 0 0 !important;
}
.margin-20.margin-bottom {
  margin: 0 0 20px 0 !important;
}

.margin-25 {
  margin: 25px !important;
}
.margin-25.margin-top {
  margin: 25px 0 0 0 !important;
}
.margin-25.margin-left {
  margin: 0 0 0 25px !important;
}
.margin-25.margin-right {
  margin: 0 25px 0 0 !important;
}
.margin-25.margin-bottom {
  margin: 0 0 25px 0 !important;
}

.margin-30 {
  margin: 30px !important;
}
.margin-30.margin-top {
  margin: 30px 0 0 0 !important;
}
.margin-30.margin-left {
  margin: 0 0 0 30px !important;
}
.margin-30.margin-right {
  margin: 0 30px 0 0 !important;
}
.margin-30.margin-bottom {
  margin: 0 0 30px 0 !important;
}

.margin-35 {
  margin: 35px !important;
}
.margin-35.margin-top {
  margin: 35px 0 0 0 !important;
}
.margin-35.margin-left {
  margin: 0 0 0 35px !important;
}
.margin-35.margin-right {
  margin: 0 35px 0 0 !important;
}
.margin-35.margin-bottom {
  margin: 0 0 35px 0 !important;
}

.margin-40 {
  margin: 40px !important;
}
.margin-40.margin-top {
  margin: 40px 0 0 0 !important;
}
.margin-40.margin-left {
  margin: 0 0 0 40px !important;
}
.margin-40.margin-right {
  margin: 0 40px 0 0 !important;
}
.margin-40.margin-bottom {
  margin: 0 0 40px 0 !important;
}

.margin-45 {
  margin: 45px !important;
}
.margin-45.margin-top {
  margin: 45px 0 0 0 !important;
}
.margin-45.margin-left {
  margin: 0 0 0 45px !important;
}
.margin-45.margin-right {
  margin: 0 45px 0 0 !important;
}
.margin-45.margin-bottom {
  margin: 0 0 45px 0 !important;
}

.margin-50 {
  margin: 50px !important;
}
.margin-50.margin-top {
  margin: 50px 0 0 0 !important;
}
.margin-50.margin-left {
  margin: 0 0 0 50px !important;
}
.margin-50.margin-right {
  margin: 0 50px 0 0 !important;
}
.margin-50.margin-bottom {
  margin: 0 0 50px 0 !important;
}

.margin-55 {
  margin: 55px !important;
}
.margin-55.margin-top {
  margin: 55px 0 0 0 !important;
}
.margin-55.margin-left {
  margin: 0 0 0 55px !important;
}
.margin-55.margin-right {
  margin: 0 55px 0 0 !important;
}
.margin-55.margin-bottom {
  margin: 0 0 55px 0 !important;
}

.margin-60 {
  margin: 60px !important;
}
.margin-60.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-60.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-60.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-60.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-65 {
  margin: 65px !important;
}
.margin-65.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-65.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-65.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-65.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-70 {
  margin: 70px !important;
}
.margin-70.margin-top {
  margin: 70px 0 0 0 !important;
}
.margin-70.margin-left {
  margin: 0 0 0 70px !important;
}
.margin-70.margin-right {
  margin: 0 70px 0 0 !important;
}
.margin-70.margin-bottom {
  margin: 0 0 70px 0 !important;
}

.margin-75 {
  margin: 75px !important;
}
.margin-75.margin-top {
  margin: 75px 0 0 0 !important;
}
.margin-75.margin-left {
  margin: 0 0 0 75px !important;
}
.margin-75.margin-right {
  margin: 0 75px 0 0 !important;
}
.margin-75.margin-bottom {
  margin: 0 0 75px 0 !important;
}

.margin-80 {
  margin: 80px !important;
}
.margin-80.margin-top {
  margin: 80px 0 0 0 !important;
}
.margin-80.margin-left {
  margin: 0 0 0 80px !important;
}
.margin-80.margin-right {
  margin: 0 80px 0 0 !important;
}
.margin-80.margin-bottom {
  margin: 0 0 80px 0 !important;
}

.margin-85 {
  margin: 85px !important;
}
.margin-85.margin-top {
  margin: 85px 0 0 0 !important;
}
.margin-85.margin-left {
  margin: 0 0 0 85px !important;
}
.margin-85.margin-right {
  margin: 0 85px 0 0 !important;
}
.margin-85.margin-bottom {
  margin: 0 0 85px 0 !important;
}

.margin-90 {
  margin: 90px !important;
}
.margin-90.margin-top {
  margin: 90px 0 0 0 !important;
}
.margin-90.margin-left {
  margin: 0 0 0 90px !important;
}
.margin-90.margin-right {
  margin: 0 90px 0 0 !important;
}
.margin-90.margin-bottom {
  margin: 0 0 90px 0 !important;
}

.margin-95 {
  margin: 95px !important;
}
.margin-95.margin-top {
  margin: 95px 0 0 0 !important;
}
.margin-95.margin-left {
  margin: 0 0 0 95px !important;
}
.margin-95.margin-right {
  margin: 0 95px 0 0 !important;
}
.margin-95.margin-bottom {
  margin: 0 0 95px 0 !important;
}

.margin-100 {
  margin: 100px !important;
}
.margin-100.margin-top {
  margin: 100px 0 0 0 !important;
}
.margin-100.margin-left {
  margin: 0 0 0 100px !important;
}
.margin-100.margin-right {
  margin: 0 100px 0 0 !important;
}
.margin-100.margin-bottom {
  margin: 0 0 100px 0 !important;
}

/* margin */
/* height */
.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-310 {
  height: 310px !important;
}

.h-320 {
  height: 320px !important;
}

.h-330 {
  height: 330px !important;
}

.h-340 {
  height: 340px !important;
}

.h-350 {
  height: 350px !important;
}

.h-360 {
  height: 360px !important;
}

.h-370 {
  height: 370px !important;
}

.h-380 {
  height: 380px !important;
}

.h-390 {
  height: 390px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

.h-750 {
  height: 750px !important;
}

.h-800 {
  height: 800px !important;
}

/* height */
/* width */
.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.w-750 {
  width: 750px !important;
}

.w-800 {
  width: 800px !important;
}

/* width */
/* min height */
.min-h-50 {
  min-height: 50px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.min-h-450 {
  min-height: 450px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.min-h-650 {
  min-height: 650px !important;
}

.min-h-700 {
  min-height: 700px !important;
}

.min-h-750 {
  min-height: 750px !important;
}

.min-h-800 {
  min-height: 800px !important;
}

/* min height */
/* min width */
.min-w-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.min-w-450 {
  min-width: 450px !important;
}

.min-w-500 {
  min-width: 500px !important;
}

.min-w-550 {
  min-width: 550px !important;
}

.min-w-600 {
  min-width: 600px !important;
}

.min-w-650 {
  min-width: 650px !important;
}

.min-w-700 {
  min-width: 700px !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.min-w-800 {
  min-width: 800px !important;
}

/* min width */
/* max height */
.max-h-50 {
  max-height: 50px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-550 {
  max-height: 550px !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-650 {
  max-height: 650px !important;
}

.max-h-700 {
  max-height: 700px !important;
}

.max-h-750 {
  max-height: 750px !important;
}

.max-h-800 {
  max-height: 800px !important;
}

/* max height */
/* max width */
.max-w-50 {
  max-width: 50px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-550 {
  max-width: 550px !important;
}

.max-w-600 {
  max-width: 600px !important;
}

.max-w-650 {
  max-width: 650px !important;
}

.max-w-700 {
  max-width: 700px !important;
}

.max-w-750 {
  max-width: 750px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

/* max width */
/* white-space */
.ws-inherit {
  white-space: inherit !important;
}

.ws-initial {
  white-space: initial !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.ws-pre {
  white-space: pre !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.ws-unset {
  white-space: unset !important;
}

/* white-space */
/* margin */
/* border */
.no-border {
  border: none !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.b-3 {
  border-width: 3px !important;
}

.b-4 {
  border-width: 4px !important;
}

.b-5 {
  border-width: 5px !important;
}

.b-6 {
  border-width: 6px !important;
}

.b-7 {
  border-width: 7px !important;
}

.b-8 {
  border-width: 8px !important;
}

.b-9 {
  border-width: 9px !important;
}

.b-10 {
  border-width: 10px !important;
}

.bl-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
}

.bl-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
}

.bl-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
}

.bl-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
}

.bl-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
}

.bl-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
}

.bl-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
}

.bl-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
}

.bl-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
}

.bl-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
}

.br-1 {
  border-width: 0 !important;
  border-right-width: 1px !important;
}

.br-2 {
  border-width: 0 !important;
  border-right-width: 2px !important;
}

.br-3 {
  border-width: 0 !important;
  border-right-width: 3px !important;
}

.br-4 {
  border-width: 0 !important;
  border-right-width: 4px !important;
}

.br-5 {
  border-width: 0 !important;
  border-right-width: 5px !important;
}

.br-6 {
  border-width: 0 !important;
  border-right-width: 6px !important;
}

.br-7 {
  border-width: 0 !important;
  border-right-width: 7px !important;
}

.br-8 {
  border-width: 0 !important;
  border-right-width: 8px !important;
}

.br-9 {
  border-width: 0 !important;
  border-right-width: 9px !important;
}

.br-10 {
  border-width: 0 !important;
  border-right-width: 10px !important;
}

.bt-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

.bt-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
}

.bt-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
}

.bt-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
}

.bt-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
}

.bt-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
}

.bt-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
}

.bt-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
}

.bt-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
}

.bt-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
}

.bb-1 {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-width: 0 !important;
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-width: 0 !important;
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-width: 0 !important;
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-width: 0 !important;
  border-bottom-width: 5px !important;
}

.bb-6 {
  border-width: 0 !important;
  border-bottom-width: 6px !important;
}

.bb-7 {
  border-width: 0 !important;
  border-bottom-width: 7px !important;
}

.bb-8 {
  border-width: 0 !important;
  border-bottom-width: 8px !important;
}

.bb-9 {
  border-width: 0 !important;
  border-bottom-width: 9px !important;
}

.bb-10 {
  border-width: 0 !important;
  border-bottom-width: 10px !important;
}

.blr-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.blr-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.blr-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.blr-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.blr-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}

.blr-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
  border-right-width: 6px !important;
}

.blr-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
  border-right-width: 7px !important;
}

.blr-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.blr-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}

.blr-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
  border-right-width: 10px !important;
}

.btb-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btb-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.btb-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

.btb-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.btb-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
  border-bottom-width: 5px !important;
}

.btb-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
  border-bottom-width: 6px !important;
}

.btb-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
  border-bottom-width: 7px !important;
}

.btb-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.btb-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
  border-bottom-width: 9px !important;
}

.btb-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
  border-bottom-width: 10px !important;
}

.b-dotted {
  border-style: dotted !important;
}

.b-dashed {
  border-style: dashed !important;
}

.b-solid {
  border-style: solid !important;
}

.b-double {
  border-style: double !important;
}

.b-groove {
  border-style: groove !important;
}

.b-ridge {
  border-style: ridge !important;
}

.b-inset {
  border-style: inset !important;
}

.b-outset {
  border-style: outset !important;
}

.b-none {
  border-style: none !important;
}

.b-hidden {
  border-style: hidden !important;
}

.b-mix {
  border-style: dotted dashed solid double !important;
}

/* border */
/* outline style */
.outline-dotted {
  outline-style: dotted;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-solid {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-groove {
  outline-style: groove;
}

.outline-ridge {
  outline-style: ridge;
}

.outline-inset {
  outline-style: inset;
}

.outline-outset {
  outline-style: outset;
}

/* outline style */
/* h and small */
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  font-size: 75%;
  color: #798892;
}

/* text align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-left-exact {
  text-align: left !important;
}

/* text align */
/* border */
.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

/* border */
/* float */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* float */
/* direction */
.direction-right {
  direction: rtl !important;
}

.direction-left {
  direction: ltr !important;
}

/* direction */
/* spacing */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.pb-0,
.py-0,
.p-0 {
  padding-bottom: 0 !important;
}

.pt-0,
.py-0,
.p-0 {
  padding-top: 0 !important;
}

.pl-0,
.px-0,
.p-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0,
.p-0 {
  padding-right: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-4 {
  padding-bottom: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.mb-0,
.my-0,
.m-0 {
  margin-bottom: 0 !important;
}

.mt-0,
.my-0,
.m-0 {
  margin-top: 0 !important;
}

.ml-0,
.mx-0,
.m-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0,
.m-0 {
  margin-right: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-4 {
  margin-bottom: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* spacing */
/* overflow */
.s-overflow-x {
  overflow-x: scroll !important;
}

.s-overflow-y {
  overflow-y: scroll !important;
}

.s-overflow {
  overflow: scroll !important;
}

.a-overflow-x {
  overflow-x: auto !important;
}

.a-overflow-y {
  overflow-y: auto !important;
}

.a-overflow {
  overflow: auto !important;
}

.h-overflow-x {
  overflow-x: hidden !important;
}

.h-overflow-y {
  overflow-y: hidden !important;
}

.h-overflow {
  overflow: hidden !important;
}

/* overflow */
/* text align */
/* cursor */
.c-pointer {
  cursor: pointer !important;
}

.c-none {
  cursor: none !important;
}

.c-initial {
  cursor: initial !important;
}

/* cursor */
/* float */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* float */
.width-5rem {
  width: 5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-center {
  display: flex;
}
.flex-center.horizontal {
  justify-content: center;
}
.flex-center.vertical {
  align-items: center;
}
.flex-center.both {
  align-items: center;
  justify-content: center;
}
.flex-center.space-around {
  justify-content: space-around;
}
.flex-center.space-between {
  justify-content: space-between;
}

.zoom-09 {
  zoom: 0.9 !important;
}

.width-2rem {
  width: 2rem !important;
}

.width-3rem {
  width: 3rem;
}

.width-6rem {
  width: 6rem;
}

.width-8rem {
  width: 8rem;
}

.width-12rem {
  width: 12rem;
}

.h-100vh {
  height: 100vh !important;
}

.box-shadow-50 {
  box-shadow: 5px 0px 28px 10px rgba(0, 0, 0, 0.5) !important;
}

/**
 * google-material-color v1.2.6
 * https://github.com/danlevan/google-material-color
 */
.palette-Red.bg {
  background-color: #f44336;
}

.palette-Red.text {
  color: #f44336;
}

.palette-Red-50.bg {
  background-color: #ffebee;
}

.palette-Red-50.text {
  color: #ffebee;
}

.palette-Red-100.bg {
  background-color: #ffcdd2;
}

.palette-Red-100.text {
  color: #ffcdd2;
}

.palette-Red-200.bg {
  background-color: #ef9a9a;
}

.palette-Red-200.text {
  color: #ef9a9a;
}

.palette-Red-300.bg {
  background-color: #e57373;
}

.palette-Red-300.text {
  color: #e57373;
}

.palette-Red-400.bg {
  background-color: #ef5350;
}

.palette-Red-400.text {
  color: #ef5350;
}

.palette-Red-500.bg {
  background-color: #f44336;
}

.palette-Red-500.text {
  color: #f44336;
}

.palette-Red-600.bg {
  background-color: #e53935;
}

.palette-Red-600.text {
  color: #e53935;
}

.palette-Red-700.bg {
  background-color: #d32f2f;
}

.palette-Red-700.text {
  color: #d32f2f;
}

.palette-Red-800.bg {
  background-color: #c62828;
}

.palette-Red-800.text {
  color: #c62828;
}

.palette-Red-900.bg {
  background-color: #b71c1c;
}

.palette-Red-900.text {
  color: #b71c1c;
}

.palette-Red-A100.bg {
  background-color: #ff8a80;
}

.palette-Red-A100.text {
  color: #ff8a80;
}

.palette-Red-A200.bg {
  background-color: #ff5252;
}

.palette-Red-A200.text {
  color: #ff5252;
}

.palette-Red-A400.bg {
  background-color: #ff1744;
}

.palette-Red-A400.text {
  color: #ff1744;
}

.palette-Red-A700.bg {
  background-color: #d50000;
}

.palette-Red-A700.text {
  color: #d50000;
}

.palette-Pink.bg {
  background-color: #e91e63;
}

.palette-Pink.text {
  color: #e91e63;
}

.palette-Pink-50.bg {
  background-color: #fce4ec;
}

.palette-Pink-50.text {
  color: #fce4ec;
}

.palette-Pink-100.bg {
  background-color: #f8bbd0;
}

.palette-Pink-100.text {
  color: #f8bbd0;
}

.palette-Pink-200.bg {
  background-color: #f48fb1;
}

.palette-Pink-200.text {
  color: #f48fb1;
}

.palette-Pink-300.bg {
  background-color: #f06292;
}

.palette-Pink-300.text {
  color: #f06292;
}

.palette-Pink-400.bg {
  background-color: #ec407a;
}

.palette-Pink-400.text {
  color: #ec407a;
}

.palette-Pink-500.bg {
  background-color: #e91e63;
}

.palette-Pink-500.text {
  color: #e91e63;
}

.palette-Pink-600.bg {
  background-color: #d81b60;
}

.palette-Pink-600.text {
  color: #d81b60;
}

.palette-Pink-700.bg {
  background-color: #c2185b;
}

.palette-Pink-700.text {
  color: #c2185b;
}

.palette-Pink-800.bg {
  background-color: #ad1457;
}

.palette-Pink-800.text {
  color: #ad1457;
}

.palette-Pink-900.bg {
  background-color: #880e4f;
}

.palette-Pink-900.text {
  color: #880e4f;
}

.palette-Pink-A100.bg {
  background-color: #ff80ab;
}

.palette-Pink-A100.text {
  color: #ff80ab;
}

.palette-Pink-A200.bg {
  background-color: #ff4081;
}

.palette-Pink-A200.text {
  color: #ff4081;
}

.palette-Pink-A400.bg {
  background-color: #f50057;
}

.palette-Pink-A400.text {
  color: #f50057;
}

.palette-Pink-A700.bg {
  background-color: #c51162;
}

.palette-Pink-A700.text {
  color: #c51162;
}

.palette-Purple.bg {
  background-color: #9c27b0;
}

.palette-Purple.text {
  color: #9c27b0;
}

.palette-Purple-50.bg {
  background-color: #f3e5f5;
}

.palette-Purple-50.text {
  color: #f3e5f5;
}

.palette-Purple-100.bg {
  background-color: #e1bee7;
}

.palette-Purple-100.text {
  color: #e1bee7;
}

.palette-Purple-200.bg {
  background-color: #ce93d8;
}

.palette-Purple-200.text {
  color: #ce93d8;
}

.palette-Purple-300.bg {
  background-color: #ba68c8;
}

.palette-Purple-300.text {
  color: #ba68c8;
}

.palette-Purple-400.bg {
  background-color: #ab47bc;
}

.palette-Purple-400.text {
  color: #ab47bc;
}

.palette-Purple-500.bg {
  background-color: #9c27b0;
}

.palette-Purple-500.text {
  color: #9c27b0;
}

.palette-Purple-600.bg {
  background-color: #8e24aa;
}

.palette-Purple-600.text {
  color: #8e24aa;
}

.palette-Purple-700.bg {
  background-color: #7b1fa2;
}

.palette-Purple-700.text {
  color: #7b1fa2;
}

.palette-Purple-800.bg {
  background-color: #6a1b9a;
}

.palette-Purple-800.text {
  color: #6a1b9a;
}

.palette-Purple-900.bg {
  background-color: #4a148c;
}

.palette-Purple-900.text {
  color: #4a148c;
}

.palette-Purple-A100.bg {
  background-color: #ea80fc;
}

.palette-Purple-A100.text {
  color: #ea80fc;
}

.palette-Purple-A200.bg {
  background-color: #e040fb;
}

.palette-Purple-A200.text {
  color: #e040fb;
}

.palette-Purple-A400.bg {
  background-color: #d500f9;
}

.palette-Purple-A400.text {
  color: #d500f9;
}

.palette-Purple-A700.bg {
  background-color: #aa00ff;
}

.palette-Purple-A700.text {
  color: #aa00ff;
}

.palette-Deep-Purple.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple.text {
  color: #673ab7;
}

.palette-Deep-Purple-50.bg {
  background-color: #ede7f6;
}

.palette-Deep-Purple-50.text {
  color: #ede7f6;
}

.palette-Deep-Purple-100.bg {
  background-color: #d1c4e9;
}

.palette-Deep-Purple-100.text {
  color: #d1c4e9;
}

.palette-Deep-Purple-200.bg {
  background-color: #b39ddb;
}

.palette-Deep-Purple-200.text {
  color: #b39ddb;
}

.palette-Deep-Purple-300.bg {
  background-color: #9575cd;
}

.palette-Deep-Purple-300.text {
  color: #9575cd;
}

.palette-Deep-Purple-400.bg {
  background-color: #7e57c2;
}

.palette-Deep-Purple-400.text {
  color: #7e57c2;
}

.palette-Deep-Purple-500.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple-500.text {
  color: #673ab7;
}

.palette-Deep-Purple-600.bg {
  background-color: #5e35b1;
}

.palette-Deep-Purple-600.text {
  color: #5e35b1;
}

.palette-Deep-Purple-700.bg {
  background-color: #512da8;
}

.palette-Deep-Purple-700.text {
  color: #512da8;
}

.palette-Deep-Purple-800.bg {
  background-color: #4527a0;
}

.palette-Deep-Purple-800.text {
  color: #4527a0;
}

.palette-Deep-Purple-900.bg {
  background-color: #311b92;
}

.palette-Deep-Purple-900.text {
  color: #311b92;
}

.palette-Deep-Purple-A100.bg {
  background-color: #b388ff;
}

.palette-Deep-Purple-A100.text {
  color: #b388ff;
}

.palette-Deep-Purple-A200.bg {
  background-color: #7c4dff;
}

.palette-Deep-Purple-A200.text {
  color: #7c4dff;
}

.palette-Deep-Purple-A400.bg {
  background-color: #651fff;
}

.palette-Deep-Purple-A400.text {
  color: #651fff;
}

.palette-Deep-Purple-A700.bg {
  background-color: #6200ea;
}

.palette-Deep-Purple-A700.text {
  color: #6200ea;
}

.palette-Indigo.bg {
  background-color: #3f51b5;
}

.palette-Indigo.text {
  color: #3f51b5;
}

.palette-Indigo-50.bg {
  background-color: #e8eaf6;
}

.palette-Indigo-50.text {
  color: #e8eaf6;
}

.palette-Indigo-100.bg {
  background-color: #c5cae9;
}

.palette-Indigo-100.text {
  color: #c5cae9;
}

.palette-Indigo-200.bg {
  background-color: #9fa8da;
}

.palette-Indigo-200.text {
  color: #9fa8da;
}

.palette-Indigo-300.bg {
  background-color: #7986cb;
}

.palette-Indigo-300.text {
  color: #7986cb;
}

.palette-Indigo-400.bg {
  background-color: #5c6bc0;
}

.palette-Indigo-400.text {
  color: #5c6bc0;
}

.palette-Indigo-500.bg {
  background-color: #3f51b5;
}

.palette-Indigo-500.text {
  color: #3f51b5;
}

.palette-Indigo-600.bg {
  background-color: #3949ab;
}

.palette-Indigo-600.text {
  color: #3949ab;
}

.palette-Indigo-700.bg {
  background-color: #303f9f;
}

.palette-Indigo-700.text {
  color: #303f9f;
}

.palette-Indigo-800.bg {
  background-color: #283593;
}

.palette-Indigo-800.text {
  color: #283593;
}

.palette-Indigo-900.bg {
  background-color: #1a237e;
}

.palette-Indigo-900.text {
  color: #1a237e;
}

.palette-Indigo-A100.bg {
  background-color: #8c9eff;
}

.palette-Indigo-A100.text {
  color: #8c9eff;
}

.palette-Indigo-A200.bg {
  background-color: #536dfe;
}

.palette-Indigo-A200.text {
  color: #536dfe;
}

.palette-Indigo-A400.bg {
  background-color: #3d5afe;
}

.palette-Indigo-A400.text {
  color: #3d5afe;
}

.palette-Indigo-A700.bg {
  background-color: #304ffe;
}

.palette-Indigo-A700.text {
  color: #304ffe;
}

.palette-Blue.bg {
  background-color: #2196f3;
}

.palette-Blue.text {
  color: #2196f3;
}

.palette-Blue-50.bg {
  background-color: #e3f2fd;
}

.palette-Blue-50.text {
  color: #e3f2fd;
}

.palette-Blue-100.bg {
  background-color: #bbdefb;
}

.palette-Blue-100.text {
  color: #bbdefb;
}

.palette-Blue-200.bg {
  background-color: #90caf9;
}

.palette-Blue-200.text {
  color: #90caf9;
}

.palette-Blue-300.bg {
  background-color: #64b5f6;
}

.palette-Blue-300.text {
  color: #64b5f6;
}

.palette-Blue-400.bg {
  background-color: #42a5f5;
}

.palette-Blue-400.text {
  color: #42a5f5;
}

.palette-Blue-500.bg {
  background-color: #2196f3;
}

.palette-Blue-500.text {
  color: #2196f3;
}

.palette-Blue-600.bg {
  background-color: #1e88e5;
}

.palette-Blue-600.text {
  color: #1e88e5;
}

.palette-Blue-700.bg {
  background-color: #1976d2;
}

.palette-Blue-700.text {
  color: #1976d2;
}

.palette-Blue-800.bg {
  background-color: #1565c0;
}

.palette-Blue-800.text {
  color: #1565c0;
}

.palette-Blue-900.bg {
  background-color: #0d47a1;
}

.palette-Blue-900.text {
  color: #0d47a1;
}

.palette-Blue-A100.bg {
  background-color: #82b1ff;
}

.palette-Blue-A100.text {
  color: #82b1ff;
}

.palette-Blue-A200.bg {
  background-color: #448aff;
}

.palette-Blue-A200.text {
  color: #448aff;
}

.palette-Blue-A400.bg {
  background-color: #2979ff;
}

.palette-Blue-A400.text {
  color: #2979ff;
}

.palette-Blue-A700.bg {
  background-color: #2962ff;
}

.palette-Blue-A700.text {
  color: #2962ff;
}

.palette-Light-Blue.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue.text {
  color: #03a9f4;
}

.palette-Light-Blue-50.bg {
  background-color: #e1f5fe;
}

.palette-Light-Blue-50.text {
  color: #e1f5fe;
}

.palette-Light-Blue-100.bg {
  background-color: #b3e5fc;
}

.palette-Light-Blue-100.text {
  color: #b3e5fc;
}

.palette-Light-Blue-200.bg {
  background-color: #81d4fa;
}

.palette-Light-Blue-200.text {
  color: #81d4fa;
}

.palette-Light-Blue-300.bg {
  background-color: #4fc3f7;
}

.palette-Light-Blue-300.text {
  color: #4fc3f7;
}

.palette-Light-Blue-400.bg {
  background-color: #29b6f6;
}

.palette-Light-Blue-400.text {
  color: #29b6f6;
}

.palette-Light-Blue-500.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue-500.text {
  color: #03a9f4;
}

.palette-Light-Blue-600.bg {
  background-color: #039be5;
}

.palette-Light-Blue-600.text {
  color: #039be5;
}

.palette-Light-Blue-700.bg {
  background-color: #0288d1;
}

.palette-Light-Blue-700.text {
  color: #0288d1;
}

.palette-Light-Blue-800.bg {
  background-color: #0277bd;
}

.palette-Light-Blue-800.text {
  color: #0277bd;
}

.palette-Light-Blue-900.bg {
  background-color: #01579b;
}

.palette-Light-Blue-900.text {
  color: #01579b;
}

.palette-Light-Blue-A100.bg {
  background-color: #80d8ff;
}

.palette-Light-Blue-A100.text {
  color: #80d8ff;
}

.palette-Light-Blue-A200.bg {
  background-color: #40c4ff;
}

.palette-Light-Blue-A200.text {
  color: #40c4ff;
}

.palette-Light-Blue-A400.bg {
  background-color: #00b0ff;
}

.palette-Light-Blue-A400.text {
  color: #00b0ff;
}

.palette-Light-Blue-A700.bg {
  background-color: #0091ea;
}

.palette-Light-Blue-A700.text {
  color: #0091ea;
}

.palette-Cyan.bg {
  background-color: #00bcd4;
}

.palette-Cyan.text {
  color: #00bcd4;
}

.palette-Cyan-50.bg {
  background-color: #e0f7fa;
}

.palette-Cyan-50.text {
  color: #e0f7fa;
}

.palette-Cyan-100.bg {
  background-color: #b2ebf2;
}

.palette-Cyan-100.text {
  color: #b2ebf2;
}

.palette-Cyan-200.bg {
  background-color: #80deea;
}

.palette-Cyan-200.text {
  color: #80deea;
}

.palette-Cyan-300.bg {
  background-color: #4dd0e1;
}

.palette-Cyan-300.text {
  color: #4dd0e1;
}

.palette-Cyan-400.bg {
  background-color: #26c6da;
}

.palette-Cyan-400.text {
  color: #26c6da;
}

.palette-Cyan-500.bg {
  background-color: #00bcd4;
}

.palette-Cyan-500.text {
  color: #00bcd4;
}

.palette-Cyan-600.bg {
  background-color: #00acc1;
}

.palette-Cyan-600.text {
  color: #00acc1;
}

.palette-Cyan-700.bg {
  background-color: #0097a7;
}

.palette-Cyan-700.text {
  color: #0097a7;
}

.palette-Cyan-800.bg {
  background-color: #00838f;
}

.palette-Cyan-800.text {
  color: #00838f;
}

.palette-Cyan-900.bg {
  background-color: #006064;
}

.palette-Cyan-900.text {
  color: #006064;
}

.palette-Cyan-A100.bg {
  background-color: #84ffff;
}

.palette-Cyan-A100.text {
  color: #84ffff;
}

.palette-Cyan-A200.bg {
  background-color: #18ffff;
}

.palette-Cyan-A200.text {
  color: #18ffff;
}

.palette-Cyan-A400.bg {
  background-color: #00e5ff;
}

.palette-Cyan-A400.text {
  color: #00e5ff;
}

.palette-Cyan-A700.bg {
  background-color: #00b8d4;
}

.palette-Cyan-A700.text {
  color: #00b8d4;
}

.palette-Teal.bg {
  background-color: #4791cd;
}

.palette-Teal.text {
  color: #4791cd;
}

.palette-Teal-50.bg {
  background-color: #e0f2f1;
}

.palette-Teal-50.text {
  color: #e0f2f1;
}

.palette-Teal-100.bg {
  background-color: #b2dfdb;
}

.palette-Teal-100.text {
  color: #b2dfdb;
}

.palette-Teal-200.bg {
  background-color: #80cbc4;
}

.palette-Teal-200.text {
  color: #80cbc4;
}

.palette-Teal-300.bg {
  background-color: #4db6ac;
}

.palette-Teal-300.text {
  color: #4db6ac;
}

.palette-Teal-400.bg {
  background-color: #26a69a;
}

.palette-Teal-400.text {
  color: #26a69a;
}

.palette-Teal-500.bg {
  background-color: #4791cd;
}

.palette-Teal-500.text {
  color: #4791cd;
}

.palette-Teal-600.bg {
  background-color: #00897b;
}

.palette-Teal-600.text {
  color: #00897b;
}

.palette-Teal-700.bg {
  background-color: #00796b;
}

.palette-Teal-700.text {
  color: #00796b;
}

.palette-Teal-800.bg {
  background-color: #00695c;
}

.palette-Teal-800.text {
  color: #00695c;
}

.palette-Teal-900.bg {
  background-color: #004d40;
}

.palette-Teal-900.text {
  color: #004d40;
}

.palette-Teal-A100.bg {
  background-color: #a7ffeb;
}

.palette-Teal-A100.text {
  color: #a7ffeb;
}

.palette-Teal-A200.bg {
  background-color: #64ffda;
}

.palette-Teal-A200.text {
  color: #64ffda;
}

.palette-Teal-A400.bg {
  background-color: #1de9b6;
}

.palette-Teal-A400.text {
  color: #1de9b6;
}

.palette-Teal-A700.bg {
  background-color: #00bfa5;
}

.palette-Teal-A700.text {
  color: #00bfa5;
}

.palette-Green.bg {
  background-color: #4caf50;
}

.palette-Green.text {
  color: #4caf50;
}

.palette-Green-50.bg {
  background-color: #e8f5e9;
}

.palette-Green-50.text {
  color: #e8f5e9;
}

.palette-Green-100.bg {
  background-color: #c8e6c9;
}

.palette-Green-100.text {
  color: #c8e6c9;
}

.palette-Green-200.bg {
  background-color: #a5d6a7;
}

.palette-Green-200.text {
  color: #a5d6a7;
}

.palette-Green-300.bg {
  background-color: #81c784;
}

.palette-Green-300.text {
  color: #81c784;
}

.palette-Green-400.bg {
  background-color: #66bb6a;
}

.palette-Green-400.text {
  color: #66bb6a;
}

.palette-Green-500.bg {
  background-color: #4caf50;
}

.palette-Green-500.text {
  color: #4caf50;
}

.palette-Green-600.bg {
  background-color: #43a047;
}

.palette-Green-600.text {
  color: #43a047;
}

.palette-Green-700.bg {
  background-color: #388e3c;
}

.palette-Green-700.text {
  color: #388e3c;
}

.palette-Green-800.bg {
  background-color: #2e7d32;
}

.palette-Green-800.text {
  color: #2e7d32;
}

.palette-Green-900.bg {
  background-color: #1b5e20;
}

.palette-Green-900.text {
  color: #1b5e20;
}

.palette-Green-A100.bg {
  background-color: #b9f6ca;
}

.palette-Green-A100.text {
  color: #b9f6ca;
}

.palette-Green-A200.bg {
  background-color: #69f0ae;
}

.palette-Green-A200.text {
  color: #69f0ae;
}

.palette-Green-A400.bg {
  background-color: #00e676;
}

.palette-Green-A400.text {
  color: #00e676;
}

.palette-Green-A700.bg {
  background-color: #00c853;
}

.palette-Green-A700.text {
  color: #00c853;
}

.palette-Light-Green.bg {
  background-color: #8bc34a;
}

.palette-Light-Green.text {
  color: #8bc34a;
}

.palette-Light-Green-50.bg {
  background-color: #f1f8e9;
}

.palette-Light-Green-50.text {
  color: #f1f8e9;
}

.palette-Light-Green-100.bg {
  background-color: #dcedc8;
}

.palette-Light-Green-100.text {
  color: #dcedc8;
}

.palette-Light-Green-200.bg {
  background-color: #c5e1a5;
}

.palette-Light-Green-200.text {
  color: #c5e1a5;
}

.palette-Light-Green-300.bg {
  background-color: #aed581;
}

.palette-Light-Green-300.text {
  color: #aed581;
}

.palette-Light-Green-400.bg {
  background-color: #9ccc65;
}

.palette-Light-Green-400.text {
  color: #9ccc65;
}

.palette-Light-Green-500.bg {
  background-color: #8bc34a;
}

.palette-Light-Green-500.text {
  color: #8bc34a;
}

.palette-Light-Green-600.bg {
  background-color: #7cb342;
}

.palette-Light-Green-600.text {
  color: #7cb342;
}

.palette-Light-Green-700.bg {
  background-color: #689f38;
}

.palette-Light-Green-700.text {
  color: #689f38;
}

.palette-Light-Green-800.bg {
  background-color: #558b2f;
}

.palette-Light-Green-800.text {
  color: #558b2f;
}

.palette-Light-Green-900.bg {
  background-color: #33691e;
}

.palette-Light-Green-900.text {
  color: #33691e;
}

.palette-Light-Green-A100.bg {
  background-color: #ccff90;
}

.palette-Light-Green-A100.text {
  color: #ccff90;
}

.palette-Light-Green-A200.bg {
  background-color: #b2ff59;
}

.palette-Light-Green-A200.text {
  color: #b2ff59;
}

.palette-Light-Green-A400.bg {
  background-color: #76ff03;
}

.palette-Light-Green-A400.text {
  color: #76ff03;
}

.palette-Light-Green-A700.bg {
  background-color: #64dd17;
}

.palette-Light-Green-A700.text {
  color: #64dd17;
}

.palette-Lime.bg {
  background-color: #cddc39;
}

.palette-Lime.text {
  color: #cddc39;
}

.palette-Lime-50.bg {
  background-color: #f9fbe7;
}

.palette-Lime-50.text {
  color: #f9fbe7;
}

.palette-Lime-100.bg {
  background-color: #f0f4c3;
}

.palette-Lime-100.text {
  color: #f0f4c3;
}

.palette-Lime-200.bg {
  background-color: #e6ee9c;
}

.palette-Lime-200.text {
  color: #e6ee9c;
}

.palette-Lime-300.bg {
  background-color: #dce775;
}

.palette-Lime-300.text {
  color: #dce775;
}

.palette-Lime-400.bg {
  background-color: #d4e157;
}

.palette-Lime-400.text {
  color: #d4e157;
}

.palette-Lime-500.bg {
  background-color: #cddc39;
}

.palette-Lime-500.text {
  color: #cddc39;
}

.palette-Lime-600.bg {
  background-color: #c0ca33;
}

.palette-Lime-600.text {
  color: #c0ca33;
}

.palette-Lime-700.bg {
  background-color: #afb42b;
}

.palette-Lime-700.text {
  color: #afb42b;
}

.palette-Lime-800.bg {
  background-color: #9e9d24;
}

.palette-Lime-800.text {
  color: #9e9d24;
}

.palette-Lime-900.bg {
  background-color: #827717;
}

.palette-Lime-900.text {
  color: #827717;
}

.palette-Lime-A100.bg {
  background-color: #f4ff81;
}

.palette-Lime-A100.text {
  color: #f4ff81;
}

.palette-Lime-A200.bg {
  background-color: #eeff41;
}

.palette-Lime-A200.text {
  color: #eeff41;
}

.palette-Lime-A400.bg {
  background-color: #c6ff00;
}

.palette-Lime-A400.text {
  color: #c6ff00;
}

.palette-Lime-A700.bg {
  background-color: #aeea00;
}

.palette-Lime-A700.text {
  color: #aeea00;
}

.palette-Yellow.bg {
  background-color: #ffeb3b;
}

.palette-Yellow.text {
  color: #ffeb3b;
}

.palette-Yellow-50.bg {
  background-color: #fffde7;
}

.palette-Yellow-50.text {
  color: #fffde7;
}

.palette-Yellow-100.bg {
  background-color: #fff9c4;
}

.palette-Yellow-100.text {
  color: #fff9c4;
}

.palette-Yellow-200.bg {
  background-color: #fff59d;
}

.palette-Yellow-200.text {
  color: #fff59d;
}

.palette-Yellow-300.bg {
  background-color: #fff176;
}

.palette-Yellow-300.text {
  color: #fff176;
}

.palette-Yellow-400.bg {
  background-color: #ffee58;
}

.palette-Yellow-400.text {
  color: #ffee58;
}

.palette-Yellow-500.bg {
  background-color: #ffeb3b;
}

.palette-Yellow-500.text {
  color: #ffeb3b;
}

.palette-Yellow-600.bg {
  background-color: #fdd835;
}

.palette-Yellow-600.text {
  color: #fdd835;
}

.palette-Yellow-700.bg {
  background-color: #fbc02d;
}

.palette-Yellow-700.text {
  color: #fbc02d;
}

.palette-Yellow-800.bg {
  background-color: #f9a825;
}

.palette-Yellow-800.text {
  color: #f9a825;
}

.palette-Yellow-900.bg {
  background-color: #f57f17;
}

.palette-Yellow-900.text {
  color: #f57f17;
}

.palette-Yellow-A100.bg {
  background-color: #ffff8d;
}

.palette-Yellow-A100.text {
  color: #ffff8d;
}

.palette-Yellow-A200.bg {
  background-color: #ffff00;
}

.palette-Yellow-A200.text {
  color: #ffff00;
}

.palette-Yellow-A400.bg {
  background-color: #ffea00;
}

.palette-Yellow-A400.text {
  color: #ffea00;
}

.palette-Yellow-A700.bg {
  background-color: #ffd600;
}

.palette-Yellow-A700.text {
  color: #ffd600;
}

.palette-Amber.bg {
  background-color: #ffc107;
}

.palette-Amber.text {
  color: #ffc107;
}

.palette-Amber-50.bg {
  background-color: #fff8e1;
}

.palette-Amber-50.text {
  color: #fff8e1;
}

.palette-Amber-100.bg {
  background-color: #ffecb3;
}

.palette-Amber-100.text {
  color: #ffecb3;
}

.palette-Amber-200.bg {
  background-color: #ffe082;
}

.palette-Amber-200.text {
  color: #ffe082;
}

.palette-Amber-300.bg {
  background-color: #ffd54f;
}

.palette-Amber-300.text {
  color: #ffd54f;
}

.palette-Amber-400.bg {
  background-color: #ffca28;
}

.palette-Amber-400.text {
  color: #ffca28;
}

.palette-Amber-500.bg {
  background-color: #ffc107;
}

.palette-Amber-500.text {
  color: #ffc107;
}

.palette-Amber-600.bg {
  background-color: #ffb300;
}

.palette-Amber-600.text {
  color: #ffb300;
}

.palette-Amber-700.bg {
  background-color: #ffa000;
}

.palette-Amber-700.text {
  color: #ffa000;
}

.palette-Amber-800.bg {
  background-color: #ff8f00;
}

.palette-Amber-800.text {
  color: #ff8f00;
}

.palette-Amber-900.bg {
  background-color: #ff6f00;
}

.palette-Amber-900.text {
  color: #ff6f00;
}

.palette-Amber-A100.bg {
  background-color: #ffe57f;
}

.palette-Amber-A100.text {
  color: #ffe57f;
}

.palette-Amber-A200.bg {
  background-color: #ffd740;
}

.palette-Amber-A200.text {
  color: #ffd740;
}

.palette-Amber-A400.bg {
  background-color: #ffc400;
}

.palette-Amber-A400.text {
  color: #ffc400;
}

.palette-Amber-A700.bg {
  background-color: #ffab00;
}

.palette-Amber-A700.text {
  color: #ffab00;
}

.palette-Orange.bg {
  background-color: #ff9800;
}

.palette-Orange.text {
  color: #ff9800;
}

.palette-Orange-50.bg {
  background-color: #fff3e0;
}

.palette-Orange-50.text {
  color: #fff3e0;
}

.palette-Orange-100.bg {
  background-color: #ffe0b2;
}

.palette-Orange-100.text {
  color: #ffe0b2;
}

.palette-Orange-200.bg {
  background-color: #ffcc80;
}

.palette-Orange-200.text {
  color: #ffcc80;
}

.palette-Orange-300.bg {
  background-color: #ffb74d;
}

.palette-Orange-300.text {
  color: #ffb74d;
}

.palette-Orange-400.bg {
  background-color: #ffa726;
}

.palette-Orange-400.text {
  color: #ffa726;
}

.palette-Orange-500.bg {
  background-color: #ff9800;
}

.palette-Orange-500.text {
  color: #ff9800;
}

.palette-Orange-600.bg {
  background-color: #fb8c00;
}

.palette-Orange-600.text {
  color: #fb8c00;
}

.palette-Orange-700.bg {
  background-color: #f57c00;
}

.palette-Orange-700.text {
  color: #f57c00;
}

.palette-Orange-800.bg {
  background-color: #ef6c00;
}

.palette-Orange-800.text {
  color: #ef6c00;
}

.palette-Orange-900.bg {
  background-color: #e65100;
}

.palette-Orange-900.text {
  color: #e65100;
}

.palette-Orange-A100.bg {
  background-color: #ffd180;
}

.palette-Orange-A100.text {
  color: #ffd180;
}

.palette-Orange-A200.bg {
  background-color: #ffab40;
}

.palette-Orange-A200.text {
  color: #ffab40;
}

.palette-Orange-A400.bg {
  background-color: #ff9100;
}

.palette-Orange-A400.text {
  color: #ff9100;
}

.palette-Orange-A700.bg {
  background-color: #ff6d00;
}

.palette-Orange-A700.text {
  color: #ff6d00;
}

.palette-Deep-Orange.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange.text {
  color: #ff5722;
}

.palette-Deep-Orange-50.bg {
  background-color: #fbe9e7;
}

.palette-Deep-Orange-50.text {
  color: #fbe9e7;
}

.palette-Deep-Orange-100.bg {
  background-color: #ffccbc;
}

.palette-Deep-Orange-100.text {
  color: #ffccbc;
}

.palette-Deep-Orange-200.bg {
  background-color: #ffab91;
}

.palette-Deep-Orange-200.text {
  color: #ffab91;
}

.palette-Deep-Orange-300.bg {
  background-color: #ff8a65;
}

.palette-Deep-Orange-300.text {
  color: #ff8a65;
}

.palette-Deep-Orange-400.bg {
  background-color: #ff7043;
}

.palette-Deep-Orange-400.text {
  color: #ff7043;
}

.palette-Deep-Orange-500.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange-500.text {
  color: #ff5722;
}

.palette-Deep-Orange-600.bg {
  background-color: #f4511e;
}

.palette-Deep-Orange-600.text {
  color: #f4511e;
}

.palette-Deep-Orange-700.bg {
  background-color: #e64a19;
}

.palette-Deep-Orange-700.text {
  color: #e64a19;
}

.palette-Deep-Orange-800.bg {
  background-color: #d84315;
}

.palette-Deep-Orange-800.text {
  color: #d84315;
}

.palette-Deep-Orange-900.bg {
  background-color: #bf360c;
}

.palette-Deep-Orange-900.text {
  color: #bf360c;
}

.palette-Deep-Orange-A100.bg {
  background-color: #ff9e80;
}

.palette-Deep-Orange-A100.text {
  color: #ff9e80;
}

.palette-Deep-Orange-A200.bg {
  background-color: #ff6e40;
}

.palette-Deep-Orange-A200.text {
  color: #ff6e40;
}

.palette-Deep-Orange-A400.bg {
  background-color: #ff3d00;
}

.palette-Deep-Orange-A400.text {
  color: #ff3d00;
}

.palette-Deep-Orange-A700.bg {
  background-color: #dd2c00;
}

.palette-Deep-Orange-A700.text {
  color: #dd2c00;
}

.palette-Brown.bg {
  background-color: #795548;
}

.palette-Brown.text {
  color: #795548;
}

.palette-Brown-50.bg {
  background-color: #efebe9;
}

.palette-Brown-50.text {
  color: #efebe9;
}

.palette-Brown-100.bg {
  background-color: #d7ccc8;
}

.palette-Brown-100.text {
  color: #d7ccc8;
}

.palette-Brown-200.bg {
  background-color: #bcaaa4;
}

.palette-Brown-200.text {
  color: #bcaaa4;
}

.palette-Brown-300.bg {
  background-color: #a1887f;
}

.palette-Brown-300.text {
  color: #a1887f;
}

.palette-Brown-400.bg {
  background-color: #8d6e63;
}

.palette-Brown-400.text {
  color: #8d6e63;
}

.palette-Brown-500.bg {
  background-color: #795548;
}

.palette-Brown-500.text {
  color: #795548;
}

.palette-Brown-600.bg {
  background-color: #6d4c41;
}

.palette-Brown-600.text {
  color: #6d4c41;
}

.palette-Brown-700.bg {
  background-color: #5d4037;
}

.palette-Brown-700.text {
  color: #5d4037;
}

.palette-Brown-800.bg {
  background-color: #4e342e;
}

.palette-Brown-800.text {
  color: #4e342e;
}

.palette-Brown-900.bg {
  background-color: #3e2723;
}

.palette-Brown-900.text {
  color: #3e2723;
}

.palette-Grey.bg {
  background-color: #9e9e9e;
}

.palette-Grey.text {
  color: #9e9e9e;
}

.palette-Grey-50.bg {
  background-color: #fafafa;
}

.palette-Grey-50.text {
  color: #fafafa;
}

.palette-Grey-100.bg {
  background-color: #f5f5f5;
}

.palette-Grey-100.text {
  color: #f5f5f5;
}

.palette-Grey-200.bg {
  background-color: #eeeeee;
}

.palette-Grey-200.text {
  color: #eeeeee;
}

.palette-Grey-300.bg {
  background-color: #e0e0e0;
}

.palette-Grey-300.text {
  color: #e0e0e0;
}

.palette-Grey-400.bg {
  background-color: #bdbdbd;
}

.palette-Grey-400.text {
  color: #bdbdbd;
}

.palette-Grey-500.bg {
  background-color: #9e9e9e;
}

.palette-Grey-500.text {
  color: #9e9e9e;
}

.palette-Grey-600.bg {
  background-color: #757575;
}

.palette-Grey-600.text {
  color: #757575;
}

.palette-Grey-700.bg {
  background-color: #616161;
}

.palette-Grey-700.text {
  color: #616161;
}

.palette-Grey-800.bg {
  background-color: #424242;
}

.palette-Grey-800.text {
  color: #424242;
}

.palette-Grey-900.bg {
  background-color: #212121;
}

.palette-Grey-900.text {
  color: #212121;
}

.palette-Blue-Grey.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey.text {
  color: #607d8b;
}

.palette-Blue-Grey-50.bg {
  background-color: #eceff1;
}

.palette-Blue-Grey-50.text {
  color: #eceff1;
}

.palette-Blue-Grey-100.bg {
  background-color: #cfd8dc;
}

.palette-Blue-Grey-100.text {
  color: #cfd8dc;
}

.palette-Blue-Grey-200.bg {
  background-color: #b0bec5;
}

.palette-Blue-Grey-200.text {
  color: #b0bec5;
}

.palette-Blue-Grey-300.bg {
  background-color: #90a4ae;
}

.palette-Blue-Grey-300.text {
  color: #90a4ae;
}

.palette-Blue-Grey-400.bg {
  background-color: #78909c;
}

.palette-Blue-Grey-400.text {
  color: #78909c;
}

.palette-Blue-Grey-500.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey-500.text {
  color: #607d8b;
}

.palette-Blue-Grey-600.bg {
  background-color: #546e7a;
}

.palette-Blue-Grey-600.text {
  color: #546e7a;
}

.palette-Blue-Grey-700.bg {
  background-color: #455a64;
}

.palette-Blue-Grey-700.text {
  color: #455a64;
}

.palette-Blue-Grey-800.bg {
  background-color: #37474f;
}

.palette-Blue-Grey-800.text {
  color: #37474f;
}

.palette-Blue-Grey-900.bg {
  background-color: #263238;
}

.palette-Blue-Grey-900.text {
  color: #263238;
}

.palette-Black.bg {
  background-color: #000000;
}

.palette-Black.text {
  color: #000000;
}

.palette-Black-500.bg {
  background-color: #000000;
}

.palette-Black-500.text {
  color: #000000;
}

.palette-Black-Text.bg {
  background-color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Text.text {
  color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Secondary-Text.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Secondary-Text.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Disabled.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Disabled.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Dividers.bg {
  background-color: rgba(0, 0, 0, 0.12);
}

.palette-Black-Dividers.text {
  color: rgba(0, 0, 0, 0.12);
}

.palette-White.bg {
  background-color: #ffffff;
}

.palette-White.text {
  color: #ffffff;
}

.palette-White-500.bg {
  background-color: #ffffff;
}

.palette-White-500.text {
  color: #ffffff;
}

.palette-White-Text.bg {
  background-color: #ffffff;
}

.palette-White-Text.text {
  color: #ffffff;
}

.palette-White-Secondary-Text.bg {
  background-color: rgba(255, 255, 255, 0.7);
}

.palette-White-Secondary-Text.text {
  color: rgba(255, 255, 255, 0.7);
}

.palette-White-Icons.bg {
  background-color: #ffffff;
}

.palette-White-Icons.text {
  color: #ffffff;
}

.palette-White-Disabled.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Disabled.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Dividers.bg {
  background-color: rgba(255, 255, 255, 0.12);
}

.palette-White-Dividers.text {
  color: rgba(255, 255, 255, 0.12);
}

.palette-system-primary.bg {
  background-color: #409ace;
}

.palette-system-primary.text {
  color: #409ace;
}