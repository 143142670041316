﻿.cg-busy {
  z-index: 12000 !important;
}

.cg-busy-default-sign {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
  border: 0 !important;
  display: block !important;
  padding: 0 !important;
}

.cg-busy-default-text {
  display: none !important;
}

.cg-busy-default-spinner {
  position: absolute !important;
  margin: 0 auto !important;
  text-align: center !important;
  display: block !important;
  left: 50% !important;
  top: 40% !important;

  div {
    height: 50% !important;
  }
}
