$hover: 24;

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline: none;
}

button.close {
    outline: none;
}

.btn {
    border-radius: 5px;
    transition: all 0.1s ease;
    padding: 0.344rem 1rem;
    text-transform: uppercase;
    border-radius: 2px;
    height: 35px;
}

.btn:hover {
    transform: scale(1);
}

.btn-success {
    color: #fff;
    background-color: #1dbb99;
    border-color: #1dbb99;

    &:hover {
        background-color: #4ac9ad;
        border-color: #4ac9ad;
        color: #fff;
    }

    &:active {
        background-color: #17977c;
        border-color: #17977c;
        color: #fff;
    }
}

.btn-default {
    color: #5e5e5e;
    background-color: #fff;
    border-color: #e8e9ea;

    &:hover {
        background-color: #f5f6f7;
        border-color: rgba(87, 90, 93, 0.27);
        color: #18191a;
    }

    &:active {
        background-color: #e8e9ea;
        border-color: #e1e1e2;
        color: #2c2d2f;
    }
}

.btn-primary {
    color: #fff;
    background-color: #07c25f;
    border-color: #07c25f;

    &:hover {
        background-color: #39ce7f;
        border-color: #39ce7f;
        color: #fff;
    }

    &:active {
        background-color: #069d4d;
        border-color: #069d4d;
        color: #fff;
        box-shadow: none;
    }
}

.btn-danger {
    color: #fff;
    background-color: #f94816;
    border-color: #f94816;

    &:hover {
        background-color: #fa6d45;
        border-color: #fa6d45;
        color: #fff;
    }

    &:active {
        background-color: #ca3a12;
        border-color: #ca3a12;
        color: #fff;
        box-shadow: none;
    }
}

.btn-warning {
    background-color: #f7cf5f;
    border-color: #f7cf5f;
    color: #fff;

    &:hover {
        background-color: #f9d97f;
        border-color: #f9d97f;
        color: #fff;
    }

    &:active {
        background-color: #c8a84d;
        border-color: #c8a84d;
        color: #fff;
        box-shadow: none;
    }
}

.btn-inverse {
    background-color: #6f6e6f;
    border-color: #6f6e6f;
    color: #fff;

    &:hover {
        color: #333;
        background-color: #e6e6e6;
        border-color: #e6e6e6;
    }

    &:active {
        color: #fff;
        background-color: #9c9a9a;
        border-color: #9c9a9a;
    }
}

.ui-button.ui-button-icon-only {
    color: #2399e5;
    background: transparent;
    border: 0;

    &:hover,
    &:visited,
    &:focus {
        border: 0 !important;
        background: transparent !important;
        color: #2399e5 !important;
    }
}

.btn-block {
    width: 100% !important;
}
