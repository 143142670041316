﻿@import "./conf/conf";

$default-text-size: 14px;

@media only screen and (max-width: 1200px) and (min-width: 500px) {
  .top-page-dropdown {
    top: 15px !important;
  }

  .al-content {
    padding: 10px 10px 10px 10px !important;
  }

  .al-sidebar,
  .user-Profile,
  .brand-icon {
    width: $sidebar-width-1200 !important;

    #user-image {
      padding: 15px 0px 15px 25px !important;

      img {
        width: 70px !important;
      }
    }
  }

  .dropdown-button {
  }

  .page-top,
  .brand-icon {
    height: $top-height-1200 !important;
  }

  .al-sidebar {
    top: $top-height-1200 !important;
  }

  .multiLingual {
    right: 60px !important;

    button {
      padding: 21px 15px 23px 25px !important;
    }

    .dropdown-menu {
      min-width: 110px !important;
      border-top: 0 !important;
    }
  }

  .al-main {
    padding: 25px 0px 10px 0px;
    margin-left: $sidebar-width-1200 + 30 !important;
  }

  .menu-collapsed {
    .al-sidebar {
      width: $main-collapsed-1200 !important;
    }

    #patient-picture {
      width: $sidebar-width-1200;
      left: $main-collapsed-1200 !important;
      top: $top-height-1200;
      width: 240px;
    }

    .al-main {
      margin-left: $main-collapsed-1200 + 30 !important;
    }

    .business-card {
      width: 270px !important;
    }
  }

  #sidebar-icon {
    top: 17px !important;

    span.hamburger {
      top: 9px !important;

      span:before,
      span:after {
        width: 18px !important;
      }
    }

    span.line {
      width: 18px !important;
    }

    img {
      width: 30px !important;
    }
  }

  #search-item {
    top: 18px !important;
    left: 330px !important;
  }

  .al-sidebar-list-item {
    padding: 15px 0 !important;
  }

  a.al-sidebar-list-link {
  }

  .header-item {
    top: 15px !important;

    i {
    }
  }

  .business-card {
    width: 285px !important;

    .title {
      padding: 20px 0 0 0 !important;
    }

    .finer-print {
    }
  }

  .notification-hidden-icon {
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  .header-item {
    opacity: 0;
    pointer-events: none;
  }

  .rtl {
    .al-main {
      margin-left: auto !important;
      margin-right: $sidebar-width-1200 + 30 !important;
    }

    .menu-collapsed .al-main {
      margin-left: auto !important;
      margin-right: $main-collapsed-1200 + 30 !important;
    }

    #sidebar-icon {
      top: 17px !important;
      left: auto !important;

      span.hamburger {
        top: 9px !important;
        left: auto;

        span:before,
        span:after {
          width: 18px !important;
        }
      }
    }

    #search-item {
      left: auto !important;
      right: 330px !important;
    }

    #sidebar-icon .hamburger {
      left: auto !important;
      right: 331px;
    }

    .business-card .title {
      padding: 20px 105px 0 0 !important;
    }

    .multiLingual {
      left: 60px;
    }

    #user-image {
      padding: 15px 25px 15px 0 !important;
    }
  }
}
