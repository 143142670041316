@charset "UTF-8";
.rtl * {
  direction: rtl;
}
.rtl .d-flex-ar-row-reverse {
  flex-direction: row-reverse !important;
}
.rtl .d-flex-row-reverse-rtl {
  flex-direction: row-reverse !important;
}
.rtl .d-flex-row-reverse-rtl input {
  direction: ltr;
}
.rtl .d-flex-row-reverse {
  flex-direction: row !important;
}
.rtl .d-flex-row-reverse input {
  direction: rtl;
}
.rtl .p-dropdown .p-dropdown-label {
  text-align: right !important;
}
.rtl .al-main {
  margin-left: auto !important;
  margin-right: 300px !important;
}
.rtl .menu-collapsed .al-main {
  margin-left: auto !important;
  margin-right: 45px !important;
}
.rtl .al-content {
  float: right;
  width: 100%;
}
.rtl .breadcrumb .breadcrumb-item {
  float: right;
}
.rtl .multiLingual {
  right: auto !important;
  left: 220px;
}
.rtl #search-item {
  left: auto !important;
  right: 350px !important;
}
.rtl #shortcut-item {
  right: auto !important;
  left: 30px;
}
.rtl #favorite-item {
  right: auto !important;
  left: 80px;
}
.rtl #notification-item {
  right: auto !important;
  left: 130px;
}
.rtl #sql-builder-item {
  right: auto !important;
  left: 180px;
}
.rtl #sidebar-icon {
  left: auto !important;
  right: 280px;
}
.rtl #sidebar-icon .hamburger span {
  margin-left: auto !important;
  margin-right: 5px;
}
.rtl #sidebar-icon .hamburger {
  left: auto !important;
  right: 285px;
}
.rtl .bar {
  right: auto;
  left: 0;
}
.rtl .user-profile {
  float: left;
}
.rtl .user-image {
  padding: 20px 30px 20px 0px !important;
}
.rtl .user-Profile .user-name button > i {
  padding: 0 10px 0 0 !important;
}
.rtl .absolute-calendar-icon .ui-datepicker-trigger {
  position: absolute;
  left: 5px !important;
  top: 5px;
  right: auto !important;
}
.rtl aside.al-sidebar {
  left: auto !important;
  right: 0;
}
.rtl .page-top {
  padding: 0 0 0 32px;
  right: 0;
}
.rtl .col-sm-1,
.rtl .col-sm-2,
.rtl .col-sm-3,
.rtl .col-sm-4,
.rtl .col-sm-5,
.rtl .col-sm-6 {
  float: right;
}
.rtl .offset-sm-1,
.rtl .offset-sm-2,
.rtl .offset-sm-3,
.rtl .offset-sm-4,
.rtl .offset-sm-5,
.rtl .offset-sm-6 {
  margin-left: 0;
}
.rtl .offset-sm-1 {
  margin-right: 8.33333333%;
}
.rtl .offset-sm-2 {
  margin-right: 16.66666667%;
}
.rtl .offset-sm-3 {
  margin-right: 25%;
}
.rtl .offset-sm-4 {
  margin-right: 33.33333333%;
}
.rtl .offset-sm-5 {
  margin-right: 41.66666667%;
}
.rtl .offset-sm-6 {
  margin-right: 50%;
}
.rtl .ui-dropdown-label.ui-inputtext.ui-corner-all {
  text-align: left;
  direction: ltr;
}
.rtl a.al-sidebar-list-link {
  padding-left: 0 !important;
  padding-right: 25px !important;
}
.rtl a.al-sidebar-list-link i {
  margin-left: 25px !important;
  margin-right: 0 !important;
}
.rtl a.al-sidebar-list-link b {
  left: 10px !important;
  right: auto !important;
  margin-left: 20px !important;
  margin-right: 0 !important;
}
.rtl .al-sidebar-sublist ba-menu-item > li a {
  padding-left: 0 !important;
  padding-right: 85px !important;
}
.rtl .al-sidebar-sublist > .dialog-center > .ui-dialog ba-menu-item > li a {
  padding-left: 0 !important;
  padding-right: 52px !important;
}
.rtl .user-menu {
  left: auto;
  right: 0;
  text-align: right;
}
.rtl .user-menu > li {
  text-align: right !important;
}
.rtl .user-menu > li a > i {
  padding: 0 !important;
}
.rtl .more-datails-menu {
  left: 0;
  right: auto;
  text-align: right;
}
.rtl .more-datails-menu:after {
  content: "";
  border-color: transparent #f2f2f2 transparent transparent;
  left: -20px;
  right: auto;
}
.rtl a.collapse-menu-link {
  margin: 11px 300px 0 0 !important;
}
.rtl .top-dropdown-menu {
  right: auto !important;
  left: -47px !important;
}
.rtl .top-dropdown-menu .profile-dropdown {
  right: auto !important;
  left: 5px !important;
}
.rtl .top-dropdown-menu .profile-dropdown a {
  text-align: right;
  padding: 4px 16px 4px 20px;
}
.rtl .top-dropdown-menu .profile-dropdown a i {
  margin-right: 0;
  margin-left: 10px;
}
.rtl .business-card .title {
  padding: 15px 30px 0 0;
}
.rtl .business-card .more-icon {
  right: auto;
  left: 0;
}
.rtl .business-card .content {
  float: right;
  padding: 10px 20px 0 0px;
}
.rtl .notification-hidden-icon {
  right: auto !important;
  left: 15px !important;
}
.rtl .notification-hidden-icon .dropdown-notification-icon {
  left: 0 !important;
  right: auto !important;
}
.rtl .notification-hidden-icon .dropdown-notification-icon li {
  padding: 10px 0;
  text-align: right;
}
.rtl #user-image {
  padding: 20px 30px 20px 0 !important;
}
.rtl .multiLingual-mobile {
  top: 15px;
  left: 60px !important;
  right: auto !important;
}
.rtl .icon {
  left: 0;
  right: auto !important;
}
.rtl .occupancy-rate-card {
  float: right;
}
.rtl .settings {
  right: auto !important;
  left: -80px !important;
  transform: rotate(270deg) !important;
}
.rtl .settings.active-result {
  left: 290px !important;
  right: auto !important;
}
.rtl .action-menu {
  float: right !important;
  text-align: center !important;
  left: 0px !important;
  margin-right: -150px !important;
  right: auto !important;
}
.rtl sidebar-notification-cmp #search-bar form .row > div {
  float: right;
}
.rtl .progress-result {
  float: right;
}
.rtl .search-title {
  left: auto !important;
  right: 50px !important;
}
.rtl #close-search {
  left: 20px !important;
  right: auto !important;
}
.rtl #patient-status li {
  float: right !important;
}
.rtl .text-left {
  text-align: right !important;
}
.rtl .auto-refresh {
  float: right;
}
.rtl .auto-refresh div {
  float: right !important;
}
.rtl .form-horizontal .form-group.row.form-group-default label {
  margin-top: 5px !important;
}
.rtl .control-label {
  margin-top: 10px !important;
}
.rtl .list-group.vertical-steps .list-group-item {
  border-left: 0 !important;
  border-right: 3px solid #4791cd !important;
  padding-right: 21px !important;
  padding-left: 0px !important;
}
.rtl .list-group.vertical-steps .list-group-item::before {
  float: right !important;
  margin-right: -35px !important;
  margin-left: 0 !important;
}
.rtl .ui-paginator-bottom .ui-paginator-element > span {
  transform: rotate(180deg) !important;
}
.rtl .collapsed .menu-panel-title a {
  padding-left: 0 !important;
  padding-right: 75px !important;
}
.rtl .arow-item {
  right: auto !important;
  left: 10px;
  margin-right: 0 !important;
  margin-left: 20px;
}
.rtl .menu-panel-title i {
  margin-left: 15px !important;
  margin-right: 0 !important;
}
.rtl .fab {
  left: 25px;
  right: auto !important;
}
.rtl .dropdown-menu {
  right: auto !important;
  left: -20px !important;
}

/*$body-bg: #F0F3F4; // default bg color*/
/* font-size */
.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* font-size */
/* font-weight */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* font-weight */
/* text-decoration */
.decoration-dashed {
  text-decoration: dashed !important;
}

.decoration-dotted {
  text-decoration: dotted !important;
}

.decoration-double {
  text-decoration: double !important;
}

.decoration-line-through {
  text-decoration: line-through !important;
}

.decoration-overline {
  text-decoration: overline !important;
}

.decoration-solid {
  text-decoration: solid !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.decoration-wavy {
  text-decoration: wavy !important;
}

.decoration-none {
  text-decoration: none !important;
}

/* text-decoration */
/* font-family */
.ff-opensans {
  font-family: "Open Sans" !important;
}

.ff-segoeui {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.ff-ubunto {
  font-family: "ubunto" !important;
}

.ff-cormorantunicase {
  font-family: "Cormorant Unicase" !important;
}

/* font-family */
/* element style */
.no-border {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-none {
  display: none !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

/* element style */
/* padding */
.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.plr-5 {
  padding: 0 5px !important;
}

.plr-10 {
  padding: 0 10px !important;
}

.plr-15 {
  padding: 0 15px !important;
}

.plr-20 {
  padding: 0 20px !important;
}

.plr-25 {
  padding: 0 25px !important;
}

.plr-30 {
  padding: 0 30px !important;
}

.plr-35 {
  padding: 0 35px !important;
}

.plr-40 {
  padding: 0 40px !important;
}

.plr-45 {
  padding: 0 45px !important;
}

.plr-50 {
  padding: 0 50px !important;
}

.plr-55 {
  padding: 0 55px !important;
}

.plr-60 {
  padding: 0 60px !important;
}

.plr-65 {
  padding: 0 65px !important;
}

.plr-70 {
  padding: 0 70px !important;
}

.plr-75 {
  padding: 0 75px !important;
}

.plr-80 {
  padding: 0 80px !important;
}

.plr-85 {
  padding: 0 85px !important;
}

.plr-90 {
  padding: 0 90px !important;
}

.plr-95 {
  padding: 0 95px !important;
}

.plr-100 {
  padding: 0 100px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

.ptb-55 {
  padding: 55px 0 !important;
}

.ptb-60 {
  padding: 60px 0 !important;
}

.ptb-65 {
  padding: 65px 0 !important;
}

.ptb-70 {
  padding: 70px 0 !important;
}

.ptb-75 {
  padding: 75px 0 !important;
}

.ptb-80 {
  padding: 80px 0 !important;
}

.ptb-85 {
  padding: 85px 0 !important;
}

.ptb-90 {
  padding: 90px 0 !important;
}

.ptb-95 {
  padding: 95px 0 !important;
}

.ptb-100 {
  padding: 100px 0 0 !important;
}

.padding-5 {
  padding: 5px !important;
}
.padding-5.padding-top {
  padding: 5px 0 0 0 !important;
}
.padding-5.padding-left {
  padding: 0 0 0 5px !important;
}
.padding-5.padding-right {
  padding: 0 5px 0 0 !important;
}
.padding-5.padding-bottom {
  padding: 0 0 5px 0 !important;
}

.padding-10 {
  padding: 10px !important;
}
.padding-10.padding-top {
  padding: 10px 0 0 0 !important;
}
.padding-10.padding-left {
  padding: 0 0 0 10px !important;
}
.padding-10.padding-right {
  padding: 0 10px 0 0 !important;
}
.padding-10.padding-bottom {
  padding: 0 0 10px 0 !important;
}

.padding-15 {
  padding: 15px !important;
}
.padding-15.padding-top {
  padding: 15px 0 0 0 !important;
}
.padding-15.padding-left {
  padding: 0 0 0 15px !important;
}
.padding-15.padding-right {
  padding: 0 15px 0 0 !important;
}
.padding-15.padding-bottom {
  padding: 0 0 15px 0 !important;
}

.padding-20 {
  padding: 20px !important;
}
.padding-20.padding-top {
  padding: 20px 0 0 0 !important;
}
.padding-20.padding-left {
  padding: 0 0 0 20px !important;
}
.padding-20.padding-right {
  padding: 0 20px 0 0 !important;
}
.padding-20.padding-bottom {
  padding: 0 0 20px 0 !important;
}

.padding-25 {
  padding: 25px !important;
}
.padding-25.padding-top {
  padding: 25px 0 0 0 !important;
}
.padding-25.padding-left {
  padding: 0 0 0 25px !important;
}
.padding-25.padding-right {
  padding: 0 25px 0 0 !important;
}
.padding-25.padding-bottom {
  padding: 0 0 25px 0 !important;
}

.padding-30 {
  padding: 30px !important;
}
.padding-30.padding-top {
  padding: 30px 0 0 0 !important;
}
.padding-30.padding-left {
  padding: 0 0 0 30px !important;
}
.padding-30.padding-right {
  padding: 0 30px 0 0 !important;
}
.padding-30.padding-bottom {
  padding: 0 0 30px 0 !important;
}

.padding-35 {
  padding: 35px !important;
}
.padding-35.padding-top {
  padding: 35px 0 0 0 !important;
}
.padding-35.padding-left {
  padding: 0 0 0 35px !important;
}
.padding-35.padding-right {
  padding: 0 35px 0 0 !important;
}
.padding-35.padding-bottom {
  padding: 0 0 35px 0 !important;
}

.padding-40 {
  padding: 40px !important;
}
.padding-40.padding-top {
  padding: 40px 0 0 0 !important;
}
.padding-40.padding-left {
  padding: 0 0 0 40px !important;
}
.padding-40.padding-right {
  padding: 0 40px 0 0 !important;
}
.padding-40.padding-bottom {
  padding: 0 0 40px 0 !important;
}

.padding-45 {
  padding: 45px !important;
}
.padding-45.padding-top {
  padding: 45px 0 0 0 !important;
}
.padding-45.padding-left {
  padding: 0 0 0 45px !important;
}
.padding-45.padding-right {
  padding: 0 45px 0 0 !important;
}
.padding-45.padding-bottom {
  padding: 0 0 45px 0 !important;
}

.padding-50 {
  padding: 50px !important;
}
.padding-50.padding-top {
  padding: 50px 0 0 0 !important;
}
.padding-50.padding-left {
  padding: 0 0 0 50px !important;
}
.padding-50.padding-right {
  padding: 0 50px 0 0 !important;
}
.padding-50.padding-bottom {
  padding: 0 0 50px 0 !important;
}

.padding-55 {
  padding: 55px !important;
}
.padding-55.padding-top {
  padding: 55px 0 0 0 !important;
}
.padding-55.padding-left {
  padding: 0 0 0 55px !important;
}
.padding-55.padding-right {
  padding: 0 55px 0 0 !important;
}
.padding-55.padding-bottom {
  padding: 0 0 55px 0 !important;
}

.padding-60 {
  padding: 60px !important;
}
.padding-60.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-60.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-60.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-60.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-65 {
  padding: 65px !important;
}
.padding-65.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-65.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-65.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-65.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-70 {
  padding: 70px !important;
}
.padding-70.padding-top {
  padding: 70px 0 0 0 !important;
}
.padding-70.padding-left {
  padding: 0 0 0 70px !important;
}
.padding-70.padding-right {
  padding: 0 70px 0 0 !important;
}
.padding-70.padding-bottom {
  padding: 0 0 70px 0 !important;
}

.padding-75 {
  padding: 75px !important;
}
.padding-75.padding-top {
  padding: 75px 0 0 0 !important;
}
.padding-75.padding-left {
  padding: 0 0 0 75px !important;
}
.padding-75.padding-right {
  padding: 0 75px 0 0 !important;
}
.padding-75.padding-bottom {
  padding: 0 0 75px 0 !important;
}

.padding-80 {
  padding: 80px !important;
}
.padding-80.padding-top {
  padding: 80px 0 0 0 !important;
}
.padding-80.padding-left {
  padding: 0 0 0 80px !important;
}
.padding-80.padding-right {
  padding: 0 80px 0 0 !important;
}
.padding-80.padding-bottom {
  padding: 0 0 80px 0 !important;
}

.padding-85 {
  padding: 85px !important;
}
.padding-85.padding-top {
  padding: 85px 0 0 0 !important;
}
.padding-85.padding-left {
  padding: 0 0 0 85px !important;
}
.padding-85.padding-right {
  padding: 0 85px 0 0 !important;
}
.padding-85.padding-bottom {
  padding: 0 0 85px 0 !important;
}

.padding-90 {
  padding: 90px !important;
}
.padding-90.padding-top {
  padding: 90px 0 0 0 !important;
}
.padding-90.padding-left {
  padding: 0 0 0 90px !important;
}
.padding-90.padding-right {
  padding: 0 90px 0 0 !important;
}
.padding-90.padding-bottom {
  padding: 0 0 90px 0 !important;
}

.padding-95 {
  padding: 95px !important;
}
.padding-95.padding-top {
  padding: 95px 0 0 0 !important;
}
.padding-95.padding-left {
  padding: 0 0 0 95px !important;
}
.padding-95.padding-right {
  padding: 0 95px 0 0 !important;
}
.padding-95.padding-bottom {
  padding: 0 0 95px 0 !important;
}

.padding-100 {
  padding: 100px !important;
}
.padding-100.padding-top {
  padding: 100px 0 0 0 !important;
}
.padding-100.padding-left {
  padding: 0 0 0 100px !important;
}
.padding-100.padding-right {
  padding: 0 100px 0 0 !important;
}
.padding-100.padding-bottom {
  padding: 0 0 100px 0 !important;
}

/* padding */
/* margin */
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mlr-5 {
  margin: 0 5px !important;
}

.mlr-10 {
  margin: 0 10px !important;
}

.mlr-15 {
  margin: 0 15px !important;
}

.mlr-20 {
  margin: 0 20px !important;
}

.mlr-25 {
  margin: 0 25px !important;
}

.mlr-30 {
  margin: 0 30px !important;
}

.mlr-35 {
  margin: 0 35px !important;
}

.mlr-40 {
  margin: 0 40px !important;
}

.mlr-45 {
  margin: 0 45px !important;
}

.mlr-50 {
  margin: 0 50px !important;
}

.mlr-55 {
  margin: 0 55px !important;
}

.mlr-60 {
  margin: 0 60px !important;
}

.mlr-65 {
  margin: 0 65px !important;
}

.mlr-70 {
  margin: 0 70px !important;
}

.mlr-75 {
  margin: 0 75px !important;
}

.mlr-80 {
  margin: 0 80px !important;
}

.mlr-85 {
  margin: 0 85px !important;
}

.mlr-90 {
  margin: 0 90px !important;
}

.mlr-95 {
  margin: 0 95px !important;
}

.mlr-100 {
  margin: 0 100px !important;
}

.mtb-5 {
  margin: 5px 0 !important;
}

.mtb-10 {
  margin: 10px 0 !important;
}

.mtb-15 {
  margin: 15px 0 !important;
}

.mtb-20 {
  margin: 20px 0 !important;
}

.mtb-25 {
  margin: 25px 0 !important;
}

.mtb-30 {
  margin: 30px 0 !important;
}

.mtb-35 {
  margin: 35px 0 !important;
}

.mtb-40 {
  margin: 40px 0 !important;
}

.mtb-45 {
  margin: 45px 0 !important;
}

.mtb-50 {
  margin: 50px 0 !important;
}

.mtb-55 {
  margin: 55px 0 !important;
}

.mtb-60 {
  margin: 60px 0 !important;
}

.mtb-65 {
  margin: 65px 0 !important;
}

.mtb-70 {
  margin: 70px 0 !important;
}

.mtb-75 {
  margin: 75px 0 !important;
}

.mtb-80 {
  margin: 80px 0 !important;
}

.mtb-85 {
  margin: 85px 0 !important;
}

.mtb-90 {
  margin: 90px 0 !important;
}

.mtb-95 {
  margin: 95px 0 !important;
}

.mtb-100 {
  margin: 100px 0 0 !important;
}

.margin-5 {
  margin: 5px !important;
}
.margin-5.margin-top {
  margin: 5px 0 0 0 !important;
}
.margin-5.margin-left {
  margin: 0 0 0 5px !important;
}
.margin-5.margin-right {
  margin: 0 5px 0 0 !important;
}
.margin-5.margin-bottom {
  margin: 0 0 5px 0 !important;
}

.margin-10 {
  margin: 10px !important;
}
.margin-10.margin-top {
  margin: 10px 0 0 0 !important;
}
.margin-10.margin-left {
  margin: 0 0 0 10px !important;
}
.margin-10.margin-right {
  margin: 0 10px 0 0 !important;
}
.margin-10.margin-bottom {
  margin: 0 0 10px 0 !important;
}

.margin-15 {
  margin: 15px !important;
}
.margin-15.margin-top {
  margin: 15px 0 0 0 !important;
}
.margin-15.margin-left {
  margin: 0 0 0 15px !important;
}
.margin-15.margin-right {
  margin: 0 15px 0 0 !important;
}
.margin-15.margin-bottom {
  margin: 0 0 15px 0 !important;
}

.margin-20 {
  margin: 20px !important;
}
.margin-20.margin-top {
  margin: 20px 0 0 0 !important;
}
.margin-20.margin-left {
  margin: 0 0 0 20px !important;
}
.margin-20.margin-right {
  margin: 0 20px 0 0 !important;
}
.margin-20.margin-bottom {
  margin: 0 0 20px 0 !important;
}

.margin-25 {
  margin: 25px !important;
}
.margin-25.margin-top {
  margin: 25px 0 0 0 !important;
}
.margin-25.margin-left {
  margin: 0 0 0 25px !important;
}
.margin-25.margin-right {
  margin: 0 25px 0 0 !important;
}
.margin-25.margin-bottom {
  margin: 0 0 25px 0 !important;
}

.margin-30 {
  margin: 30px !important;
}
.margin-30.margin-top {
  margin: 30px 0 0 0 !important;
}
.margin-30.margin-left {
  margin: 0 0 0 30px !important;
}
.margin-30.margin-right {
  margin: 0 30px 0 0 !important;
}
.margin-30.margin-bottom {
  margin: 0 0 30px 0 !important;
}

.margin-35 {
  margin: 35px !important;
}
.margin-35.margin-top {
  margin: 35px 0 0 0 !important;
}
.margin-35.margin-left {
  margin: 0 0 0 35px !important;
}
.margin-35.margin-right {
  margin: 0 35px 0 0 !important;
}
.margin-35.margin-bottom {
  margin: 0 0 35px 0 !important;
}

.margin-40 {
  margin: 40px !important;
}
.margin-40.margin-top {
  margin: 40px 0 0 0 !important;
}
.margin-40.margin-left {
  margin: 0 0 0 40px !important;
}
.margin-40.margin-right {
  margin: 0 40px 0 0 !important;
}
.margin-40.margin-bottom {
  margin: 0 0 40px 0 !important;
}

.margin-45 {
  margin: 45px !important;
}
.margin-45.margin-top {
  margin: 45px 0 0 0 !important;
}
.margin-45.margin-left {
  margin: 0 0 0 45px !important;
}
.margin-45.margin-right {
  margin: 0 45px 0 0 !important;
}
.margin-45.margin-bottom {
  margin: 0 0 45px 0 !important;
}

.margin-50 {
  margin: 50px !important;
}
.margin-50.margin-top {
  margin: 50px 0 0 0 !important;
}
.margin-50.margin-left {
  margin: 0 0 0 50px !important;
}
.margin-50.margin-right {
  margin: 0 50px 0 0 !important;
}
.margin-50.margin-bottom {
  margin: 0 0 50px 0 !important;
}

.margin-55 {
  margin: 55px !important;
}
.margin-55.margin-top {
  margin: 55px 0 0 0 !important;
}
.margin-55.margin-left {
  margin: 0 0 0 55px !important;
}
.margin-55.margin-right {
  margin: 0 55px 0 0 !important;
}
.margin-55.margin-bottom {
  margin: 0 0 55px 0 !important;
}

.margin-60 {
  margin: 60px !important;
}
.margin-60.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-60.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-60.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-60.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-65 {
  margin: 65px !important;
}
.margin-65.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-65.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-65.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-65.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-70 {
  margin: 70px !important;
}
.margin-70.margin-top {
  margin: 70px 0 0 0 !important;
}
.margin-70.margin-left {
  margin: 0 0 0 70px !important;
}
.margin-70.margin-right {
  margin: 0 70px 0 0 !important;
}
.margin-70.margin-bottom {
  margin: 0 0 70px 0 !important;
}

.margin-75 {
  margin: 75px !important;
}
.margin-75.margin-top {
  margin: 75px 0 0 0 !important;
}
.margin-75.margin-left {
  margin: 0 0 0 75px !important;
}
.margin-75.margin-right {
  margin: 0 75px 0 0 !important;
}
.margin-75.margin-bottom {
  margin: 0 0 75px 0 !important;
}

.margin-80 {
  margin: 80px !important;
}
.margin-80.margin-top {
  margin: 80px 0 0 0 !important;
}
.margin-80.margin-left {
  margin: 0 0 0 80px !important;
}
.margin-80.margin-right {
  margin: 0 80px 0 0 !important;
}
.margin-80.margin-bottom {
  margin: 0 0 80px 0 !important;
}

.margin-85 {
  margin: 85px !important;
}
.margin-85.margin-top {
  margin: 85px 0 0 0 !important;
}
.margin-85.margin-left {
  margin: 0 0 0 85px !important;
}
.margin-85.margin-right {
  margin: 0 85px 0 0 !important;
}
.margin-85.margin-bottom {
  margin: 0 0 85px 0 !important;
}

.margin-90 {
  margin: 90px !important;
}
.margin-90.margin-top {
  margin: 90px 0 0 0 !important;
}
.margin-90.margin-left {
  margin: 0 0 0 90px !important;
}
.margin-90.margin-right {
  margin: 0 90px 0 0 !important;
}
.margin-90.margin-bottom {
  margin: 0 0 90px 0 !important;
}

.margin-95 {
  margin: 95px !important;
}
.margin-95.margin-top {
  margin: 95px 0 0 0 !important;
}
.margin-95.margin-left {
  margin: 0 0 0 95px !important;
}
.margin-95.margin-right {
  margin: 0 95px 0 0 !important;
}
.margin-95.margin-bottom {
  margin: 0 0 95px 0 !important;
}

.margin-100 {
  margin: 100px !important;
}
.margin-100.margin-top {
  margin: 100px 0 0 0 !important;
}
.margin-100.margin-left {
  margin: 0 0 0 100px !important;
}
.margin-100.margin-right {
  margin: 0 100px 0 0 !important;
}
.margin-100.margin-bottom {
  margin: 0 0 100px 0 !important;
}

/* margin */
/* height */
.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-310 {
  height: 310px !important;
}

.h-320 {
  height: 320px !important;
}

.h-330 {
  height: 330px !important;
}

.h-340 {
  height: 340px !important;
}

.h-350 {
  height: 350px !important;
}

.h-360 {
  height: 360px !important;
}

.h-370 {
  height: 370px !important;
}

.h-380 {
  height: 380px !important;
}

.h-390 {
  height: 390px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

.h-750 {
  height: 750px !important;
}

.h-800 {
  height: 800px !important;
}

/* height */
/* width */
.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.w-750 {
  width: 750px !important;
}

.w-800 {
  width: 800px !important;
}

/* width */
/* min height */
.min-h-50 {
  min-height: 50px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.min-h-450 {
  min-height: 450px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.min-h-650 {
  min-height: 650px !important;
}

.min-h-700 {
  min-height: 700px !important;
}

.min-h-750 {
  min-height: 750px !important;
}

.min-h-800 {
  min-height: 800px !important;
}

/* min height */
/* min width */
.min-w-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.min-w-450 {
  min-width: 450px !important;
}

.min-w-500 {
  min-width: 500px !important;
}

.min-w-550 {
  min-width: 550px !important;
}

.min-w-600 {
  min-width: 600px !important;
}

.min-w-650 {
  min-width: 650px !important;
}

.min-w-700 {
  min-width: 700px !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.min-w-800 {
  min-width: 800px !important;
}

/* min width */
/* max height */
.max-h-50 {
  max-height: 50px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-550 {
  max-height: 550px !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-650 {
  max-height: 650px !important;
}

.max-h-700 {
  max-height: 700px !important;
}

.max-h-750 {
  max-height: 750px !important;
}

.max-h-800 {
  max-height: 800px !important;
}

/* max height */
/* max width */
.max-w-50 {
  max-width: 50px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-550 {
  max-width: 550px !important;
}

.max-w-600 {
  max-width: 600px !important;
}

.max-w-650 {
  max-width: 650px !important;
}

.max-w-700 {
  max-width: 700px !important;
}

.max-w-750 {
  max-width: 750px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

/* max width */
/* white-space */
.ws-inherit {
  white-space: inherit !important;
}

.ws-initial {
  white-space: initial !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.ws-pre {
  white-space: pre !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.ws-unset {
  white-space: unset !important;
}

/* white-space */
/* margin */
/* border */
.no-border {
  border: none !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.b-3 {
  border-width: 3px !important;
}

.b-4 {
  border-width: 4px !important;
}

.b-5 {
  border-width: 5px !important;
}

.b-6 {
  border-width: 6px !important;
}

.b-7 {
  border-width: 7px !important;
}

.b-8 {
  border-width: 8px !important;
}

.b-9 {
  border-width: 9px !important;
}

.b-10 {
  border-width: 10px !important;
}

.bl-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
}

.bl-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
}

.bl-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
}

.bl-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
}

.bl-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
}

.bl-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
}

.bl-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
}

.bl-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
}

.bl-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
}

.bl-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
}

.br-1 {
  border-width: 0 !important;
  border-right-width: 1px !important;
}

.br-2 {
  border-width: 0 !important;
  border-right-width: 2px !important;
}

.br-3 {
  border-width: 0 !important;
  border-right-width: 3px !important;
}

.br-4 {
  border-width: 0 !important;
  border-right-width: 4px !important;
}

.br-5 {
  border-width: 0 !important;
  border-right-width: 5px !important;
}

.br-6 {
  border-width: 0 !important;
  border-right-width: 6px !important;
}

.br-7 {
  border-width: 0 !important;
  border-right-width: 7px !important;
}

.br-8 {
  border-width: 0 !important;
  border-right-width: 8px !important;
}

.br-9 {
  border-width: 0 !important;
  border-right-width: 9px !important;
}

.br-10 {
  border-width: 0 !important;
  border-right-width: 10px !important;
}

.bt-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

.bt-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
}

.bt-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
}

.bt-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
}

.bt-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
}

.bt-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
}

.bt-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
}

.bt-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
}

.bt-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
}

.bt-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
}

.bb-1 {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-width: 0 !important;
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-width: 0 !important;
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-width: 0 !important;
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-width: 0 !important;
  border-bottom-width: 5px !important;
}

.bb-6 {
  border-width: 0 !important;
  border-bottom-width: 6px !important;
}

.bb-7 {
  border-width: 0 !important;
  border-bottom-width: 7px !important;
}

.bb-8 {
  border-width: 0 !important;
  border-bottom-width: 8px !important;
}

.bb-9 {
  border-width: 0 !important;
  border-bottom-width: 9px !important;
}

.bb-10 {
  border-width: 0 !important;
  border-bottom-width: 10px !important;
}

.blr-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.blr-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.blr-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.blr-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.blr-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}

.blr-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
  border-right-width: 6px !important;
}

.blr-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
  border-right-width: 7px !important;
}

.blr-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.blr-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}

.blr-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
  border-right-width: 10px !important;
}

.btb-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btb-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.btb-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

.btb-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.btb-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
  border-bottom-width: 5px !important;
}

.btb-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
  border-bottom-width: 6px !important;
}

.btb-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
  border-bottom-width: 7px !important;
}

.btb-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.btb-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
  border-bottom-width: 9px !important;
}

.btb-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
  border-bottom-width: 10px !important;
}

.b-dotted {
  border-style: dotted !important;
}

.b-dashed {
  border-style: dashed !important;
}

.b-solid {
  border-style: solid !important;
}

.b-double {
  border-style: double !important;
}

.b-groove {
  border-style: groove !important;
}

.b-ridge {
  border-style: ridge !important;
}

.b-inset {
  border-style: inset !important;
}

.b-outset {
  border-style: outset !important;
}

.b-none {
  border-style: none !important;
}

.b-hidden {
  border-style: hidden !important;
}

.b-mix {
  border-style: dotted dashed solid double !important;
}

/* border */
/* outline style */
.outline-dotted {
  outline-style: dotted;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-solid {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-groove {
  outline-style: groove;
}

.outline-ridge {
  outline-style: ridge;
}

.outline-inset {
  outline-style: inset;
}

.outline-outset {
  outline-style: outset;
}

/* outline style */
/* h and small */
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  font-size: 75%;
  color: #798892;
}

/* text align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-left-exact {
  text-align: left !important;
}

/* text align */
/* border */
.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

/* border */
/* float */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* float */
/* direction */
.direction-right {
  direction: rtl !important;
}

.direction-left {
  direction: ltr !important;
}

/* direction */
/* spacing */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.pb-0,
.py-0,
.p-0 {
  padding-bottom: 0 !important;
}

.pt-0,
.py-0,
.p-0 {
  padding-top: 0 !important;
}

.pl-0,
.px-0,
.p-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0,
.p-0 {
  padding-right: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-4 {
  padding-bottom: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.mb-0,
.my-0,
.m-0 {
  margin-bottom: 0 !important;
}

.mt-0,
.my-0,
.m-0 {
  margin-top: 0 !important;
}

.ml-0,
.mx-0,
.m-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0,
.m-0 {
  margin-right: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-4 {
  margin-bottom: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* spacing */
/* overflow */
.s-overflow-x {
  overflow-x: scroll !important;
}

.s-overflow-y {
  overflow-y: scroll !important;
}

.s-overflow {
  overflow: scroll !important;
}

.a-overflow-x {
  overflow-x: auto !important;
}

.a-overflow-y {
  overflow-y: auto !important;
}

.a-overflow {
  overflow: auto !important;
}

.h-overflow-x {
  overflow-x: hidden !important;
}

.h-overflow-y {
  overflow-y: hidden !important;
}

.h-overflow {
  overflow: hidden !important;
}

/* overflow */
/* text align */
/* cursor */
.c-pointer {
  cursor: pointer !important;
}

.c-none {
  cursor: none !important;
}

.c-initial {
  cursor: initial !important;
}

/* cursor */
/* float */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* float */
.width-5rem {
  width: 5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-center {
  display: flex;
}
.flex-center.horizontal {
  justify-content: center;
}
.flex-center.vertical {
  align-items: center;
}
.flex-center.both {
  align-items: center;
  justify-content: center;
}
.flex-center.space-around {
  justify-content: space-around;
}
.flex-center.space-between {
  justify-content: space-between;
}

.zoom-09 {
  zoom: 0.9 !important;
}

.width-2rem {
  width: 2rem !important;
}

.width-3rem {
  width: 3rem;
}

.width-6rem {
  width: 6rem;
}

.width-8rem {
  width: 8rem;
}

.width-12rem {
  width: 12rem;
}

.h-100vh {
  height: 100vh !important;
}

.box-shadow-50 {
  box-shadow: 5px 0px 28px 10px rgba(0, 0, 0, 0.5) !important;
}

/**
 * google-material-color v1.2.6
 * https://github.com/danlevan/google-material-color
 */
.palette-Red.bg {
  background-color: #f44336;
}

.palette-Red.text {
  color: #f44336;
}

.palette-Red-50.bg {
  background-color: #ffebee;
}

.palette-Red-50.text {
  color: #ffebee;
}

.palette-Red-100.bg {
  background-color: #ffcdd2;
}

.palette-Red-100.text {
  color: #ffcdd2;
}

.palette-Red-200.bg {
  background-color: #ef9a9a;
}

.palette-Red-200.text {
  color: #ef9a9a;
}

.palette-Red-300.bg {
  background-color: #e57373;
}

.palette-Red-300.text {
  color: #e57373;
}

.palette-Red-400.bg {
  background-color: #ef5350;
}

.palette-Red-400.text {
  color: #ef5350;
}

.palette-Red-500.bg {
  background-color: #f44336;
}

.palette-Red-500.text {
  color: #f44336;
}

.palette-Red-600.bg {
  background-color: #e53935;
}

.palette-Red-600.text {
  color: #e53935;
}

.palette-Red-700.bg {
  background-color: #d32f2f;
}

.palette-Red-700.text {
  color: #d32f2f;
}

.palette-Red-800.bg {
  background-color: #c62828;
}

.palette-Red-800.text {
  color: #c62828;
}

.palette-Red-900.bg {
  background-color: #b71c1c;
}

.palette-Red-900.text {
  color: #b71c1c;
}

.palette-Red-A100.bg {
  background-color: #ff8a80;
}

.palette-Red-A100.text {
  color: #ff8a80;
}

.palette-Red-A200.bg {
  background-color: #ff5252;
}

.palette-Red-A200.text {
  color: #ff5252;
}

.palette-Red-A400.bg {
  background-color: #ff1744;
}

.palette-Red-A400.text {
  color: #ff1744;
}

.palette-Red-A700.bg {
  background-color: #d50000;
}

.palette-Red-A700.text {
  color: #d50000;
}

.palette-Pink.bg {
  background-color: #e91e63;
}

.palette-Pink.text {
  color: #e91e63;
}

.palette-Pink-50.bg {
  background-color: #fce4ec;
}

.palette-Pink-50.text {
  color: #fce4ec;
}

.palette-Pink-100.bg {
  background-color: #f8bbd0;
}

.palette-Pink-100.text {
  color: #f8bbd0;
}

.palette-Pink-200.bg {
  background-color: #f48fb1;
}

.palette-Pink-200.text {
  color: #f48fb1;
}

.palette-Pink-300.bg {
  background-color: #f06292;
}

.palette-Pink-300.text {
  color: #f06292;
}

.palette-Pink-400.bg {
  background-color: #ec407a;
}

.palette-Pink-400.text {
  color: #ec407a;
}

.palette-Pink-500.bg {
  background-color: #e91e63;
}

.palette-Pink-500.text {
  color: #e91e63;
}

.palette-Pink-600.bg {
  background-color: #d81b60;
}

.palette-Pink-600.text {
  color: #d81b60;
}

.palette-Pink-700.bg {
  background-color: #c2185b;
}

.palette-Pink-700.text {
  color: #c2185b;
}

.palette-Pink-800.bg {
  background-color: #ad1457;
}

.palette-Pink-800.text {
  color: #ad1457;
}

.palette-Pink-900.bg {
  background-color: #880e4f;
}

.palette-Pink-900.text {
  color: #880e4f;
}

.palette-Pink-A100.bg {
  background-color: #ff80ab;
}

.palette-Pink-A100.text {
  color: #ff80ab;
}

.palette-Pink-A200.bg {
  background-color: #ff4081;
}

.palette-Pink-A200.text {
  color: #ff4081;
}

.palette-Pink-A400.bg {
  background-color: #f50057;
}

.palette-Pink-A400.text {
  color: #f50057;
}

.palette-Pink-A700.bg {
  background-color: #c51162;
}

.palette-Pink-A700.text {
  color: #c51162;
}

.palette-Purple.bg {
  background-color: #9c27b0;
}

.palette-Purple.text {
  color: #9c27b0;
}

.palette-Purple-50.bg {
  background-color: #f3e5f5;
}

.palette-Purple-50.text {
  color: #f3e5f5;
}

.palette-Purple-100.bg {
  background-color: #e1bee7;
}

.palette-Purple-100.text {
  color: #e1bee7;
}

.palette-Purple-200.bg {
  background-color: #ce93d8;
}

.palette-Purple-200.text {
  color: #ce93d8;
}

.palette-Purple-300.bg {
  background-color: #ba68c8;
}

.palette-Purple-300.text {
  color: #ba68c8;
}

.palette-Purple-400.bg {
  background-color: #ab47bc;
}

.palette-Purple-400.text {
  color: #ab47bc;
}

.palette-Purple-500.bg {
  background-color: #9c27b0;
}

.palette-Purple-500.text {
  color: #9c27b0;
}

.palette-Purple-600.bg {
  background-color: #8e24aa;
}

.palette-Purple-600.text {
  color: #8e24aa;
}

.palette-Purple-700.bg {
  background-color: #7b1fa2;
}

.palette-Purple-700.text {
  color: #7b1fa2;
}

.palette-Purple-800.bg {
  background-color: #6a1b9a;
}

.palette-Purple-800.text {
  color: #6a1b9a;
}

.palette-Purple-900.bg {
  background-color: #4a148c;
}

.palette-Purple-900.text {
  color: #4a148c;
}

.palette-Purple-A100.bg {
  background-color: #ea80fc;
}

.palette-Purple-A100.text {
  color: #ea80fc;
}

.palette-Purple-A200.bg {
  background-color: #e040fb;
}

.palette-Purple-A200.text {
  color: #e040fb;
}

.palette-Purple-A400.bg {
  background-color: #d500f9;
}

.palette-Purple-A400.text {
  color: #d500f9;
}

.palette-Purple-A700.bg {
  background-color: #aa00ff;
}

.palette-Purple-A700.text {
  color: #aa00ff;
}

.palette-Deep-Purple.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple.text {
  color: #673ab7;
}

.palette-Deep-Purple-50.bg {
  background-color: #ede7f6;
}

.palette-Deep-Purple-50.text {
  color: #ede7f6;
}

.palette-Deep-Purple-100.bg {
  background-color: #d1c4e9;
}

.palette-Deep-Purple-100.text {
  color: #d1c4e9;
}

.palette-Deep-Purple-200.bg {
  background-color: #b39ddb;
}

.palette-Deep-Purple-200.text {
  color: #b39ddb;
}

.palette-Deep-Purple-300.bg {
  background-color: #9575cd;
}

.palette-Deep-Purple-300.text {
  color: #9575cd;
}

.palette-Deep-Purple-400.bg {
  background-color: #7e57c2;
}

.palette-Deep-Purple-400.text {
  color: #7e57c2;
}

.palette-Deep-Purple-500.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple-500.text {
  color: #673ab7;
}

.palette-Deep-Purple-600.bg {
  background-color: #5e35b1;
}

.palette-Deep-Purple-600.text {
  color: #5e35b1;
}

.palette-Deep-Purple-700.bg {
  background-color: #512da8;
}

.palette-Deep-Purple-700.text {
  color: #512da8;
}

.palette-Deep-Purple-800.bg {
  background-color: #4527a0;
}

.palette-Deep-Purple-800.text {
  color: #4527a0;
}

.palette-Deep-Purple-900.bg {
  background-color: #311b92;
}

.palette-Deep-Purple-900.text {
  color: #311b92;
}

.palette-Deep-Purple-A100.bg {
  background-color: #b388ff;
}

.palette-Deep-Purple-A100.text {
  color: #b388ff;
}

.palette-Deep-Purple-A200.bg {
  background-color: #7c4dff;
}

.palette-Deep-Purple-A200.text {
  color: #7c4dff;
}

.palette-Deep-Purple-A400.bg {
  background-color: #651fff;
}

.palette-Deep-Purple-A400.text {
  color: #651fff;
}

.palette-Deep-Purple-A700.bg {
  background-color: #6200ea;
}

.palette-Deep-Purple-A700.text {
  color: #6200ea;
}

.palette-Indigo.bg {
  background-color: #3f51b5;
}

.palette-Indigo.text {
  color: #3f51b5;
}

.palette-Indigo-50.bg {
  background-color: #e8eaf6;
}

.palette-Indigo-50.text {
  color: #e8eaf6;
}

.palette-Indigo-100.bg {
  background-color: #c5cae9;
}

.palette-Indigo-100.text {
  color: #c5cae9;
}

.palette-Indigo-200.bg {
  background-color: #9fa8da;
}

.palette-Indigo-200.text {
  color: #9fa8da;
}

.palette-Indigo-300.bg {
  background-color: #7986cb;
}

.palette-Indigo-300.text {
  color: #7986cb;
}

.palette-Indigo-400.bg {
  background-color: #5c6bc0;
}

.palette-Indigo-400.text {
  color: #5c6bc0;
}

.palette-Indigo-500.bg {
  background-color: #3f51b5;
}

.palette-Indigo-500.text {
  color: #3f51b5;
}

.palette-Indigo-600.bg {
  background-color: #3949ab;
}

.palette-Indigo-600.text {
  color: #3949ab;
}

.palette-Indigo-700.bg {
  background-color: #303f9f;
}

.palette-Indigo-700.text {
  color: #303f9f;
}

.palette-Indigo-800.bg {
  background-color: #283593;
}

.palette-Indigo-800.text {
  color: #283593;
}

.palette-Indigo-900.bg {
  background-color: #1a237e;
}

.palette-Indigo-900.text {
  color: #1a237e;
}

.palette-Indigo-A100.bg {
  background-color: #8c9eff;
}

.palette-Indigo-A100.text {
  color: #8c9eff;
}

.palette-Indigo-A200.bg {
  background-color: #536dfe;
}

.palette-Indigo-A200.text {
  color: #536dfe;
}

.palette-Indigo-A400.bg {
  background-color: #3d5afe;
}

.palette-Indigo-A400.text {
  color: #3d5afe;
}

.palette-Indigo-A700.bg {
  background-color: #304ffe;
}

.palette-Indigo-A700.text {
  color: #304ffe;
}

.palette-Blue.bg {
  background-color: #2196f3;
}

.palette-Blue.text {
  color: #2196f3;
}

.palette-Blue-50.bg {
  background-color: #e3f2fd;
}

.palette-Blue-50.text {
  color: #e3f2fd;
}

.palette-Blue-100.bg {
  background-color: #bbdefb;
}

.palette-Blue-100.text {
  color: #bbdefb;
}

.palette-Blue-200.bg {
  background-color: #90caf9;
}

.palette-Blue-200.text {
  color: #90caf9;
}

.palette-Blue-300.bg {
  background-color: #64b5f6;
}

.palette-Blue-300.text {
  color: #64b5f6;
}

.palette-Blue-400.bg {
  background-color: #42a5f5;
}

.palette-Blue-400.text {
  color: #42a5f5;
}

.palette-Blue-500.bg {
  background-color: #2196f3;
}

.palette-Blue-500.text {
  color: #2196f3;
}

.palette-Blue-600.bg {
  background-color: #1e88e5;
}

.palette-Blue-600.text {
  color: #1e88e5;
}

.palette-Blue-700.bg {
  background-color: #1976d2;
}

.palette-Blue-700.text {
  color: #1976d2;
}

.palette-Blue-800.bg {
  background-color: #1565c0;
}

.palette-Blue-800.text {
  color: #1565c0;
}

.palette-Blue-900.bg {
  background-color: #0d47a1;
}

.palette-Blue-900.text {
  color: #0d47a1;
}

.palette-Blue-A100.bg {
  background-color: #82b1ff;
}

.palette-Blue-A100.text {
  color: #82b1ff;
}

.palette-Blue-A200.bg {
  background-color: #448aff;
}

.palette-Blue-A200.text {
  color: #448aff;
}

.palette-Blue-A400.bg {
  background-color: #2979ff;
}

.palette-Blue-A400.text {
  color: #2979ff;
}

.palette-Blue-A700.bg {
  background-color: #2962ff;
}

.palette-Blue-A700.text {
  color: #2962ff;
}

.palette-Light-Blue.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue.text {
  color: #03a9f4;
}

.palette-Light-Blue-50.bg {
  background-color: #e1f5fe;
}

.palette-Light-Blue-50.text {
  color: #e1f5fe;
}

.palette-Light-Blue-100.bg {
  background-color: #b3e5fc;
}

.palette-Light-Blue-100.text {
  color: #b3e5fc;
}

.palette-Light-Blue-200.bg {
  background-color: #81d4fa;
}

.palette-Light-Blue-200.text {
  color: #81d4fa;
}

.palette-Light-Blue-300.bg {
  background-color: #4fc3f7;
}

.palette-Light-Blue-300.text {
  color: #4fc3f7;
}

.palette-Light-Blue-400.bg {
  background-color: #29b6f6;
}

.palette-Light-Blue-400.text {
  color: #29b6f6;
}

.palette-Light-Blue-500.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue-500.text {
  color: #03a9f4;
}

.palette-Light-Blue-600.bg {
  background-color: #039be5;
}

.palette-Light-Blue-600.text {
  color: #039be5;
}

.palette-Light-Blue-700.bg {
  background-color: #0288d1;
}

.palette-Light-Blue-700.text {
  color: #0288d1;
}

.palette-Light-Blue-800.bg {
  background-color: #0277bd;
}

.palette-Light-Blue-800.text {
  color: #0277bd;
}

.palette-Light-Blue-900.bg {
  background-color: #01579b;
}

.palette-Light-Blue-900.text {
  color: #01579b;
}

.palette-Light-Blue-A100.bg {
  background-color: #80d8ff;
}

.palette-Light-Blue-A100.text {
  color: #80d8ff;
}

.palette-Light-Blue-A200.bg {
  background-color: #40c4ff;
}

.palette-Light-Blue-A200.text {
  color: #40c4ff;
}

.palette-Light-Blue-A400.bg {
  background-color: #00b0ff;
}

.palette-Light-Blue-A400.text {
  color: #00b0ff;
}

.palette-Light-Blue-A700.bg {
  background-color: #0091ea;
}

.palette-Light-Blue-A700.text {
  color: #0091ea;
}

.palette-Cyan.bg {
  background-color: #00bcd4;
}

.palette-Cyan.text {
  color: #00bcd4;
}

.palette-Cyan-50.bg {
  background-color: #e0f7fa;
}

.palette-Cyan-50.text {
  color: #e0f7fa;
}

.palette-Cyan-100.bg {
  background-color: #b2ebf2;
}

.palette-Cyan-100.text {
  color: #b2ebf2;
}

.palette-Cyan-200.bg {
  background-color: #80deea;
}

.palette-Cyan-200.text {
  color: #80deea;
}

.palette-Cyan-300.bg {
  background-color: #4dd0e1;
}

.palette-Cyan-300.text {
  color: #4dd0e1;
}

.palette-Cyan-400.bg {
  background-color: #26c6da;
}

.palette-Cyan-400.text {
  color: #26c6da;
}

.palette-Cyan-500.bg {
  background-color: #00bcd4;
}

.palette-Cyan-500.text {
  color: #00bcd4;
}

.palette-Cyan-600.bg {
  background-color: #00acc1;
}

.palette-Cyan-600.text {
  color: #00acc1;
}

.palette-Cyan-700.bg {
  background-color: #0097a7;
}

.palette-Cyan-700.text {
  color: #0097a7;
}

.palette-Cyan-800.bg {
  background-color: #00838f;
}

.palette-Cyan-800.text {
  color: #00838f;
}

.palette-Cyan-900.bg {
  background-color: #006064;
}

.palette-Cyan-900.text {
  color: #006064;
}

.palette-Cyan-A100.bg {
  background-color: #84ffff;
}

.palette-Cyan-A100.text {
  color: #84ffff;
}

.palette-Cyan-A200.bg {
  background-color: #18ffff;
}

.palette-Cyan-A200.text {
  color: #18ffff;
}

.palette-Cyan-A400.bg {
  background-color: #00e5ff;
}

.palette-Cyan-A400.text {
  color: #00e5ff;
}

.palette-Cyan-A700.bg {
  background-color: #00b8d4;
}

.palette-Cyan-A700.text {
  color: #00b8d4;
}

.palette-Teal.bg {
  background-color: #4791cd;
}

.palette-Teal.text {
  color: #4791cd;
}

.palette-Teal-50.bg {
  background-color: #e0f2f1;
}

.palette-Teal-50.text {
  color: #e0f2f1;
}

.palette-Teal-100.bg {
  background-color: #b2dfdb;
}

.palette-Teal-100.text {
  color: #b2dfdb;
}

.palette-Teal-200.bg {
  background-color: #80cbc4;
}

.palette-Teal-200.text {
  color: #80cbc4;
}

.palette-Teal-300.bg {
  background-color: #4db6ac;
}

.palette-Teal-300.text {
  color: #4db6ac;
}

.palette-Teal-400.bg {
  background-color: #26a69a;
}

.palette-Teal-400.text {
  color: #26a69a;
}

.palette-Teal-500.bg {
  background-color: #4791cd;
}

.palette-Teal-500.text {
  color: #4791cd;
}

.palette-Teal-600.bg {
  background-color: #00897b;
}

.palette-Teal-600.text {
  color: #00897b;
}

.palette-Teal-700.bg {
  background-color: #00796b;
}

.palette-Teal-700.text {
  color: #00796b;
}

.palette-Teal-800.bg {
  background-color: #00695c;
}

.palette-Teal-800.text {
  color: #00695c;
}

.palette-Teal-900.bg {
  background-color: #004d40;
}

.palette-Teal-900.text {
  color: #004d40;
}

.palette-Teal-A100.bg {
  background-color: #a7ffeb;
}

.palette-Teal-A100.text {
  color: #a7ffeb;
}

.palette-Teal-A200.bg {
  background-color: #64ffda;
}

.palette-Teal-A200.text {
  color: #64ffda;
}

.palette-Teal-A400.bg {
  background-color: #1de9b6;
}

.palette-Teal-A400.text {
  color: #1de9b6;
}

.palette-Teal-A700.bg {
  background-color: #00bfa5;
}

.palette-Teal-A700.text {
  color: #00bfa5;
}

.palette-Green.bg {
  background-color: #4caf50;
}

.palette-Green.text {
  color: #4caf50;
}

.palette-Green-50.bg {
  background-color: #e8f5e9;
}

.palette-Green-50.text {
  color: #e8f5e9;
}

.palette-Green-100.bg {
  background-color: #c8e6c9;
}

.palette-Green-100.text {
  color: #c8e6c9;
}

.palette-Green-200.bg {
  background-color: #a5d6a7;
}

.palette-Green-200.text {
  color: #a5d6a7;
}

.palette-Green-300.bg {
  background-color: #81c784;
}

.palette-Green-300.text {
  color: #81c784;
}

.palette-Green-400.bg {
  background-color: #66bb6a;
}

.palette-Green-400.text {
  color: #66bb6a;
}

.palette-Green-500.bg {
  background-color: #4caf50;
}

.palette-Green-500.text {
  color: #4caf50;
}

.palette-Green-600.bg {
  background-color: #43a047;
}

.palette-Green-600.text {
  color: #43a047;
}

.palette-Green-700.bg {
  background-color: #388e3c;
}

.palette-Green-700.text {
  color: #388e3c;
}

.palette-Green-800.bg {
  background-color: #2e7d32;
}

.palette-Green-800.text {
  color: #2e7d32;
}

.palette-Green-900.bg {
  background-color: #1b5e20;
}

.palette-Green-900.text {
  color: #1b5e20;
}

.palette-Green-A100.bg {
  background-color: #b9f6ca;
}

.palette-Green-A100.text {
  color: #b9f6ca;
}

.palette-Green-A200.bg {
  background-color: #69f0ae;
}

.palette-Green-A200.text {
  color: #69f0ae;
}

.palette-Green-A400.bg {
  background-color: #00e676;
}

.palette-Green-A400.text {
  color: #00e676;
}

.palette-Green-A700.bg {
  background-color: #00c853;
}

.palette-Green-A700.text {
  color: #00c853;
}

.palette-Light-Green.bg {
  background-color: #8bc34a;
}

.palette-Light-Green.text {
  color: #8bc34a;
}

.palette-Light-Green-50.bg {
  background-color: #f1f8e9;
}

.palette-Light-Green-50.text {
  color: #f1f8e9;
}

.palette-Light-Green-100.bg {
  background-color: #dcedc8;
}

.palette-Light-Green-100.text {
  color: #dcedc8;
}

.palette-Light-Green-200.bg {
  background-color: #c5e1a5;
}

.palette-Light-Green-200.text {
  color: #c5e1a5;
}

.palette-Light-Green-300.bg {
  background-color: #aed581;
}

.palette-Light-Green-300.text {
  color: #aed581;
}

.palette-Light-Green-400.bg {
  background-color: #9ccc65;
}

.palette-Light-Green-400.text {
  color: #9ccc65;
}

.palette-Light-Green-500.bg {
  background-color: #8bc34a;
}

.palette-Light-Green-500.text {
  color: #8bc34a;
}

.palette-Light-Green-600.bg {
  background-color: #7cb342;
}

.palette-Light-Green-600.text {
  color: #7cb342;
}

.palette-Light-Green-700.bg {
  background-color: #689f38;
}

.palette-Light-Green-700.text {
  color: #689f38;
}

.palette-Light-Green-800.bg {
  background-color: #558b2f;
}

.palette-Light-Green-800.text {
  color: #558b2f;
}

.palette-Light-Green-900.bg {
  background-color: #33691e;
}

.palette-Light-Green-900.text {
  color: #33691e;
}

.palette-Light-Green-A100.bg {
  background-color: #ccff90;
}

.palette-Light-Green-A100.text {
  color: #ccff90;
}

.palette-Light-Green-A200.bg {
  background-color: #b2ff59;
}

.palette-Light-Green-A200.text {
  color: #b2ff59;
}

.palette-Light-Green-A400.bg {
  background-color: #76ff03;
}

.palette-Light-Green-A400.text {
  color: #76ff03;
}

.palette-Light-Green-A700.bg {
  background-color: #64dd17;
}

.palette-Light-Green-A700.text {
  color: #64dd17;
}

.palette-Lime.bg {
  background-color: #cddc39;
}

.palette-Lime.text {
  color: #cddc39;
}

.palette-Lime-50.bg {
  background-color: #f9fbe7;
}

.palette-Lime-50.text {
  color: #f9fbe7;
}

.palette-Lime-100.bg {
  background-color: #f0f4c3;
}

.palette-Lime-100.text {
  color: #f0f4c3;
}

.palette-Lime-200.bg {
  background-color: #e6ee9c;
}

.palette-Lime-200.text {
  color: #e6ee9c;
}

.palette-Lime-300.bg {
  background-color: #dce775;
}

.palette-Lime-300.text {
  color: #dce775;
}

.palette-Lime-400.bg {
  background-color: #d4e157;
}

.palette-Lime-400.text {
  color: #d4e157;
}

.palette-Lime-500.bg {
  background-color: #cddc39;
}

.palette-Lime-500.text {
  color: #cddc39;
}

.palette-Lime-600.bg {
  background-color: #c0ca33;
}

.palette-Lime-600.text {
  color: #c0ca33;
}

.palette-Lime-700.bg {
  background-color: #afb42b;
}

.palette-Lime-700.text {
  color: #afb42b;
}

.palette-Lime-800.bg {
  background-color: #9e9d24;
}

.palette-Lime-800.text {
  color: #9e9d24;
}

.palette-Lime-900.bg {
  background-color: #827717;
}

.palette-Lime-900.text {
  color: #827717;
}

.palette-Lime-A100.bg {
  background-color: #f4ff81;
}

.palette-Lime-A100.text {
  color: #f4ff81;
}

.palette-Lime-A200.bg {
  background-color: #eeff41;
}

.palette-Lime-A200.text {
  color: #eeff41;
}

.palette-Lime-A400.bg {
  background-color: #c6ff00;
}

.palette-Lime-A400.text {
  color: #c6ff00;
}

.palette-Lime-A700.bg {
  background-color: #aeea00;
}

.palette-Lime-A700.text {
  color: #aeea00;
}

.palette-Yellow.bg {
  background-color: #ffeb3b;
}

.palette-Yellow.text {
  color: #ffeb3b;
}

.palette-Yellow-50.bg {
  background-color: #fffde7;
}

.palette-Yellow-50.text {
  color: #fffde7;
}

.palette-Yellow-100.bg {
  background-color: #fff9c4;
}

.palette-Yellow-100.text {
  color: #fff9c4;
}

.palette-Yellow-200.bg {
  background-color: #fff59d;
}

.palette-Yellow-200.text {
  color: #fff59d;
}

.palette-Yellow-300.bg {
  background-color: #fff176;
}

.palette-Yellow-300.text {
  color: #fff176;
}

.palette-Yellow-400.bg {
  background-color: #ffee58;
}

.palette-Yellow-400.text {
  color: #ffee58;
}

.palette-Yellow-500.bg {
  background-color: #ffeb3b;
}

.palette-Yellow-500.text {
  color: #ffeb3b;
}

.palette-Yellow-600.bg {
  background-color: #fdd835;
}

.palette-Yellow-600.text {
  color: #fdd835;
}

.palette-Yellow-700.bg {
  background-color: #fbc02d;
}

.palette-Yellow-700.text {
  color: #fbc02d;
}

.palette-Yellow-800.bg {
  background-color: #f9a825;
}

.palette-Yellow-800.text {
  color: #f9a825;
}

.palette-Yellow-900.bg {
  background-color: #f57f17;
}

.palette-Yellow-900.text {
  color: #f57f17;
}

.palette-Yellow-A100.bg {
  background-color: #ffff8d;
}

.palette-Yellow-A100.text {
  color: #ffff8d;
}

.palette-Yellow-A200.bg {
  background-color: #ffff00;
}

.palette-Yellow-A200.text {
  color: #ffff00;
}

.palette-Yellow-A400.bg {
  background-color: #ffea00;
}

.palette-Yellow-A400.text {
  color: #ffea00;
}

.palette-Yellow-A700.bg {
  background-color: #ffd600;
}

.palette-Yellow-A700.text {
  color: #ffd600;
}

.palette-Amber.bg {
  background-color: #ffc107;
}

.palette-Amber.text {
  color: #ffc107;
}

.palette-Amber-50.bg {
  background-color: #fff8e1;
}

.palette-Amber-50.text {
  color: #fff8e1;
}

.palette-Amber-100.bg {
  background-color: #ffecb3;
}

.palette-Amber-100.text {
  color: #ffecb3;
}

.palette-Amber-200.bg {
  background-color: #ffe082;
}

.palette-Amber-200.text {
  color: #ffe082;
}

.palette-Amber-300.bg {
  background-color: #ffd54f;
}

.palette-Amber-300.text {
  color: #ffd54f;
}

.palette-Amber-400.bg {
  background-color: #ffca28;
}

.palette-Amber-400.text {
  color: #ffca28;
}

.palette-Amber-500.bg {
  background-color: #ffc107;
}

.palette-Amber-500.text {
  color: #ffc107;
}

.palette-Amber-600.bg {
  background-color: #ffb300;
}

.palette-Amber-600.text {
  color: #ffb300;
}

.palette-Amber-700.bg {
  background-color: #ffa000;
}

.palette-Amber-700.text {
  color: #ffa000;
}

.palette-Amber-800.bg {
  background-color: #ff8f00;
}

.palette-Amber-800.text {
  color: #ff8f00;
}

.palette-Amber-900.bg {
  background-color: #ff6f00;
}

.palette-Amber-900.text {
  color: #ff6f00;
}

.palette-Amber-A100.bg {
  background-color: #ffe57f;
}

.palette-Amber-A100.text {
  color: #ffe57f;
}

.palette-Amber-A200.bg {
  background-color: #ffd740;
}

.palette-Amber-A200.text {
  color: #ffd740;
}

.palette-Amber-A400.bg {
  background-color: #ffc400;
}

.palette-Amber-A400.text {
  color: #ffc400;
}

.palette-Amber-A700.bg {
  background-color: #ffab00;
}

.palette-Amber-A700.text {
  color: #ffab00;
}

.palette-Orange.bg {
  background-color: #ff9800;
}

.palette-Orange.text {
  color: #ff9800;
}

.palette-Orange-50.bg {
  background-color: #fff3e0;
}

.palette-Orange-50.text {
  color: #fff3e0;
}

.palette-Orange-100.bg {
  background-color: #ffe0b2;
}

.palette-Orange-100.text {
  color: #ffe0b2;
}

.palette-Orange-200.bg {
  background-color: #ffcc80;
}

.palette-Orange-200.text {
  color: #ffcc80;
}

.palette-Orange-300.bg {
  background-color: #ffb74d;
}

.palette-Orange-300.text {
  color: #ffb74d;
}

.palette-Orange-400.bg {
  background-color: #ffa726;
}

.palette-Orange-400.text {
  color: #ffa726;
}

.palette-Orange-500.bg {
  background-color: #ff9800;
}

.palette-Orange-500.text {
  color: #ff9800;
}

.palette-Orange-600.bg {
  background-color: #fb8c00;
}

.palette-Orange-600.text {
  color: #fb8c00;
}

.palette-Orange-700.bg {
  background-color: #f57c00;
}

.palette-Orange-700.text {
  color: #f57c00;
}

.palette-Orange-800.bg {
  background-color: #ef6c00;
}

.palette-Orange-800.text {
  color: #ef6c00;
}

.palette-Orange-900.bg {
  background-color: #e65100;
}

.palette-Orange-900.text {
  color: #e65100;
}

.palette-Orange-A100.bg {
  background-color: #ffd180;
}

.palette-Orange-A100.text {
  color: #ffd180;
}

.palette-Orange-A200.bg {
  background-color: #ffab40;
}

.palette-Orange-A200.text {
  color: #ffab40;
}

.palette-Orange-A400.bg {
  background-color: #ff9100;
}

.palette-Orange-A400.text {
  color: #ff9100;
}

.palette-Orange-A700.bg {
  background-color: #ff6d00;
}

.palette-Orange-A700.text {
  color: #ff6d00;
}

.palette-Deep-Orange.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange.text {
  color: #ff5722;
}

.palette-Deep-Orange-50.bg {
  background-color: #fbe9e7;
}

.palette-Deep-Orange-50.text {
  color: #fbe9e7;
}

.palette-Deep-Orange-100.bg {
  background-color: #ffccbc;
}

.palette-Deep-Orange-100.text {
  color: #ffccbc;
}

.palette-Deep-Orange-200.bg {
  background-color: #ffab91;
}

.palette-Deep-Orange-200.text {
  color: #ffab91;
}

.palette-Deep-Orange-300.bg {
  background-color: #ff8a65;
}

.palette-Deep-Orange-300.text {
  color: #ff8a65;
}

.palette-Deep-Orange-400.bg {
  background-color: #ff7043;
}

.palette-Deep-Orange-400.text {
  color: #ff7043;
}

.palette-Deep-Orange-500.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange-500.text {
  color: #ff5722;
}

.palette-Deep-Orange-600.bg {
  background-color: #f4511e;
}

.palette-Deep-Orange-600.text {
  color: #f4511e;
}

.palette-Deep-Orange-700.bg {
  background-color: #e64a19;
}

.palette-Deep-Orange-700.text {
  color: #e64a19;
}

.palette-Deep-Orange-800.bg {
  background-color: #d84315;
}

.palette-Deep-Orange-800.text {
  color: #d84315;
}

.palette-Deep-Orange-900.bg {
  background-color: #bf360c;
}

.palette-Deep-Orange-900.text {
  color: #bf360c;
}

.palette-Deep-Orange-A100.bg {
  background-color: #ff9e80;
}

.palette-Deep-Orange-A100.text {
  color: #ff9e80;
}

.palette-Deep-Orange-A200.bg {
  background-color: #ff6e40;
}

.palette-Deep-Orange-A200.text {
  color: #ff6e40;
}

.palette-Deep-Orange-A400.bg {
  background-color: #ff3d00;
}

.palette-Deep-Orange-A400.text {
  color: #ff3d00;
}

.palette-Deep-Orange-A700.bg {
  background-color: #dd2c00;
}

.palette-Deep-Orange-A700.text {
  color: #dd2c00;
}

.palette-Brown.bg {
  background-color: #795548;
}

.palette-Brown.text {
  color: #795548;
}

.palette-Brown-50.bg {
  background-color: #efebe9;
}

.palette-Brown-50.text {
  color: #efebe9;
}

.palette-Brown-100.bg {
  background-color: #d7ccc8;
}

.palette-Brown-100.text {
  color: #d7ccc8;
}

.palette-Brown-200.bg {
  background-color: #bcaaa4;
}

.palette-Brown-200.text {
  color: #bcaaa4;
}

.palette-Brown-300.bg {
  background-color: #a1887f;
}

.palette-Brown-300.text {
  color: #a1887f;
}

.palette-Brown-400.bg {
  background-color: #8d6e63;
}

.palette-Brown-400.text {
  color: #8d6e63;
}

.palette-Brown-500.bg {
  background-color: #795548;
}

.palette-Brown-500.text {
  color: #795548;
}

.palette-Brown-600.bg {
  background-color: #6d4c41;
}

.palette-Brown-600.text {
  color: #6d4c41;
}

.palette-Brown-700.bg {
  background-color: #5d4037;
}

.palette-Brown-700.text {
  color: #5d4037;
}

.palette-Brown-800.bg {
  background-color: #4e342e;
}

.palette-Brown-800.text {
  color: #4e342e;
}

.palette-Brown-900.bg {
  background-color: #3e2723;
}

.palette-Brown-900.text {
  color: #3e2723;
}

.palette-Grey.bg {
  background-color: #9e9e9e;
}

.palette-Grey.text {
  color: #9e9e9e;
}

.palette-Grey-50.bg {
  background-color: #fafafa;
}

.palette-Grey-50.text {
  color: #fafafa;
}

.palette-Grey-100.bg {
  background-color: #f5f5f5;
}

.palette-Grey-100.text {
  color: #f5f5f5;
}

.palette-Grey-200.bg {
  background-color: #eeeeee;
}

.palette-Grey-200.text {
  color: #eeeeee;
}

.palette-Grey-300.bg {
  background-color: #e0e0e0;
}

.palette-Grey-300.text {
  color: #e0e0e0;
}

.palette-Grey-400.bg {
  background-color: #bdbdbd;
}

.palette-Grey-400.text {
  color: #bdbdbd;
}

.palette-Grey-500.bg {
  background-color: #9e9e9e;
}

.palette-Grey-500.text {
  color: #9e9e9e;
}

.palette-Grey-600.bg {
  background-color: #757575;
}

.palette-Grey-600.text {
  color: #757575;
}

.palette-Grey-700.bg {
  background-color: #616161;
}

.palette-Grey-700.text {
  color: #616161;
}

.palette-Grey-800.bg {
  background-color: #424242;
}

.palette-Grey-800.text {
  color: #424242;
}

.palette-Grey-900.bg {
  background-color: #212121;
}

.palette-Grey-900.text {
  color: #212121;
}

.palette-Blue-Grey.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey.text {
  color: #607d8b;
}

.palette-Blue-Grey-50.bg {
  background-color: #eceff1;
}

.palette-Blue-Grey-50.text {
  color: #eceff1;
}

.palette-Blue-Grey-100.bg {
  background-color: #cfd8dc;
}

.palette-Blue-Grey-100.text {
  color: #cfd8dc;
}

.palette-Blue-Grey-200.bg {
  background-color: #b0bec5;
}

.palette-Blue-Grey-200.text {
  color: #b0bec5;
}

.palette-Blue-Grey-300.bg {
  background-color: #90a4ae;
}

.palette-Blue-Grey-300.text {
  color: #90a4ae;
}

.palette-Blue-Grey-400.bg {
  background-color: #78909c;
}

.palette-Blue-Grey-400.text {
  color: #78909c;
}

.palette-Blue-Grey-500.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey-500.text {
  color: #607d8b;
}

.palette-Blue-Grey-600.bg {
  background-color: #546e7a;
}

.palette-Blue-Grey-600.text {
  color: #546e7a;
}

.palette-Blue-Grey-700.bg {
  background-color: #455a64;
}

.palette-Blue-Grey-700.text {
  color: #455a64;
}

.palette-Blue-Grey-800.bg {
  background-color: #37474f;
}

.palette-Blue-Grey-800.text {
  color: #37474f;
}

.palette-Blue-Grey-900.bg {
  background-color: #263238;
}

.palette-Blue-Grey-900.text {
  color: #263238;
}

.palette-Black.bg {
  background-color: #000000;
}

.palette-Black.text {
  color: #000000;
}

.palette-Black-500.bg {
  background-color: #000000;
}

.palette-Black-500.text {
  color: #000000;
}

.palette-Black-Text.bg {
  background-color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Text.text {
  color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Secondary-Text.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Secondary-Text.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Disabled.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Disabled.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Dividers.bg {
  background-color: rgba(0, 0, 0, 0.12);
}

.palette-Black-Dividers.text {
  color: rgba(0, 0, 0, 0.12);
}

.palette-White.bg {
  background-color: #ffffff;
}

.palette-White.text {
  color: #ffffff;
}

.palette-White-500.bg {
  background-color: #ffffff;
}

.palette-White-500.text {
  color: #ffffff;
}

.palette-White-Text.bg {
  background-color: #ffffff;
}

.palette-White-Text.text {
  color: #ffffff;
}

.palette-White-Secondary-Text.bg {
  background-color: rgba(255, 255, 255, 0.7);
}

.palette-White-Secondary-Text.text {
  color: rgba(255, 255, 255, 0.7);
}

.palette-White-Icons.bg {
  background-color: #ffffff;
}

.palette-White-Icons.text {
  color: #ffffff;
}

.palette-White-Disabled.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Disabled.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Dividers.bg {
  background-color: rgba(255, 255, 255, 0.12);
}

.palette-White-Dividers.text {
  color: rgba(255, 255, 255, 0.12);
}

.palette-system-primary.bg {
  background-color: #409ace;
}

.palette-system-primary.text {
  color: #409ace;
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
  .top-page-dropdown {
    top: 15px !important;
  }

  .al-content {
    padding: 10px 10px 10px 10px !important;
  }

  .component-result {
    margin-top: 0 !important;
  }

  .al-sidebar,
.user-Profile,
.brand-icon {
    width: 300px !important;
  }
  .al-sidebar #user-image,
.user-Profile #user-image,
.brand-icon #user-image {
    padding: 15px 0px 15px 25px !important;
  }
  .al-sidebar #user-image img,
.user-Profile #user-image img,
.brand-icon #user-image img {
    width: 70px !important;
  }

  .page-top,
.brand-icon {
    height: 65px !important;
  }

  .al-sidebar {
    top: 65px !important;
  }

  .multiLingual button {
    padding: 22px 15px 22px 23px !important;
  }

  .al-main {
    padding: 25px 20px 10px 20px;
    margin-right: auto;
    margin-left: 300px !important;
  }

  .menu-collapsed #patient-picture {
    left: 45px;
  }
  .menu-collapsed .al-sidebar {
    width: 45px !important;
  }
  .menu-collapsed .al-main {
    margin-right: auto !important;
    margin-left: 45px !important;
  }
  .menu-collapsed .business-card {
    width: 270px !important;
  }

  #sidebar-icon {
    top: 17px !important;
    left: 286px !important;
  }
  #sidebar-icon span.hamburger {
    top: 9px !important;
    left: 286px !important;
  }
  #sidebar-icon span.hamburger span:before,
#sidebar-icon span.hamburger span:after {
    width: 18px !important;
  }
  #sidebar-icon span.line {
    width: 18px !important;
  }
  #sidebar-icon img {
    width: 30px !important;
  }

  #search-item {
    top: 20px !important;
    left: 330px !important;
  }

  .al-sidebar-list-item {
    padding: 15px 0 !important;
  }

  .header-item {
    top: 15px !important;
  }
  .business-card {
    width: 285px !important;
  }
  .business-card .title {
    padding: 20px 0 0 0 !important;
  }
  .rtl .al-main {
    margin-right: 300px !important;
  }
  .rtl .menu-collapsed .al-main {
    margin-left: auto !important;
    margin-right: 45px !important;
  }
  .rtl #user-image {
    padding: 15px 25px 15px 0 !important;
  }
  .rtl #sidebar-icon {
    top: 17px !important;
    left: auto !important;
    right: 285px !important;
  }
  .rtl #sidebar-icon span.hamburger {
    top: 9px !important;
    right: 286px !important;
    left: auto;
  }
  .rtl #sidebar-icon span.hamburger span:before,
.rtl #sidebar-icon span.hamburger span:after {
    width: 18px !important;
  }
  .rtl #search-item {
    left: auto !important;
    right: 330px !important;
  }
  .rtl #sidebar-icon .hamburger {
    left: auto !important;
    right: 331px;
  }
  .rtl .business-card .title {
    padding: 20px 25px 0 0 !important;
  }
}
/*$body-bg: #F0F3F4; // default bg color*/
/* font-size */
.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* font-size */
/* font-weight */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* font-weight */
/* text-decoration */
.decoration-dashed {
  text-decoration: dashed !important;
}

.decoration-dotted {
  text-decoration: dotted !important;
}

.decoration-double {
  text-decoration: double !important;
}

.decoration-line-through {
  text-decoration: line-through !important;
}

.decoration-overline {
  text-decoration: overline !important;
}

.decoration-solid {
  text-decoration: solid !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.decoration-wavy {
  text-decoration: wavy !important;
}

.decoration-none {
  text-decoration: none !important;
}

/* text-decoration */
/* font-family */
.ff-opensans {
  font-family: "Open Sans" !important;
}

.ff-segoeui {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.ff-ubunto {
  font-family: "ubunto" !important;
}

.ff-cormorantunicase {
  font-family: "Cormorant Unicase" !important;
}

/* font-family */
/* element style */
.no-border {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-none {
  display: none !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

/* element style */
/* padding */
.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.plr-5 {
  padding: 0 5px !important;
}

.plr-10 {
  padding: 0 10px !important;
}

.plr-15 {
  padding: 0 15px !important;
}

.plr-20 {
  padding: 0 20px !important;
}

.plr-25 {
  padding: 0 25px !important;
}

.plr-30 {
  padding: 0 30px !important;
}

.plr-35 {
  padding: 0 35px !important;
}

.plr-40 {
  padding: 0 40px !important;
}

.plr-45 {
  padding: 0 45px !important;
}

.plr-50 {
  padding: 0 50px !important;
}

.plr-55 {
  padding: 0 55px !important;
}

.plr-60 {
  padding: 0 60px !important;
}

.plr-65 {
  padding: 0 65px !important;
}

.plr-70 {
  padding: 0 70px !important;
}

.plr-75 {
  padding: 0 75px !important;
}

.plr-80 {
  padding: 0 80px !important;
}

.plr-85 {
  padding: 0 85px !important;
}

.plr-90 {
  padding: 0 90px !important;
}

.plr-95 {
  padding: 0 95px !important;
}

.plr-100 {
  padding: 0 100px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

.ptb-55 {
  padding: 55px 0 !important;
}

.ptb-60 {
  padding: 60px 0 !important;
}

.ptb-65 {
  padding: 65px 0 !important;
}

.ptb-70 {
  padding: 70px 0 !important;
}

.ptb-75 {
  padding: 75px 0 !important;
}

.ptb-80 {
  padding: 80px 0 !important;
}

.ptb-85 {
  padding: 85px 0 !important;
}

.ptb-90 {
  padding: 90px 0 !important;
}

.ptb-95 {
  padding: 95px 0 !important;
}

.ptb-100 {
  padding: 100px 0 0 !important;
}

.padding-5 {
  padding: 5px !important;
}
.padding-5.padding-top {
  padding: 5px 0 0 0 !important;
}
.padding-5.padding-left {
  padding: 0 0 0 5px !important;
}
.padding-5.padding-right {
  padding: 0 5px 0 0 !important;
}
.padding-5.padding-bottom {
  padding: 0 0 5px 0 !important;
}

.padding-10 {
  padding: 10px !important;
}
.padding-10.padding-top {
  padding: 10px 0 0 0 !important;
}
.padding-10.padding-left {
  padding: 0 0 0 10px !important;
}
.padding-10.padding-right {
  padding: 0 10px 0 0 !important;
}
.padding-10.padding-bottom {
  padding: 0 0 10px 0 !important;
}

.padding-15 {
  padding: 15px !important;
}
.padding-15.padding-top {
  padding: 15px 0 0 0 !important;
}
.padding-15.padding-left {
  padding: 0 0 0 15px !important;
}
.padding-15.padding-right {
  padding: 0 15px 0 0 !important;
}
.padding-15.padding-bottom {
  padding: 0 0 15px 0 !important;
}

.padding-20 {
  padding: 20px !important;
}
.padding-20.padding-top {
  padding: 20px 0 0 0 !important;
}
.padding-20.padding-left {
  padding: 0 0 0 20px !important;
}
.padding-20.padding-right {
  padding: 0 20px 0 0 !important;
}
.padding-20.padding-bottom {
  padding: 0 0 20px 0 !important;
}

.padding-25 {
  padding: 25px !important;
}
.padding-25.padding-top {
  padding: 25px 0 0 0 !important;
}
.padding-25.padding-left {
  padding: 0 0 0 25px !important;
}
.padding-25.padding-right {
  padding: 0 25px 0 0 !important;
}
.padding-25.padding-bottom {
  padding: 0 0 25px 0 !important;
}

.padding-30 {
  padding: 30px !important;
}
.padding-30.padding-top {
  padding: 30px 0 0 0 !important;
}
.padding-30.padding-left {
  padding: 0 0 0 30px !important;
}
.padding-30.padding-right {
  padding: 0 30px 0 0 !important;
}
.padding-30.padding-bottom {
  padding: 0 0 30px 0 !important;
}

.padding-35 {
  padding: 35px !important;
}
.padding-35.padding-top {
  padding: 35px 0 0 0 !important;
}
.padding-35.padding-left {
  padding: 0 0 0 35px !important;
}
.padding-35.padding-right {
  padding: 0 35px 0 0 !important;
}
.padding-35.padding-bottom {
  padding: 0 0 35px 0 !important;
}

.padding-40 {
  padding: 40px !important;
}
.padding-40.padding-top {
  padding: 40px 0 0 0 !important;
}
.padding-40.padding-left {
  padding: 0 0 0 40px !important;
}
.padding-40.padding-right {
  padding: 0 40px 0 0 !important;
}
.padding-40.padding-bottom {
  padding: 0 0 40px 0 !important;
}

.padding-45 {
  padding: 45px !important;
}
.padding-45.padding-top {
  padding: 45px 0 0 0 !important;
}
.padding-45.padding-left {
  padding: 0 0 0 45px !important;
}
.padding-45.padding-right {
  padding: 0 45px 0 0 !important;
}
.padding-45.padding-bottom {
  padding: 0 0 45px 0 !important;
}

.padding-50 {
  padding: 50px !important;
}
.padding-50.padding-top {
  padding: 50px 0 0 0 !important;
}
.padding-50.padding-left {
  padding: 0 0 0 50px !important;
}
.padding-50.padding-right {
  padding: 0 50px 0 0 !important;
}
.padding-50.padding-bottom {
  padding: 0 0 50px 0 !important;
}

.padding-55 {
  padding: 55px !important;
}
.padding-55.padding-top {
  padding: 55px 0 0 0 !important;
}
.padding-55.padding-left {
  padding: 0 0 0 55px !important;
}
.padding-55.padding-right {
  padding: 0 55px 0 0 !important;
}
.padding-55.padding-bottom {
  padding: 0 0 55px 0 !important;
}

.padding-60 {
  padding: 60px !important;
}
.padding-60.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-60.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-60.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-60.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-65 {
  padding: 65px !important;
}
.padding-65.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-65.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-65.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-65.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-70 {
  padding: 70px !important;
}
.padding-70.padding-top {
  padding: 70px 0 0 0 !important;
}
.padding-70.padding-left {
  padding: 0 0 0 70px !important;
}
.padding-70.padding-right {
  padding: 0 70px 0 0 !important;
}
.padding-70.padding-bottom {
  padding: 0 0 70px 0 !important;
}

.padding-75 {
  padding: 75px !important;
}
.padding-75.padding-top {
  padding: 75px 0 0 0 !important;
}
.padding-75.padding-left {
  padding: 0 0 0 75px !important;
}
.padding-75.padding-right {
  padding: 0 75px 0 0 !important;
}
.padding-75.padding-bottom {
  padding: 0 0 75px 0 !important;
}

.padding-80 {
  padding: 80px !important;
}
.padding-80.padding-top {
  padding: 80px 0 0 0 !important;
}
.padding-80.padding-left {
  padding: 0 0 0 80px !important;
}
.padding-80.padding-right {
  padding: 0 80px 0 0 !important;
}
.padding-80.padding-bottom {
  padding: 0 0 80px 0 !important;
}

.padding-85 {
  padding: 85px !important;
}
.padding-85.padding-top {
  padding: 85px 0 0 0 !important;
}
.padding-85.padding-left {
  padding: 0 0 0 85px !important;
}
.padding-85.padding-right {
  padding: 0 85px 0 0 !important;
}
.padding-85.padding-bottom {
  padding: 0 0 85px 0 !important;
}

.padding-90 {
  padding: 90px !important;
}
.padding-90.padding-top {
  padding: 90px 0 0 0 !important;
}
.padding-90.padding-left {
  padding: 0 0 0 90px !important;
}
.padding-90.padding-right {
  padding: 0 90px 0 0 !important;
}
.padding-90.padding-bottom {
  padding: 0 0 90px 0 !important;
}

.padding-95 {
  padding: 95px !important;
}
.padding-95.padding-top {
  padding: 95px 0 0 0 !important;
}
.padding-95.padding-left {
  padding: 0 0 0 95px !important;
}
.padding-95.padding-right {
  padding: 0 95px 0 0 !important;
}
.padding-95.padding-bottom {
  padding: 0 0 95px 0 !important;
}

.padding-100 {
  padding: 100px !important;
}
.padding-100.padding-top {
  padding: 100px 0 0 0 !important;
}
.padding-100.padding-left {
  padding: 0 0 0 100px !important;
}
.padding-100.padding-right {
  padding: 0 100px 0 0 !important;
}
.padding-100.padding-bottom {
  padding: 0 0 100px 0 !important;
}

/* padding */
/* margin */
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mlr-5 {
  margin: 0 5px !important;
}

.mlr-10 {
  margin: 0 10px !important;
}

.mlr-15 {
  margin: 0 15px !important;
}

.mlr-20 {
  margin: 0 20px !important;
}

.mlr-25 {
  margin: 0 25px !important;
}

.mlr-30 {
  margin: 0 30px !important;
}

.mlr-35 {
  margin: 0 35px !important;
}

.mlr-40 {
  margin: 0 40px !important;
}

.mlr-45 {
  margin: 0 45px !important;
}

.mlr-50 {
  margin: 0 50px !important;
}

.mlr-55 {
  margin: 0 55px !important;
}

.mlr-60 {
  margin: 0 60px !important;
}

.mlr-65 {
  margin: 0 65px !important;
}

.mlr-70 {
  margin: 0 70px !important;
}

.mlr-75 {
  margin: 0 75px !important;
}

.mlr-80 {
  margin: 0 80px !important;
}

.mlr-85 {
  margin: 0 85px !important;
}

.mlr-90 {
  margin: 0 90px !important;
}

.mlr-95 {
  margin: 0 95px !important;
}

.mlr-100 {
  margin: 0 100px !important;
}

.mtb-5 {
  margin: 5px 0 !important;
}

.mtb-10 {
  margin: 10px 0 !important;
}

.mtb-15 {
  margin: 15px 0 !important;
}

.mtb-20 {
  margin: 20px 0 !important;
}

.mtb-25 {
  margin: 25px 0 !important;
}

.mtb-30 {
  margin: 30px 0 !important;
}

.mtb-35 {
  margin: 35px 0 !important;
}

.mtb-40 {
  margin: 40px 0 !important;
}

.mtb-45 {
  margin: 45px 0 !important;
}

.mtb-50 {
  margin: 50px 0 !important;
}

.mtb-55 {
  margin: 55px 0 !important;
}

.mtb-60 {
  margin: 60px 0 !important;
}

.mtb-65 {
  margin: 65px 0 !important;
}

.mtb-70 {
  margin: 70px 0 !important;
}

.mtb-75 {
  margin: 75px 0 !important;
}

.mtb-80 {
  margin: 80px 0 !important;
}

.mtb-85 {
  margin: 85px 0 !important;
}

.mtb-90 {
  margin: 90px 0 !important;
}

.mtb-95 {
  margin: 95px 0 !important;
}

.mtb-100 {
  margin: 100px 0 0 !important;
}

.margin-5 {
  margin: 5px !important;
}
.margin-5.margin-top {
  margin: 5px 0 0 0 !important;
}
.margin-5.margin-left {
  margin: 0 0 0 5px !important;
}
.margin-5.margin-right {
  margin: 0 5px 0 0 !important;
}
.margin-5.margin-bottom {
  margin: 0 0 5px 0 !important;
}

.margin-10 {
  margin: 10px !important;
}
.margin-10.margin-top {
  margin: 10px 0 0 0 !important;
}
.margin-10.margin-left {
  margin: 0 0 0 10px !important;
}
.margin-10.margin-right {
  margin: 0 10px 0 0 !important;
}
.margin-10.margin-bottom {
  margin: 0 0 10px 0 !important;
}

.margin-15 {
  margin: 15px !important;
}
.margin-15.margin-top {
  margin: 15px 0 0 0 !important;
}
.margin-15.margin-left {
  margin: 0 0 0 15px !important;
}
.margin-15.margin-right {
  margin: 0 15px 0 0 !important;
}
.margin-15.margin-bottom {
  margin: 0 0 15px 0 !important;
}

.margin-20 {
  margin: 20px !important;
}
.margin-20.margin-top {
  margin: 20px 0 0 0 !important;
}
.margin-20.margin-left {
  margin: 0 0 0 20px !important;
}
.margin-20.margin-right {
  margin: 0 20px 0 0 !important;
}
.margin-20.margin-bottom {
  margin: 0 0 20px 0 !important;
}

.margin-25 {
  margin: 25px !important;
}
.margin-25.margin-top {
  margin: 25px 0 0 0 !important;
}
.margin-25.margin-left {
  margin: 0 0 0 25px !important;
}
.margin-25.margin-right {
  margin: 0 25px 0 0 !important;
}
.margin-25.margin-bottom {
  margin: 0 0 25px 0 !important;
}

.margin-30 {
  margin: 30px !important;
}
.margin-30.margin-top {
  margin: 30px 0 0 0 !important;
}
.margin-30.margin-left {
  margin: 0 0 0 30px !important;
}
.margin-30.margin-right {
  margin: 0 30px 0 0 !important;
}
.margin-30.margin-bottom {
  margin: 0 0 30px 0 !important;
}

.margin-35 {
  margin: 35px !important;
}
.margin-35.margin-top {
  margin: 35px 0 0 0 !important;
}
.margin-35.margin-left {
  margin: 0 0 0 35px !important;
}
.margin-35.margin-right {
  margin: 0 35px 0 0 !important;
}
.margin-35.margin-bottom {
  margin: 0 0 35px 0 !important;
}

.margin-40 {
  margin: 40px !important;
}
.margin-40.margin-top {
  margin: 40px 0 0 0 !important;
}
.margin-40.margin-left {
  margin: 0 0 0 40px !important;
}
.margin-40.margin-right {
  margin: 0 40px 0 0 !important;
}
.margin-40.margin-bottom {
  margin: 0 0 40px 0 !important;
}

.margin-45 {
  margin: 45px !important;
}
.margin-45.margin-top {
  margin: 45px 0 0 0 !important;
}
.margin-45.margin-left {
  margin: 0 0 0 45px !important;
}
.margin-45.margin-right {
  margin: 0 45px 0 0 !important;
}
.margin-45.margin-bottom {
  margin: 0 0 45px 0 !important;
}

.margin-50 {
  margin: 50px !important;
}
.margin-50.margin-top {
  margin: 50px 0 0 0 !important;
}
.margin-50.margin-left {
  margin: 0 0 0 50px !important;
}
.margin-50.margin-right {
  margin: 0 50px 0 0 !important;
}
.margin-50.margin-bottom {
  margin: 0 0 50px 0 !important;
}

.margin-55 {
  margin: 55px !important;
}
.margin-55.margin-top {
  margin: 55px 0 0 0 !important;
}
.margin-55.margin-left {
  margin: 0 0 0 55px !important;
}
.margin-55.margin-right {
  margin: 0 55px 0 0 !important;
}
.margin-55.margin-bottom {
  margin: 0 0 55px 0 !important;
}

.margin-60 {
  margin: 60px !important;
}
.margin-60.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-60.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-60.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-60.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-65 {
  margin: 65px !important;
}
.margin-65.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-65.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-65.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-65.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-70 {
  margin: 70px !important;
}
.margin-70.margin-top {
  margin: 70px 0 0 0 !important;
}
.margin-70.margin-left {
  margin: 0 0 0 70px !important;
}
.margin-70.margin-right {
  margin: 0 70px 0 0 !important;
}
.margin-70.margin-bottom {
  margin: 0 0 70px 0 !important;
}

.margin-75 {
  margin: 75px !important;
}
.margin-75.margin-top {
  margin: 75px 0 0 0 !important;
}
.margin-75.margin-left {
  margin: 0 0 0 75px !important;
}
.margin-75.margin-right {
  margin: 0 75px 0 0 !important;
}
.margin-75.margin-bottom {
  margin: 0 0 75px 0 !important;
}

.margin-80 {
  margin: 80px !important;
}
.margin-80.margin-top {
  margin: 80px 0 0 0 !important;
}
.margin-80.margin-left {
  margin: 0 0 0 80px !important;
}
.margin-80.margin-right {
  margin: 0 80px 0 0 !important;
}
.margin-80.margin-bottom {
  margin: 0 0 80px 0 !important;
}

.margin-85 {
  margin: 85px !important;
}
.margin-85.margin-top {
  margin: 85px 0 0 0 !important;
}
.margin-85.margin-left {
  margin: 0 0 0 85px !important;
}
.margin-85.margin-right {
  margin: 0 85px 0 0 !important;
}
.margin-85.margin-bottom {
  margin: 0 0 85px 0 !important;
}

.margin-90 {
  margin: 90px !important;
}
.margin-90.margin-top {
  margin: 90px 0 0 0 !important;
}
.margin-90.margin-left {
  margin: 0 0 0 90px !important;
}
.margin-90.margin-right {
  margin: 0 90px 0 0 !important;
}
.margin-90.margin-bottom {
  margin: 0 0 90px 0 !important;
}

.margin-95 {
  margin: 95px !important;
}
.margin-95.margin-top {
  margin: 95px 0 0 0 !important;
}
.margin-95.margin-left {
  margin: 0 0 0 95px !important;
}
.margin-95.margin-right {
  margin: 0 95px 0 0 !important;
}
.margin-95.margin-bottom {
  margin: 0 0 95px 0 !important;
}

.margin-100 {
  margin: 100px !important;
}
.margin-100.margin-top {
  margin: 100px 0 0 0 !important;
}
.margin-100.margin-left {
  margin: 0 0 0 100px !important;
}
.margin-100.margin-right {
  margin: 0 100px 0 0 !important;
}
.margin-100.margin-bottom {
  margin: 0 0 100px 0 !important;
}

/* margin */
/* height */
.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-310 {
  height: 310px !important;
}

.h-320 {
  height: 320px !important;
}

.h-330 {
  height: 330px !important;
}

.h-340 {
  height: 340px !important;
}

.h-350 {
  height: 350px !important;
}

.h-360 {
  height: 360px !important;
}

.h-370 {
  height: 370px !important;
}

.h-380 {
  height: 380px !important;
}

.h-390 {
  height: 390px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

.h-750 {
  height: 750px !important;
}

.h-800 {
  height: 800px !important;
}

/* height */
/* width */
.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.w-750 {
  width: 750px !important;
}

.w-800 {
  width: 800px !important;
}

/* width */
/* min height */
.min-h-50 {
  min-height: 50px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.min-h-450 {
  min-height: 450px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.min-h-650 {
  min-height: 650px !important;
}

.min-h-700 {
  min-height: 700px !important;
}

.min-h-750 {
  min-height: 750px !important;
}

.min-h-800 {
  min-height: 800px !important;
}

/* min height */
/* min width */
.min-w-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.min-w-450 {
  min-width: 450px !important;
}

.min-w-500 {
  min-width: 500px !important;
}

.min-w-550 {
  min-width: 550px !important;
}

.min-w-600 {
  min-width: 600px !important;
}

.min-w-650 {
  min-width: 650px !important;
}

.min-w-700 {
  min-width: 700px !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.min-w-800 {
  min-width: 800px !important;
}

/* min width */
/* max height */
.max-h-50 {
  max-height: 50px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-550 {
  max-height: 550px !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-650 {
  max-height: 650px !important;
}

.max-h-700 {
  max-height: 700px !important;
}

.max-h-750 {
  max-height: 750px !important;
}

.max-h-800 {
  max-height: 800px !important;
}

/* max height */
/* max width */
.max-w-50 {
  max-width: 50px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-550 {
  max-width: 550px !important;
}

.max-w-600 {
  max-width: 600px !important;
}

.max-w-650 {
  max-width: 650px !important;
}

.max-w-700 {
  max-width: 700px !important;
}

.max-w-750 {
  max-width: 750px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

/* max width */
/* white-space */
.ws-inherit {
  white-space: inherit !important;
}

.ws-initial {
  white-space: initial !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.ws-pre {
  white-space: pre !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.ws-unset {
  white-space: unset !important;
}

/* white-space */
/* margin */
/* border */
.no-border {
  border: none !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.b-3 {
  border-width: 3px !important;
}

.b-4 {
  border-width: 4px !important;
}

.b-5 {
  border-width: 5px !important;
}

.b-6 {
  border-width: 6px !important;
}

.b-7 {
  border-width: 7px !important;
}

.b-8 {
  border-width: 8px !important;
}

.b-9 {
  border-width: 9px !important;
}

.b-10 {
  border-width: 10px !important;
}

.bl-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
}

.bl-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
}

.bl-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
}

.bl-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
}

.bl-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
}

.bl-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
}

.bl-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
}

.bl-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
}

.bl-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
}

.bl-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
}

.br-1 {
  border-width: 0 !important;
  border-right-width: 1px !important;
}

.br-2 {
  border-width: 0 !important;
  border-right-width: 2px !important;
}

.br-3 {
  border-width: 0 !important;
  border-right-width: 3px !important;
}

.br-4 {
  border-width: 0 !important;
  border-right-width: 4px !important;
}

.br-5 {
  border-width: 0 !important;
  border-right-width: 5px !important;
}

.br-6 {
  border-width: 0 !important;
  border-right-width: 6px !important;
}

.br-7 {
  border-width: 0 !important;
  border-right-width: 7px !important;
}

.br-8 {
  border-width: 0 !important;
  border-right-width: 8px !important;
}

.br-9 {
  border-width: 0 !important;
  border-right-width: 9px !important;
}

.br-10 {
  border-width: 0 !important;
  border-right-width: 10px !important;
}

.bt-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

.bt-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
}

.bt-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
}

.bt-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
}

.bt-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
}

.bt-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
}

.bt-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
}

.bt-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
}

.bt-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
}

.bt-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
}

.bb-1 {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-width: 0 !important;
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-width: 0 !important;
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-width: 0 !important;
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-width: 0 !important;
  border-bottom-width: 5px !important;
}

.bb-6 {
  border-width: 0 !important;
  border-bottom-width: 6px !important;
}

.bb-7 {
  border-width: 0 !important;
  border-bottom-width: 7px !important;
}

.bb-8 {
  border-width: 0 !important;
  border-bottom-width: 8px !important;
}

.bb-9 {
  border-width: 0 !important;
  border-bottom-width: 9px !important;
}

.bb-10 {
  border-width: 0 !important;
  border-bottom-width: 10px !important;
}

.blr-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.blr-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.blr-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.blr-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.blr-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}

.blr-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
  border-right-width: 6px !important;
}

.blr-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
  border-right-width: 7px !important;
}

.blr-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.blr-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}

.blr-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
  border-right-width: 10px !important;
}

.btb-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btb-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.btb-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

.btb-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.btb-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
  border-bottom-width: 5px !important;
}

.btb-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
  border-bottom-width: 6px !important;
}

.btb-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
  border-bottom-width: 7px !important;
}

.btb-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.btb-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
  border-bottom-width: 9px !important;
}

.btb-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
  border-bottom-width: 10px !important;
}

.b-dotted {
  border-style: dotted !important;
}

.b-dashed {
  border-style: dashed !important;
}

.b-solid {
  border-style: solid !important;
}

.b-double {
  border-style: double !important;
}

.b-groove {
  border-style: groove !important;
}

.b-ridge {
  border-style: ridge !important;
}

.b-inset {
  border-style: inset !important;
}

.b-outset {
  border-style: outset !important;
}

.b-none {
  border-style: none !important;
}

.b-hidden {
  border-style: hidden !important;
}

.b-mix {
  border-style: dotted dashed solid double !important;
}

/* border */
/* outline style */
.outline-dotted {
  outline-style: dotted;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-solid {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-groove {
  outline-style: groove;
}

.outline-ridge {
  outline-style: ridge;
}

.outline-inset {
  outline-style: inset;
}

.outline-outset {
  outline-style: outset;
}

/* outline style */
/* h and small */
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  font-size: 75%;
  color: #798892;
}

/* text align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-left-exact {
  text-align: left !important;
}

/* text align */
/* border */
.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

/* border */
/* float */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* float */
/* direction */
.direction-right {
  direction: rtl !important;
}

.direction-left {
  direction: ltr !important;
}

/* direction */
/* spacing */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.pb-0,
.py-0,
.p-0 {
  padding-bottom: 0 !important;
}

.pt-0,
.py-0,
.p-0 {
  padding-top: 0 !important;
}

.pl-0,
.px-0,
.p-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0,
.p-0 {
  padding-right: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-4 {
  padding-bottom: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.mb-0,
.my-0,
.m-0 {
  margin-bottom: 0 !important;
}

.mt-0,
.my-0,
.m-0 {
  margin-top: 0 !important;
}

.ml-0,
.mx-0,
.m-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0,
.m-0 {
  margin-right: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-4 {
  margin-bottom: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* spacing */
/* overflow */
.s-overflow-x {
  overflow-x: scroll !important;
}

.s-overflow-y {
  overflow-y: scroll !important;
}

.s-overflow {
  overflow: scroll !important;
}

.a-overflow-x {
  overflow-x: auto !important;
}

.a-overflow-y {
  overflow-y: auto !important;
}

.a-overflow {
  overflow: auto !important;
}

.h-overflow-x {
  overflow-x: hidden !important;
}

.h-overflow-y {
  overflow-y: hidden !important;
}

.h-overflow {
  overflow: hidden !important;
}

/* overflow */
/* text align */
/* cursor */
.c-pointer {
  cursor: pointer !important;
}

.c-none {
  cursor: none !important;
}

.c-initial {
  cursor: initial !important;
}

/* cursor */
/* float */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* float */
.width-5rem {
  width: 5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-center {
  display: flex;
}
.flex-center.horizontal {
  justify-content: center;
}
.flex-center.vertical {
  align-items: center;
}
.flex-center.both {
  align-items: center;
  justify-content: center;
}
.flex-center.space-around {
  justify-content: space-around;
}
.flex-center.space-between {
  justify-content: space-between;
}

.zoom-09 {
  zoom: 0.9 !important;
}

.width-2rem {
  width: 2rem !important;
}

.width-3rem {
  width: 3rem;
}

.width-6rem {
  width: 6rem;
}

.width-8rem {
  width: 8rem;
}

.width-12rem {
  width: 12rem;
}

.h-100vh {
  height: 100vh !important;
}

.box-shadow-50 {
  box-shadow: 5px 0px 28px 10px rgba(0, 0, 0, 0.5) !important;
}

/**
 * google-material-color v1.2.6
 * https://github.com/danlevan/google-material-color
 */
.palette-Red.bg {
  background-color: #f44336;
}

.palette-Red.text {
  color: #f44336;
}

.palette-Red-50.bg {
  background-color: #ffebee;
}

.palette-Red-50.text {
  color: #ffebee;
}

.palette-Red-100.bg {
  background-color: #ffcdd2;
}

.palette-Red-100.text {
  color: #ffcdd2;
}

.palette-Red-200.bg {
  background-color: #ef9a9a;
}

.palette-Red-200.text {
  color: #ef9a9a;
}

.palette-Red-300.bg {
  background-color: #e57373;
}

.palette-Red-300.text {
  color: #e57373;
}

.palette-Red-400.bg {
  background-color: #ef5350;
}

.palette-Red-400.text {
  color: #ef5350;
}

.palette-Red-500.bg {
  background-color: #f44336;
}

.palette-Red-500.text {
  color: #f44336;
}

.palette-Red-600.bg {
  background-color: #e53935;
}

.palette-Red-600.text {
  color: #e53935;
}

.palette-Red-700.bg {
  background-color: #d32f2f;
}

.palette-Red-700.text {
  color: #d32f2f;
}

.palette-Red-800.bg {
  background-color: #c62828;
}

.palette-Red-800.text {
  color: #c62828;
}

.palette-Red-900.bg {
  background-color: #b71c1c;
}

.palette-Red-900.text {
  color: #b71c1c;
}

.palette-Red-A100.bg {
  background-color: #ff8a80;
}

.palette-Red-A100.text {
  color: #ff8a80;
}

.palette-Red-A200.bg {
  background-color: #ff5252;
}

.palette-Red-A200.text {
  color: #ff5252;
}

.palette-Red-A400.bg {
  background-color: #ff1744;
}

.palette-Red-A400.text {
  color: #ff1744;
}

.palette-Red-A700.bg {
  background-color: #d50000;
}

.palette-Red-A700.text {
  color: #d50000;
}

.palette-Pink.bg {
  background-color: #e91e63;
}

.palette-Pink.text {
  color: #e91e63;
}

.palette-Pink-50.bg {
  background-color: #fce4ec;
}

.palette-Pink-50.text {
  color: #fce4ec;
}

.palette-Pink-100.bg {
  background-color: #f8bbd0;
}

.palette-Pink-100.text {
  color: #f8bbd0;
}

.palette-Pink-200.bg {
  background-color: #f48fb1;
}

.palette-Pink-200.text {
  color: #f48fb1;
}

.palette-Pink-300.bg {
  background-color: #f06292;
}

.palette-Pink-300.text {
  color: #f06292;
}

.palette-Pink-400.bg {
  background-color: #ec407a;
}

.palette-Pink-400.text {
  color: #ec407a;
}

.palette-Pink-500.bg {
  background-color: #e91e63;
}

.palette-Pink-500.text {
  color: #e91e63;
}

.palette-Pink-600.bg {
  background-color: #d81b60;
}

.palette-Pink-600.text {
  color: #d81b60;
}

.palette-Pink-700.bg {
  background-color: #c2185b;
}

.palette-Pink-700.text {
  color: #c2185b;
}

.palette-Pink-800.bg {
  background-color: #ad1457;
}

.palette-Pink-800.text {
  color: #ad1457;
}

.palette-Pink-900.bg {
  background-color: #880e4f;
}

.palette-Pink-900.text {
  color: #880e4f;
}

.palette-Pink-A100.bg {
  background-color: #ff80ab;
}

.palette-Pink-A100.text {
  color: #ff80ab;
}

.palette-Pink-A200.bg {
  background-color: #ff4081;
}

.palette-Pink-A200.text {
  color: #ff4081;
}

.palette-Pink-A400.bg {
  background-color: #f50057;
}

.palette-Pink-A400.text {
  color: #f50057;
}

.palette-Pink-A700.bg {
  background-color: #c51162;
}

.palette-Pink-A700.text {
  color: #c51162;
}

.palette-Purple.bg {
  background-color: #9c27b0;
}

.palette-Purple.text {
  color: #9c27b0;
}

.palette-Purple-50.bg {
  background-color: #f3e5f5;
}

.palette-Purple-50.text {
  color: #f3e5f5;
}

.palette-Purple-100.bg {
  background-color: #e1bee7;
}

.palette-Purple-100.text {
  color: #e1bee7;
}

.palette-Purple-200.bg {
  background-color: #ce93d8;
}

.palette-Purple-200.text {
  color: #ce93d8;
}

.palette-Purple-300.bg {
  background-color: #ba68c8;
}

.palette-Purple-300.text {
  color: #ba68c8;
}

.palette-Purple-400.bg {
  background-color: #ab47bc;
}

.palette-Purple-400.text {
  color: #ab47bc;
}

.palette-Purple-500.bg {
  background-color: #9c27b0;
}

.palette-Purple-500.text {
  color: #9c27b0;
}

.palette-Purple-600.bg {
  background-color: #8e24aa;
}

.palette-Purple-600.text {
  color: #8e24aa;
}

.palette-Purple-700.bg {
  background-color: #7b1fa2;
}

.palette-Purple-700.text {
  color: #7b1fa2;
}

.palette-Purple-800.bg {
  background-color: #6a1b9a;
}

.palette-Purple-800.text {
  color: #6a1b9a;
}

.palette-Purple-900.bg {
  background-color: #4a148c;
}

.palette-Purple-900.text {
  color: #4a148c;
}

.palette-Purple-A100.bg {
  background-color: #ea80fc;
}

.palette-Purple-A100.text {
  color: #ea80fc;
}

.palette-Purple-A200.bg {
  background-color: #e040fb;
}

.palette-Purple-A200.text {
  color: #e040fb;
}

.palette-Purple-A400.bg {
  background-color: #d500f9;
}

.palette-Purple-A400.text {
  color: #d500f9;
}

.palette-Purple-A700.bg {
  background-color: #aa00ff;
}

.palette-Purple-A700.text {
  color: #aa00ff;
}

.palette-Deep-Purple.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple.text {
  color: #673ab7;
}

.palette-Deep-Purple-50.bg {
  background-color: #ede7f6;
}

.palette-Deep-Purple-50.text {
  color: #ede7f6;
}

.palette-Deep-Purple-100.bg {
  background-color: #d1c4e9;
}

.palette-Deep-Purple-100.text {
  color: #d1c4e9;
}

.palette-Deep-Purple-200.bg {
  background-color: #b39ddb;
}

.palette-Deep-Purple-200.text {
  color: #b39ddb;
}

.palette-Deep-Purple-300.bg {
  background-color: #9575cd;
}

.palette-Deep-Purple-300.text {
  color: #9575cd;
}

.palette-Deep-Purple-400.bg {
  background-color: #7e57c2;
}

.palette-Deep-Purple-400.text {
  color: #7e57c2;
}

.palette-Deep-Purple-500.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple-500.text {
  color: #673ab7;
}

.palette-Deep-Purple-600.bg {
  background-color: #5e35b1;
}

.palette-Deep-Purple-600.text {
  color: #5e35b1;
}

.palette-Deep-Purple-700.bg {
  background-color: #512da8;
}

.palette-Deep-Purple-700.text {
  color: #512da8;
}

.palette-Deep-Purple-800.bg {
  background-color: #4527a0;
}

.palette-Deep-Purple-800.text {
  color: #4527a0;
}

.palette-Deep-Purple-900.bg {
  background-color: #311b92;
}

.palette-Deep-Purple-900.text {
  color: #311b92;
}

.palette-Deep-Purple-A100.bg {
  background-color: #b388ff;
}

.palette-Deep-Purple-A100.text {
  color: #b388ff;
}

.palette-Deep-Purple-A200.bg {
  background-color: #7c4dff;
}

.palette-Deep-Purple-A200.text {
  color: #7c4dff;
}

.palette-Deep-Purple-A400.bg {
  background-color: #651fff;
}

.palette-Deep-Purple-A400.text {
  color: #651fff;
}

.palette-Deep-Purple-A700.bg {
  background-color: #6200ea;
}

.palette-Deep-Purple-A700.text {
  color: #6200ea;
}

.palette-Indigo.bg {
  background-color: #3f51b5;
}

.palette-Indigo.text {
  color: #3f51b5;
}

.palette-Indigo-50.bg {
  background-color: #e8eaf6;
}

.palette-Indigo-50.text {
  color: #e8eaf6;
}

.palette-Indigo-100.bg {
  background-color: #c5cae9;
}

.palette-Indigo-100.text {
  color: #c5cae9;
}

.palette-Indigo-200.bg {
  background-color: #9fa8da;
}

.palette-Indigo-200.text {
  color: #9fa8da;
}

.palette-Indigo-300.bg {
  background-color: #7986cb;
}

.palette-Indigo-300.text {
  color: #7986cb;
}

.palette-Indigo-400.bg {
  background-color: #5c6bc0;
}

.palette-Indigo-400.text {
  color: #5c6bc0;
}

.palette-Indigo-500.bg {
  background-color: #3f51b5;
}

.palette-Indigo-500.text {
  color: #3f51b5;
}

.palette-Indigo-600.bg {
  background-color: #3949ab;
}

.palette-Indigo-600.text {
  color: #3949ab;
}

.palette-Indigo-700.bg {
  background-color: #303f9f;
}

.palette-Indigo-700.text {
  color: #303f9f;
}

.palette-Indigo-800.bg {
  background-color: #283593;
}

.palette-Indigo-800.text {
  color: #283593;
}

.palette-Indigo-900.bg {
  background-color: #1a237e;
}

.palette-Indigo-900.text {
  color: #1a237e;
}

.palette-Indigo-A100.bg {
  background-color: #8c9eff;
}

.palette-Indigo-A100.text {
  color: #8c9eff;
}

.palette-Indigo-A200.bg {
  background-color: #536dfe;
}

.palette-Indigo-A200.text {
  color: #536dfe;
}

.palette-Indigo-A400.bg {
  background-color: #3d5afe;
}

.palette-Indigo-A400.text {
  color: #3d5afe;
}

.palette-Indigo-A700.bg {
  background-color: #304ffe;
}

.palette-Indigo-A700.text {
  color: #304ffe;
}

.palette-Blue.bg {
  background-color: #2196f3;
}

.palette-Blue.text {
  color: #2196f3;
}

.palette-Blue-50.bg {
  background-color: #e3f2fd;
}

.palette-Blue-50.text {
  color: #e3f2fd;
}

.palette-Blue-100.bg {
  background-color: #bbdefb;
}

.palette-Blue-100.text {
  color: #bbdefb;
}

.palette-Blue-200.bg {
  background-color: #90caf9;
}

.palette-Blue-200.text {
  color: #90caf9;
}

.palette-Blue-300.bg {
  background-color: #64b5f6;
}

.palette-Blue-300.text {
  color: #64b5f6;
}

.palette-Blue-400.bg {
  background-color: #42a5f5;
}

.palette-Blue-400.text {
  color: #42a5f5;
}

.palette-Blue-500.bg {
  background-color: #2196f3;
}

.palette-Blue-500.text {
  color: #2196f3;
}

.palette-Blue-600.bg {
  background-color: #1e88e5;
}

.palette-Blue-600.text {
  color: #1e88e5;
}

.palette-Blue-700.bg {
  background-color: #1976d2;
}

.palette-Blue-700.text {
  color: #1976d2;
}

.palette-Blue-800.bg {
  background-color: #1565c0;
}

.palette-Blue-800.text {
  color: #1565c0;
}

.palette-Blue-900.bg {
  background-color: #0d47a1;
}

.palette-Blue-900.text {
  color: #0d47a1;
}

.palette-Blue-A100.bg {
  background-color: #82b1ff;
}

.palette-Blue-A100.text {
  color: #82b1ff;
}

.palette-Blue-A200.bg {
  background-color: #448aff;
}

.palette-Blue-A200.text {
  color: #448aff;
}

.palette-Blue-A400.bg {
  background-color: #2979ff;
}

.palette-Blue-A400.text {
  color: #2979ff;
}

.palette-Blue-A700.bg {
  background-color: #2962ff;
}

.palette-Blue-A700.text {
  color: #2962ff;
}

.palette-Light-Blue.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue.text {
  color: #03a9f4;
}

.palette-Light-Blue-50.bg {
  background-color: #e1f5fe;
}

.palette-Light-Blue-50.text {
  color: #e1f5fe;
}

.palette-Light-Blue-100.bg {
  background-color: #b3e5fc;
}

.palette-Light-Blue-100.text {
  color: #b3e5fc;
}

.palette-Light-Blue-200.bg {
  background-color: #81d4fa;
}

.palette-Light-Blue-200.text {
  color: #81d4fa;
}

.palette-Light-Blue-300.bg {
  background-color: #4fc3f7;
}

.palette-Light-Blue-300.text {
  color: #4fc3f7;
}

.palette-Light-Blue-400.bg {
  background-color: #29b6f6;
}

.palette-Light-Blue-400.text {
  color: #29b6f6;
}

.palette-Light-Blue-500.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue-500.text {
  color: #03a9f4;
}

.palette-Light-Blue-600.bg {
  background-color: #039be5;
}

.palette-Light-Blue-600.text {
  color: #039be5;
}

.palette-Light-Blue-700.bg {
  background-color: #0288d1;
}

.palette-Light-Blue-700.text {
  color: #0288d1;
}

.palette-Light-Blue-800.bg {
  background-color: #0277bd;
}

.palette-Light-Blue-800.text {
  color: #0277bd;
}

.palette-Light-Blue-900.bg {
  background-color: #01579b;
}

.palette-Light-Blue-900.text {
  color: #01579b;
}

.palette-Light-Blue-A100.bg {
  background-color: #80d8ff;
}

.palette-Light-Blue-A100.text {
  color: #80d8ff;
}

.palette-Light-Blue-A200.bg {
  background-color: #40c4ff;
}

.palette-Light-Blue-A200.text {
  color: #40c4ff;
}

.palette-Light-Blue-A400.bg {
  background-color: #00b0ff;
}

.palette-Light-Blue-A400.text {
  color: #00b0ff;
}

.palette-Light-Blue-A700.bg {
  background-color: #0091ea;
}

.palette-Light-Blue-A700.text {
  color: #0091ea;
}

.palette-Cyan.bg {
  background-color: #00bcd4;
}

.palette-Cyan.text {
  color: #00bcd4;
}

.palette-Cyan-50.bg {
  background-color: #e0f7fa;
}

.palette-Cyan-50.text {
  color: #e0f7fa;
}

.palette-Cyan-100.bg {
  background-color: #b2ebf2;
}

.palette-Cyan-100.text {
  color: #b2ebf2;
}

.palette-Cyan-200.bg {
  background-color: #80deea;
}

.palette-Cyan-200.text {
  color: #80deea;
}

.palette-Cyan-300.bg {
  background-color: #4dd0e1;
}

.palette-Cyan-300.text {
  color: #4dd0e1;
}

.palette-Cyan-400.bg {
  background-color: #26c6da;
}

.palette-Cyan-400.text {
  color: #26c6da;
}

.palette-Cyan-500.bg {
  background-color: #00bcd4;
}

.palette-Cyan-500.text {
  color: #00bcd4;
}

.palette-Cyan-600.bg {
  background-color: #00acc1;
}

.palette-Cyan-600.text {
  color: #00acc1;
}

.palette-Cyan-700.bg {
  background-color: #0097a7;
}

.palette-Cyan-700.text {
  color: #0097a7;
}

.palette-Cyan-800.bg {
  background-color: #00838f;
}

.palette-Cyan-800.text {
  color: #00838f;
}

.palette-Cyan-900.bg {
  background-color: #006064;
}

.palette-Cyan-900.text {
  color: #006064;
}

.palette-Cyan-A100.bg {
  background-color: #84ffff;
}

.palette-Cyan-A100.text {
  color: #84ffff;
}

.palette-Cyan-A200.bg {
  background-color: #18ffff;
}

.palette-Cyan-A200.text {
  color: #18ffff;
}

.palette-Cyan-A400.bg {
  background-color: #00e5ff;
}

.palette-Cyan-A400.text {
  color: #00e5ff;
}

.palette-Cyan-A700.bg {
  background-color: #00b8d4;
}

.palette-Cyan-A700.text {
  color: #00b8d4;
}

.palette-Teal.bg {
  background-color: #4791cd;
}

.palette-Teal.text {
  color: #4791cd;
}

.palette-Teal-50.bg {
  background-color: #e0f2f1;
}

.palette-Teal-50.text {
  color: #e0f2f1;
}

.palette-Teal-100.bg {
  background-color: #b2dfdb;
}

.palette-Teal-100.text {
  color: #b2dfdb;
}

.palette-Teal-200.bg {
  background-color: #80cbc4;
}

.palette-Teal-200.text {
  color: #80cbc4;
}

.palette-Teal-300.bg {
  background-color: #4db6ac;
}

.palette-Teal-300.text {
  color: #4db6ac;
}

.palette-Teal-400.bg {
  background-color: #26a69a;
}

.palette-Teal-400.text {
  color: #26a69a;
}

.palette-Teal-500.bg {
  background-color: #4791cd;
}

.palette-Teal-500.text {
  color: #4791cd;
}

.palette-Teal-600.bg {
  background-color: #00897b;
}

.palette-Teal-600.text {
  color: #00897b;
}

.palette-Teal-700.bg {
  background-color: #00796b;
}

.palette-Teal-700.text {
  color: #00796b;
}

.palette-Teal-800.bg {
  background-color: #00695c;
}

.palette-Teal-800.text {
  color: #00695c;
}

.palette-Teal-900.bg {
  background-color: #004d40;
}

.palette-Teal-900.text {
  color: #004d40;
}

.palette-Teal-A100.bg {
  background-color: #a7ffeb;
}

.palette-Teal-A100.text {
  color: #a7ffeb;
}

.palette-Teal-A200.bg {
  background-color: #64ffda;
}

.palette-Teal-A200.text {
  color: #64ffda;
}

.palette-Teal-A400.bg {
  background-color: #1de9b6;
}

.palette-Teal-A400.text {
  color: #1de9b6;
}

.palette-Teal-A700.bg {
  background-color: #00bfa5;
}

.palette-Teal-A700.text {
  color: #00bfa5;
}

.palette-Green.bg {
  background-color: #4caf50;
}

.palette-Green.text {
  color: #4caf50;
}

.palette-Green-50.bg {
  background-color: #e8f5e9;
}

.palette-Green-50.text {
  color: #e8f5e9;
}

.palette-Green-100.bg {
  background-color: #c8e6c9;
}

.palette-Green-100.text {
  color: #c8e6c9;
}

.palette-Green-200.bg {
  background-color: #a5d6a7;
}

.palette-Green-200.text {
  color: #a5d6a7;
}

.palette-Green-300.bg {
  background-color: #81c784;
}

.palette-Green-300.text {
  color: #81c784;
}

.palette-Green-400.bg {
  background-color: #66bb6a;
}

.palette-Green-400.text {
  color: #66bb6a;
}

.palette-Green-500.bg {
  background-color: #4caf50;
}

.palette-Green-500.text {
  color: #4caf50;
}

.palette-Green-600.bg {
  background-color: #43a047;
}

.palette-Green-600.text {
  color: #43a047;
}

.palette-Green-700.bg {
  background-color: #388e3c;
}

.palette-Green-700.text {
  color: #388e3c;
}

.palette-Green-800.bg {
  background-color: #2e7d32;
}

.palette-Green-800.text {
  color: #2e7d32;
}

.palette-Green-900.bg {
  background-color: #1b5e20;
}

.palette-Green-900.text {
  color: #1b5e20;
}

.palette-Green-A100.bg {
  background-color: #b9f6ca;
}

.palette-Green-A100.text {
  color: #b9f6ca;
}

.palette-Green-A200.bg {
  background-color: #69f0ae;
}

.palette-Green-A200.text {
  color: #69f0ae;
}

.palette-Green-A400.bg {
  background-color: #00e676;
}

.palette-Green-A400.text {
  color: #00e676;
}

.palette-Green-A700.bg {
  background-color: #00c853;
}

.palette-Green-A700.text {
  color: #00c853;
}

.palette-Light-Green.bg {
  background-color: #8bc34a;
}

.palette-Light-Green.text {
  color: #8bc34a;
}

.palette-Light-Green-50.bg {
  background-color: #f1f8e9;
}

.palette-Light-Green-50.text {
  color: #f1f8e9;
}

.palette-Light-Green-100.bg {
  background-color: #dcedc8;
}

.palette-Light-Green-100.text {
  color: #dcedc8;
}

.palette-Light-Green-200.bg {
  background-color: #c5e1a5;
}

.palette-Light-Green-200.text {
  color: #c5e1a5;
}

.palette-Light-Green-300.bg {
  background-color: #aed581;
}

.palette-Light-Green-300.text {
  color: #aed581;
}

.palette-Light-Green-400.bg {
  background-color: #9ccc65;
}

.palette-Light-Green-400.text {
  color: #9ccc65;
}

.palette-Light-Green-500.bg {
  background-color: #8bc34a;
}

.palette-Light-Green-500.text {
  color: #8bc34a;
}

.palette-Light-Green-600.bg {
  background-color: #7cb342;
}

.palette-Light-Green-600.text {
  color: #7cb342;
}

.palette-Light-Green-700.bg {
  background-color: #689f38;
}

.palette-Light-Green-700.text {
  color: #689f38;
}

.palette-Light-Green-800.bg {
  background-color: #558b2f;
}

.palette-Light-Green-800.text {
  color: #558b2f;
}

.palette-Light-Green-900.bg {
  background-color: #33691e;
}

.palette-Light-Green-900.text {
  color: #33691e;
}

.palette-Light-Green-A100.bg {
  background-color: #ccff90;
}

.palette-Light-Green-A100.text {
  color: #ccff90;
}

.palette-Light-Green-A200.bg {
  background-color: #b2ff59;
}

.palette-Light-Green-A200.text {
  color: #b2ff59;
}

.palette-Light-Green-A400.bg {
  background-color: #76ff03;
}

.palette-Light-Green-A400.text {
  color: #76ff03;
}

.palette-Light-Green-A700.bg {
  background-color: #64dd17;
}

.palette-Light-Green-A700.text {
  color: #64dd17;
}

.palette-Lime.bg {
  background-color: #cddc39;
}

.palette-Lime.text {
  color: #cddc39;
}

.palette-Lime-50.bg {
  background-color: #f9fbe7;
}

.palette-Lime-50.text {
  color: #f9fbe7;
}

.palette-Lime-100.bg {
  background-color: #f0f4c3;
}

.palette-Lime-100.text {
  color: #f0f4c3;
}

.palette-Lime-200.bg {
  background-color: #e6ee9c;
}

.palette-Lime-200.text {
  color: #e6ee9c;
}

.palette-Lime-300.bg {
  background-color: #dce775;
}

.palette-Lime-300.text {
  color: #dce775;
}

.palette-Lime-400.bg {
  background-color: #d4e157;
}

.palette-Lime-400.text {
  color: #d4e157;
}

.palette-Lime-500.bg {
  background-color: #cddc39;
}

.palette-Lime-500.text {
  color: #cddc39;
}

.palette-Lime-600.bg {
  background-color: #c0ca33;
}

.palette-Lime-600.text {
  color: #c0ca33;
}

.palette-Lime-700.bg {
  background-color: #afb42b;
}

.palette-Lime-700.text {
  color: #afb42b;
}

.palette-Lime-800.bg {
  background-color: #9e9d24;
}

.palette-Lime-800.text {
  color: #9e9d24;
}

.palette-Lime-900.bg {
  background-color: #827717;
}

.palette-Lime-900.text {
  color: #827717;
}

.palette-Lime-A100.bg {
  background-color: #f4ff81;
}

.palette-Lime-A100.text {
  color: #f4ff81;
}

.palette-Lime-A200.bg {
  background-color: #eeff41;
}

.palette-Lime-A200.text {
  color: #eeff41;
}

.palette-Lime-A400.bg {
  background-color: #c6ff00;
}

.palette-Lime-A400.text {
  color: #c6ff00;
}

.palette-Lime-A700.bg {
  background-color: #aeea00;
}

.palette-Lime-A700.text {
  color: #aeea00;
}

.palette-Yellow.bg {
  background-color: #ffeb3b;
}

.palette-Yellow.text {
  color: #ffeb3b;
}

.palette-Yellow-50.bg {
  background-color: #fffde7;
}

.palette-Yellow-50.text {
  color: #fffde7;
}

.palette-Yellow-100.bg {
  background-color: #fff9c4;
}

.palette-Yellow-100.text {
  color: #fff9c4;
}

.palette-Yellow-200.bg {
  background-color: #fff59d;
}

.palette-Yellow-200.text {
  color: #fff59d;
}

.palette-Yellow-300.bg {
  background-color: #fff176;
}

.palette-Yellow-300.text {
  color: #fff176;
}

.palette-Yellow-400.bg {
  background-color: #ffee58;
}

.palette-Yellow-400.text {
  color: #ffee58;
}

.palette-Yellow-500.bg {
  background-color: #ffeb3b;
}

.palette-Yellow-500.text {
  color: #ffeb3b;
}

.palette-Yellow-600.bg {
  background-color: #fdd835;
}

.palette-Yellow-600.text {
  color: #fdd835;
}

.palette-Yellow-700.bg {
  background-color: #fbc02d;
}

.palette-Yellow-700.text {
  color: #fbc02d;
}

.palette-Yellow-800.bg {
  background-color: #f9a825;
}

.palette-Yellow-800.text {
  color: #f9a825;
}

.palette-Yellow-900.bg {
  background-color: #f57f17;
}

.palette-Yellow-900.text {
  color: #f57f17;
}

.palette-Yellow-A100.bg {
  background-color: #ffff8d;
}

.palette-Yellow-A100.text {
  color: #ffff8d;
}

.palette-Yellow-A200.bg {
  background-color: #ffff00;
}

.palette-Yellow-A200.text {
  color: #ffff00;
}

.palette-Yellow-A400.bg {
  background-color: #ffea00;
}

.palette-Yellow-A400.text {
  color: #ffea00;
}

.palette-Yellow-A700.bg {
  background-color: #ffd600;
}

.palette-Yellow-A700.text {
  color: #ffd600;
}

.palette-Amber.bg {
  background-color: #ffc107;
}

.palette-Amber.text {
  color: #ffc107;
}

.palette-Amber-50.bg {
  background-color: #fff8e1;
}

.palette-Amber-50.text {
  color: #fff8e1;
}

.palette-Amber-100.bg {
  background-color: #ffecb3;
}

.palette-Amber-100.text {
  color: #ffecb3;
}

.palette-Amber-200.bg {
  background-color: #ffe082;
}

.palette-Amber-200.text {
  color: #ffe082;
}

.palette-Amber-300.bg {
  background-color: #ffd54f;
}

.palette-Amber-300.text {
  color: #ffd54f;
}

.palette-Amber-400.bg {
  background-color: #ffca28;
}

.palette-Amber-400.text {
  color: #ffca28;
}

.palette-Amber-500.bg {
  background-color: #ffc107;
}

.palette-Amber-500.text {
  color: #ffc107;
}

.palette-Amber-600.bg {
  background-color: #ffb300;
}

.palette-Amber-600.text {
  color: #ffb300;
}

.palette-Amber-700.bg {
  background-color: #ffa000;
}

.palette-Amber-700.text {
  color: #ffa000;
}

.palette-Amber-800.bg {
  background-color: #ff8f00;
}

.palette-Amber-800.text {
  color: #ff8f00;
}

.palette-Amber-900.bg {
  background-color: #ff6f00;
}

.palette-Amber-900.text {
  color: #ff6f00;
}

.palette-Amber-A100.bg {
  background-color: #ffe57f;
}

.palette-Amber-A100.text {
  color: #ffe57f;
}

.palette-Amber-A200.bg {
  background-color: #ffd740;
}

.palette-Amber-A200.text {
  color: #ffd740;
}

.palette-Amber-A400.bg {
  background-color: #ffc400;
}

.palette-Amber-A400.text {
  color: #ffc400;
}

.palette-Amber-A700.bg {
  background-color: #ffab00;
}

.palette-Amber-A700.text {
  color: #ffab00;
}

.palette-Orange.bg {
  background-color: #ff9800;
}

.palette-Orange.text {
  color: #ff9800;
}

.palette-Orange-50.bg {
  background-color: #fff3e0;
}

.palette-Orange-50.text {
  color: #fff3e0;
}

.palette-Orange-100.bg {
  background-color: #ffe0b2;
}

.palette-Orange-100.text {
  color: #ffe0b2;
}

.palette-Orange-200.bg {
  background-color: #ffcc80;
}

.palette-Orange-200.text {
  color: #ffcc80;
}

.palette-Orange-300.bg {
  background-color: #ffb74d;
}

.palette-Orange-300.text {
  color: #ffb74d;
}

.palette-Orange-400.bg {
  background-color: #ffa726;
}

.palette-Orange-400.text {
  color: #ffa726;
}

.palette-Orange-500.bg {
  background-color: #ff9800;
}

.palette-Orange-500.text {
  color: #ff9800;
}

.palette-Orange-600.bg {
  background-color: #fb8c00;
}

.palette-Orange-600.text {
  color: #fb8c00;
}

.palette-Orange-700.bg {
  background-color: #f57c00;
}

.palette-Orange-700.text {
  color: #f57c00;
}

.palette-Orange-800.bg {
  background-color: #ef6c00;
}

.palette-Orange-800.text {
  color: #ef6c00;
}

.palette-Orange-900.bg {
  background-color: #e65100;
}

.palette-Orange-900.text {
  color: #e65100;
}

.palette-Orange-A100.bg {
  background-color: #ffd180;
}

.palette-Orange-A100.text {
  color: #ffd180;
}

.palette-Orange-A200.bg {
  background-color: #ffab40;
}

.palette-Orange-A200.text {
  color: #ffab40;
}

.palette-Orange-A400.bg {
  background-color: #ff9100;
}

.palette-Orange-A400.text {
  color: #ff9100;
}

.palette-Orange-A700.bg {
  background-color: #ff6d00;
}

.palette-Orange-A700.text {
  color: #ff6d00;
}

.palette-Deep-Orange.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange.text {
  color: #ff5722;
}

.palette-Deep-Orange-50.bg {
  background-color: #fbe9e7;
}

.palette-Deep-Orange-50.text {
  color: #fbe9e7;
}

.palette-Deep-Orange-100.bg {
  background-color: #ffccbc;
}

.palette-Deep-Orange-100.text {
  color: #ffccbc;
}

.palette-Deep-Orange-200.bg {
  background-color: #ffab91;
}

.palette-Deep-Orange-200.text {
  color: #ffab91;
}

.palette-Deep-Orange-300.bg {
  background-color: #ff8a65;
}

.palette-Deep-Orange-300.text {
  color: #ff8a65;
}

.palette-Deep-Orange-400.bg {
  background-color: #ff7043;
}

.palette-Deep-Orange-400.text {
  color: #ff7043;
}

.palette-Deep-Orange-500.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange-500.text {
  color: #ff5722;
}

.palette-Deep-Orange-600.bg {
  background-color: #f4511e;
}

.palette-Deep-Orange-600.text {
  color: #f4511e;
}

.palette-Deep-Orange-700.bg {
  background-color: #e64a19;
}

.palette-Deep-Orange-700.text {
  color: #e64a19;
}

.palette-Deep-Orange-800.bg {
  background-color: #d84315;
}

.palette-Deep-Orange-800.text {
  color: #d84315;
}

.palette-Deep-Orange-900.bg {
  background-color: #bf360c;
}

.palette-Deep-Orange-900.text {
  color: #bf360c;
}

.palette-Deep-Orange-A100.bg {
  background-color: #ff9e80;
}

.palette-Deep-Orange-A100.text {
  color: #ff9e80;
}

.palette-Deep-Orange-A200.bg {
  background-color: #ff6e40;
}

.palette-Deep-Orange-A200.text {
  color: #ff6e40;
}

.palette-Deep-Orange-A400.bg {
  background-color: #ff3d00;
}

.palette-Deep-Orange-A400.text {
  color: #ff3d00;
}

.palette-Deep-Orange-A700.bg {
  background-color: #dd2c00;
}

.palette-Deep-Orange-A700.text {
  color: #dd2c00;
}

.palette-Brown.bg {
  background-color: #795548;
}

.palette-Brown.text {
  color: #795548;
}

.palette-Brown-50.bg {
  background-color: #efebe9;
}

.palette-Brown-50.text {
  color: #efebe9;
}

.palette-Brown-100.bg {
  background-color: #d7ccc8;
}

.palette-Brown-100.text {
  color: #d7ccc8;
}

.palette-Brown-200.bg {
  background-color: #bcaaa4;
}

.palette-Brown-200.text {
  color: #bcaaa4;
}

.palette-Brown-300.bg {
  background-color: #a1887f;
}

.palette-Brown-300.text {
  color: #a1887f;
}

.palette-Brown-400.bg {
  background-color: #8d6e63;
}

.palette-Brown-400.text {
  color: #8d6e63;
}

.palette-Brown-500.bg {
  background-color: #795548;
}

.palette-Brown-500.text {
  color: #795548;
}

.palette-Brown-600.bg {
  background-color: #6d4c41;
}

.palette-Brown-600.text {
  color: #6d4c41;
}

.palette-Brown-700.bg {
  background-color: #5d4037;
}

.palette-Brown-700.text {
  color: #5d4037;
}

.palette-Brown-800.bg {
  background-color: #4e342e;
}

.palette-Brown-800.text {
  color: #4e342e;
}

.palette-Brown-900.bg {
  background-color: #3e2723;
}

.palette-Brown-900.text {
  color: #3e2723;
}

.palette-Grey.bg {
  background-color: #9e9e9e;
}

.palette-Grey.text {
  color: #9e9e9e;
}

.palette-Grey-50.bg {
  background-color: #fafafa;
}

.palette-Grey-50.text {
  color: #fafafa;
}

.palette-Grey-100.bg {
  background-color: #f5f5f5;
}

.palette-Grey-100.text {
  color: #f5f5f5;
}

.palette-Grey-200.bg {
  background-color: #eeeeee;
}

.palette-Grey-200.text {
  color: #eeeeee;
}

.palette-Grey-300.bg {
  background-color: #e0e0e0;
}

.palette-Grey-300.text {
  color: #e0e0e0;
}

.palette-Grey-400.bg {
  background-color: #bdbdbd;
}

.palette-Grey-400.text {
  color: #bdbdbd;
}

.palette-Grey-500.bg {
  background-color: #9e9e9e;
}

.palette-Grey-500.text {
  color: #9e9e9e;
}

.palette-Grey-600.bg {
  background-color: #757575;
}

.palette-Grey-600.text {
  color: #757575;
}

.palette-Grey-700.bg {
  background-color: #616161;
}

.palette-Grey-700.text {
  color: #616161;
}

.palette-Grey-800.bg {
  background-color: #424242;
}

.palette-Grey-800.text {
  color: #424242;
}

.palette-Grey-900.bg {
  background-color: #212121;
}

.palette-Grey-900.text {
  color: #212121;
}

.palette-Blue-Grey.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey.text {
  color: #607d8b;
}

.palette-Blue-Grey-50.bg {
  background-color: #eceff1;
}

.palette-Blue-Grey-50.text {
  color: #eceff1;
}

.palette-Blue-Grey-100.bg {
  background-color: #cfd8dc;
}

.palette-Blue-Grey-100.text {
  color: #cfd8dc;
}

.palette-Blue-Grey-200.bg {
  background-color: #b0bec5;
}

.palette-Blue-Grey-200.text {
  color: #b0bec5;
}

.palette-Blue-Grey-300.bg {
  background-color: #90a4ae;
}

.palette-Blue-Grey-300.text {
  color: #90a4ae;
}

.palette-Blue-Grey-400.bg {
  background-color: #78909c;
}

.palette-Blue-Grey-400.text {
  color: #78909c;
}

.palette-Blue-Grey-500.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey-500.text {
  color: #607d8b;
}

.palette-Blue-Grey-600.bg {
  background-color: #546e7a;
}

.palette-Blue-Grey-600.text {
  color: #546e7a;
}

.palette-Blue-Grey-700.bg {
  background-color: #455a64;
}

.palette-Blue-Grey-700.text {
  color: #455a64;
}

.palette-Blue-Grey-800.bg {
  background-color: #37474f;
}

.palette-Blue-Grey-800.text {
  color: #37474f;
}

.palette-Blue-Grey-900.bg {
  background-color: #263238;
}

.palette-Blue-Grey-900.text {
  color: #263238;
}

.palette-Black.bg {
  background-color: #000000;
}

.palette-Black.text {
  color: #000000;
}

.palette-Black-500.bg {
  background-color: #000000;
}

.palette-Black-500.text {
  color: #000000;
}

.palette-Black-Text.bg {
  background-color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Text.text {
  color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Secondary-Text.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Secondary-Text.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Disabled.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Disabled.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Dividers.bg {
  background-color: rgba(0, 0, 0, 0.12);
}

.palette-Black-Dividers.text {
  color: rgba(0, 0, 0, 0.12);
}

.palette-White.bg {
  background-color: #ffffff;
}

.palette-White.text {
  color: #ffffff;
}

.palette-White-500.bg {
  background-color: #ffffff;
}

.palette-White-500.text {
  color: #ffffff;
}

.palette-White-Text.bg {
  background-color: #ffffff;
}

.palette-White-Text.text {
  color: #ffffff;
}

.palette-White-Secondary-Text.bg {
  background-color: rgba(255, 255, 255, 0.7);
}

.palette-White-Secondary-Text.text {
  color: rgba(255, 255, 255, 0.7);
}

.palette-White-Icons.bg {
  background-color: #ffffff;
}

.palette-White-Icons.text {
  color: #ffffff;
}

.palette-White-Disabled.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Disabled.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Dividers.bg {
  background-color: rgba(255, 255, 255, 0.12);
}

.palette-White-Dividers.text {
  color: rgba(255, 255, 255, 0.12);
}

.palette-system-primary.bg {
  background-color: #409ace;
}

.palette-system-primary.text {
  color: #409ace;
}

@media only screen and (max-width: 1200px) and (min-width: 500px) {
  .top-page-dropdown {
    top: 15px !important;
  }

  .al-content {
    padding: 10px 10px 10px 10px !important;
  }

  .al-sidebar,
.user-Profile,
.brand-icon {
    width: 300px !important;
  }
  .al-sidebar #user-image,
.user-Profile #user-image,
.brand-icon #user-image {
    padding: 15px 0px 15px 25px !important;
  }
  .al-sidebar #user-image img,
.user-Profile #user-image img,
.brand-icon #user-image img {
    width: 70px !important;
  }

  .page-top,
.brand-icon {
    height: 65px !important;
  }

  .al-sidebar {
    top: 65px !important;
  }

  .multiLingual {
    right: 60px !important;
  }
  .multiLingual button {
    padding: 21px 15px 23px 25px !important;
  }
  .multiLingual .dropdown-menu {
    min-width: 110px !important;
    border-top: 0 !important;
  }

  .al-main {
    padding: 25px 0px 10px 0px;
    margin-left: 330px !important;
  }

  .menu-collapsed .al-sidebar {
    width: 45px !important;
  }
  .menu-collapsed #patient-picture {
    width: 300px;
    left: 45px !important;
    top: 65px;
    width: 240px;
  }
  .menu-collapsed .al-main {
    margin-left: 75px !important;
  }
  .menu-collapsed .business-card {
    width: 270px !important;
  }

  #sidebar-icon {
    top: 17px !important;
  }
  #sidebar-icon span.hamburger {
    top: 9px !important;
  }
  #sidebar-icon span.hamburger span:before,
#sidebar-icon span.hamburger span:after {
    width: 18px !important;
  }
  #sidebar-icon span.line {
    width: 18px !important;
  }
  #sidebar-icon img {
    width: 30px !important;
  }

  #search-item {
    top: 18px !important;
    left: 330px !important;
  }

  .al-sidebar-list-item {
    padding: 15px 0 !important;
  }

  .header-item {
    top: 15px !important;
  }
  .business-card {
    width: 285px !important;
  }
  .business-card .title {
    padding: 20px 0 0 0 !important;
  }
  .notification-hidden-icon {
    opacity: 1 !important;
    pointer-events: auto !important;
  }

  .header-item {
    opacity: 0;
    pointer-events: none;
  }

  .rtl .al-main {
    margin-left: auto !important;
    margin-right: 330px !important;
  }
  .rtl .menu-collapsed .al-main {
    margin-left: auto !important;
    margin-right: 75px !important;
  }
  .rtl #sidebar-icon {
    top: 17px !important;
    left: auto !important;
  }
  .rtl #sidebar-icon span.hamburger {
    top: 9px !important;
    left: auto;
  }
  .rtl #sidebar-icon span.hamburger span:before,
.rtl #sidebar-icon span.hamburger span:after {
    width: 18px !important;
  }
  .rtl #search-item {
    left: auto !important;
    right: 330px !important;
  }
  .rtl #sidebar-icon .hamburger {
    left: auto !important;
    right: 331px;
  }
  .rtl .business-card .title {
    padding: 20px 105px 0 0 !important;
  }
  .rtl .multiLingual {
    left: 60px;
  }
  .rtl #user-image {
    padding: 15px 25px 15px 0 !important;
  }
}
.cg-busy {
  z-index: 12000 !important;
}

.cg-busy-default-sign {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 9999 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
  border: 0 !important;
  display: block !important;
  padding: 0 !important;
}

.cg-busy-default-text {
  display: none !important;
}

.cg-busy-default-spinner {
  position: absolute !important;
  margin: 0 auto !important;
  text-align: center !important;
  display: block !important;
  left: 50% !important;
  top: 40% !important;
}
.cg-busy-default-spinner div {
  height: 50% !important;
}

/*$body-bg: #F0F3F4; // default bg color*/
/* font-size */
.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

/* font-size */
/* font-weight */
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-bold {
  font-weight: bold !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-normal {
  font-weight: normal !important;
}

/* font-weight */
/* text-decoration */
.decoration-dashed {
  text-decoration: dashed !important;
}

.decoration-dotted {
  text-decoration: dotted !important;
}

.decoration-double {
  text-decoration: double !important;
}

.decoration-line-through {
  text-decoration: line-through !important;
}

.decoration-overline {
  text-decoration: overline !important;
}

.decoration-solid {
  text-decoration: solid !important;
}

.decoration-underline {
  text-decoration: underline !important;
}

.decoration-wavy {
  text-decoration: wavy !important;
}

.decoration-none {
  text-decoration: none !important;
}

/* text-decoration */
/* font-family */
.ff-opensans {
  font-family: "Open Sans" !important;
}

.ff-segoeui {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif !important;
}

.ff-ubunto {
  font-family: "ubunto" !important;
}

.ff-cormorantunicase {
  font-family: "Cormorant Unicase" !important;
}

/* font-family */
/* element style */
.no-border {
  border: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-pointer-events {
  pointer-events: none;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.d-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-none {
  display: none !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

/* element style */
/* padding */
.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.plr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ptb-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.plr-5 {
  padding: 0 5px !important;
}

.plr-10 {
  padding: 0 10px !important;
}

.plr-15 {
  padding: 0 15px !important;
}

.plr-20 {
  padding: 0 20px !important;
}

.plr-25 {
  padding: 0 25px !important;
}

.plr-30 {
  padding: 0 30px !important;
}

.plr-35 {
  padding: 0 35px !important;
}

.plr-40 {
  padding: 0 40px !important;
}

.plr-45 {
  padding: 0 45px !important;
}

.plr-50 {
  padding: 0 50px !important;
}

.plr-55 {
  padding: 0 55px !important;
}

.plr-60 {
  padding: 0 60px !important;
}

.plr-65 {
  padding: 0 65px !important;
}

.plr-70 {
  padding: 0 70px !important;
}

.plr-75 {
  padding: 0 75px !important;
}

.plr-80 {
  padding: 0 80px !important;
}

.plr-85 {
  padding: 0 85px !important;
}

.plr-90 {
  padding: 0 90px !important;
}

.plr-95 {
  padding: 0 95px !important;
}

.plr-100 {
  padding: 0 100px !important;
}

.ptb-5 {
  padding: 5px 0 !important;
}

.ptb-10 {
  padding: 10px 0 !important;
}

.ptb-15 {
  padding: 15px 0 !important;
}

.ptb-20 {
  padding: 20px 0 !important;
}

.ptb-25 {
  padding: 25px 0 !important;
}

.ptb-30 {
  padding: 30px 0 !important;
}

.ptb-35 {
  padding: 35px 0 !important;
}

.ptb-40 {
  padding: 40px 0 !important;
}

.ptb-45 {
  padding: 45px 0 !important;
}

.ptb-50 {
  padding: 50px 0 !important;
}

.ptb-55 {
  padding: 55px 0 !important;
}

.ptb-60 {
  padding: 60px 0 !important;
}

.ptb-65 {
  padding: 65px 0 !important;
}

.ptb-70 {
  padding: 70px 0 !important;
}

.ptb-75 {
  padding: 75px 0 !important;
}

.ptb-80 {
  padding: 80px 0 !important;
}

.ptb-85 {
  padding: 85px 0 !important;
}

.ptb-90 {
  padding: 90px 0 !important;
}

.ptb-95 {
  padding: 95px 0 !important;
}

.ptb-100 {
  padding: 100px 0 0 !important;
}

.padding-5 {
  padding: 5px !important;
}
.padding-5.padding-top {
  padding: 5px 0 0 0 !important;
}
.padding-5.padding-left {
  padding: 0 0 0 5px !important;
}
.padding-5.padding-right {
  padding: 0 5px 0 0 !important;
}
.padding-5.padding-bottom {
  padding: 0 0 5px 0 !important;
}

.padding-10 {
  padding: 10px !important;
}
.padding-10.padding-top {
  padding: 10px 0 0 0 !important;
}
.padding-10.padding-left {
  padding: 0 0 0 10px !important;
}
.padding-10.padding-right {
  padding: 0 10px 0 0 !important;
}
.padding-10.padding-bottom {
  padding: 0 0 10px 0 !important;
}

.padding-15 {
  padding: 15px !important;
}
.padding-15.padding-top {
  padding: 15px 0 0 0 !important;
}
.padding-15.padding-left {
  padding: 0 0 0 15px !important;
}
.padding-15.padding-right {
  padding: 0 15px 0 0 !important;
}
.padding-15.padding-bottom {
  padding: 0 0 15px 0 !important;
}

.padding-20 {
  padding: 20px !important;
}
.padding-20.padding-top {
  padding: 20px 0 0 0 !important;
}
.padding-20.padding-left {
  padding: 0 0 0 20px !important;
}
.padding-20.padding-right {
  padding: 0 20px 0 0 !important;
}
.padding-20.padding-bottom {
  padding: 0 0 20px 0 !important;
}

.padding-25 {
  padding: 25px !important;
}
.padding-25.padding-top {
  padding: 25px 0 0 0 !important;
}
.padding-25.padding-left {
  padding: 0 0 0 25px !important;
}
.padding-25.padding-right {
  padding: 0 25px 0 0 !important;
}
.padding-25.padding-bottom {
  padding: 0 0 25px 0 !important;
}

.padding-30 {
  padding: 30px !important;
}
.padding-30.padding-top {
  padding: 30px 0 0 0 !important;
}
.padding-30.padding-left {
  padding: 0 0 0 30px !important;
}
.padding-30.padding-right {
  padding: 0 30px 0 0 !important;
}
.padding-30.padding-bottom {
  padding: 0 0 30px 0 !important;
}

.padding-35 {
  padding: 35px !important;
}
.padding-35.padding-top {
  padding: 35px 0 0 0 !important;
}
.padding-35.padding-left {
  padding: 0 0 0 35px !important;
}
.padding-35.padding-right {
  padding: 0 35px 0 0 !important;
}
.padding-35.padding-bottom {
  padding: 0 0 35px 0 !important;
}

.padding-40 {
  padding: 40px !important;
}
.padding-40.padding-top {
  padding: 40px 0 0 0 !important;
}
.padding-40.padding-left {
  padding: 0 0 0 40px !important;
}
.padding-40.padding-right {
  padding: 0 40px 0 0 !important;
}
.padding-40.padding-bottom {
  padding: 0 0 40px 0 !important;
}

.padding-45 {
  padding: 45px !important;
}
.padding-45.padding-top {
  padding: 45px 0 0 0 !important;
}
.padding-45.padding-left {
  padding: 0 0 0 45px !important;
}
.padding-45.padding-right {
  padding: 0 45px 0 0 !important;
}
.padding-45.padding-bottom {
  padding: 0 0 45px 0 !important;
}

.padding-50 {
  padding: 50px !important;
}
.padding-50.padding-top {
  padding: 50px 0 0 0 !important;
}
.padding-50.padding-left {
  padding: 0 0 0 50px !important;
}
.padding-50.padding-right {
  padding: 0 50px 0 0 !important;
}
.padding-50.padding-bottom {
  padding: 0 0 50px 0 !important;
}

.padding-55 {
  padding: 55px !important;
}
.padding-55.padding-top {
  padding: 55px 0 0 0 !important;
}
.padding-55.padding-left {
  padding: 0 0 0 55px !important;
}
.padding-55.padding-right {
  padding: 0 55px 0 0 !important;
}
.padding-55.padding-bottom {
  padding: 0 0 55px 0 !important;
}

.padding-60 {
  padding: 60px !important;
}
.padding-60.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-60.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-60.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-60.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-65 {
  padding: 65px !important;
}
.padding-65.padding-top {
  padding: 65px 0 0 0 !important;
}
.padding-65.padding-left {
  padding: 0 0 0 65px !important;
}
.padding-65.padding-right {
  padding: 0 65px 0 0 !important;
}
.padding-65.padding-bottom {
  padding: 0 0 65px 0 !important;
}

.padding-70 {
  padding: 70px !important;
}
.padding-70.padding-top {
  padding: 70px 0 0 0 !important;
}
.padding-70.padding-left {
  padding: 0 0 0 70px !important;
}
.padding-70.padding-right {
  padding: 0 70px 0 0 !important;
}
.padding-70.padding-bottom {
  padding: 0 0 70px 0 !important;
}

.padding-75 {
  padding: 75px !important;
}
.padding-75.padding-top {
  padding: 75px 0 0 0 !important;
}
.padding-75.padding-left {
  padding: 0 0 0 75px !important;
}
.padding-75.padding-right {
  padding: 0 75px 0 0 !important;
}
.padding-75.padding-bottom {
  padding: 0 0 75px 0 !important;
}

.padding-80 {
  padding: 80px !important;
}
.padding-80.padding-top {
  padding: 80px 0 0 0 !important;
}
.padding-80.padding-left {
  padding: 0 0 0 80px !important;
}
.padding-80.padding-right {
  padding: 0 80px 0 0 !important;
}
.padding-80.padding-bottom {
  padding: 0 0 80px 0 !important;
}

.padding-85 {
  padding: 85px !important;
}
.padding-85.padding-top {
  padding: 85px 0 0 0 !important;
}
.padding-85.padding-left {
  padding: 0 0 0 85px !important;
}
.padding-85.padding-right {
  padding: 0 85px 0 0 !important;
}
.padding-85.padding-bottom {
  padding: 0 0 85px 0 !important;
}

.padding-90 {
  padding: 90px !important;
}
.padding-90.padding-top {
  padding: 90px 0 0 0 !important;
}
.padding-90.padding-left {
  padding: 0 0 0 90px !important;
}
.padding-90.padding-right {
  padding: 0 90px 0 0 !important;
}
.padding-90.padding-bottom {
  padding: 0 0 90px 0 !important;
}

.padding-95 {
  padding: 95px !important;
}
.padding-95.padding-top {
  padding: 95px 0 0 0 !important;
}
.padding-95.padding-left {
  padding: 0 0 0 95px !important;
}
.padding-95.padding-right {
  padding: 0 95px 0 0 !important;
}
.padding-95.padding-bottom {
  padding: 0 0 95px 0 !important;
}

.padding-100 {
  padding: 100px !important;
}
.padding-100.padding-top {
  padding: 100px 0 0 0 !important;
}
.padding-100.padding-left {
  padding: 0 0 0 100px !important;
}
.padding-100.padding-right {
  padding: 0 100px 0 0 !important;
}
.padding-100.padding-bottom {
  padding: 0 0 100px 0 !important;
}

/* padding */
/* margin */
.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mlr-5 {
  margin: 0 5px !important;
}

.mlr-10 {
  margin: 0 10px !important;
}

.mlr-15 {
  margin: 0 15px !important;
}

.mlr-20 {
  margin: 0 20px !important;
}

.mlr-25 {
  margin: 0 25px !important;
}

.mlr-30 {
  margin: 0 30px !important;
}

.mlr-35 {
  margin: 0 35px !important;
}

.mlr-40 {
  margin: 0 40px !important;
}

.mlr-45 {
  margin: 0 45px !important;
}

.mlr-50 {
  margin: 0 50px !important;
}

.mlr-55 {
  margin: 0 55px !important;
}

.mlr-60 {
  margin: 0 60px !important;
}

.mlr-65 {
  margin: 0 65px !important;
}

.mlr-70 {
  margin: 0 70px !important;
}

.mlr-75 {
  margin: 0 75px !important;
}

.mlr-80 {
  margin: 0 80px !important;
}

.mlr-85 {
  margin: 0 85px !important;
}

.mlr-90 {
  margin: 0 90px !important;
}

.mlr-95 {
  margin: 0 95px !important;
}

.mlr-100 {
  margin: 0 100px !important;
}

.mtb-5 {
  margin: 5px 0 !important;
}

.mtb-10 {
  margin: 10px 0 !important;
}

.mtb-15 {
  margin: 15px 0 !important;
}

.mtb-20 {
  margin: 20px 0 !important;
}

.mtb-25 {
  margin: 25px 0 !important;
}

.mtb-30 {
  margin: 30px 0 !important;
}

.mtb-35 {
  margin: 35px 0 !important;
}

.mtb-40 {
  margin: 40px 0 !important;
}

.mtb-45 {
  margin: 45px 0 !important;
}

.mtb-50 {
  margin: 50px 0 !important;
}

.mtb-55 {
  margin: 55px 0 !important;
}

.mtb-60 {
  margin: 60px 0 !important;
}

.mtb-65 {
  margin: 65px 0 !important;
}

.mtb-70 {
  margin: 70px 0 !important;
}

.mtb-75 {
  margin: 75px 0 !important;
}

.mtb-80 {
  margin: 80px 0 !important;
}

.mtb-85 {
  margin: 85px 0 !important;
}

.mtb-90 {
  margin: 90px 0 !important;
}

.mtb-95 {
  margin: 95px 0 !important;
}

.mtb-100 {
  margin: 100px 0 0 !important;
}

.margin-5 {
  margin: 5px !important;
}
.margin-5.margin-top {
  margin: 5px 0 0 0 !important;
}
.margin-5.margin-left {
  margin: 0 0 0 5px !important;
}
.margin-5.margin-right {
  margin: 0 5px 0 0 !important;
}
.margin-5.margin-bottom {
  margin: 0 0 5px 0 !important;
}

.margin-10 {
  margin: 10px !important;
}
.margin-10.margin-top {
  margin: 10px 0 0 0 !important;
}
.margin-10.margin-left {
  margin: 0 0 0 10px !important;
}
.margin-10.margin-right {
  margin: 0 10px 0 0 !important;
}
.margin-10.margin-bottom {
  margin: 0 0 10px 0 !important;
}

.margin-15 {
  margin: 15px !important;
}
.margin-15.margin-top {
  margin: 15px 0 0 0 !important;
}
.margin-15.margin-left {
  margin: 0 0 0 15px !important;
}
.margin-15.margin-right {
  margin: 0 15px 0 0 !important;
}
.margin-15.margin-bottom {
  margin: 0 0 15px 0 !important;
}

.margin-20 {
  margin: 20px !important;
}
.margin-20.margin-top {
  margin: 20px 0 0 0 !important;
}
.margin-20.margin-left {
  margin: 0 0 0 20px !important;
}
.margin-20.margin-right {
  margin: 0 20px 0 0 !important;
}
.margin-20.margin-bottom {
  margin: 0 0 20px 0 !important;
}

.margin-25 {
  margin: 25px !important;
}
.margin-25.margin-top {
  margin: 25px 0 0 0 !important;
}
.margin-25.margin-left {
  margin: 0 0 0 25px !important;
}
.margin-25.margin-right {
  margin: 0 25px 0 0 !important;
}
.margin-25.margin-bottom {
  margin: 0 0 25px 0 !important;
}

.margin-30 {
  margin: 30px !important;
}
.margin-30.margin-top {
  margin: 30px 0 0 0 !important;
}
.margin-30.margin-left {
  margin: 0 0 0 30px !important;
}
.margin-30.margin-right {
  margin: 0 30px 0 0 !important;
}
.margin-30.margin-bottom {
  margin: 0 0 30px 0 !important;
}

.margin-35 {
  margin: 35px !important;
}
.margin-35.margin-top {
  margin: 35px 0 0 0 !important;
}
.margin-35.margin-left {
  margin: 0 0 0 35px !important;
}
.margin-35.margin-right {
  margin: 0 35px 0 0 !important;
}
.margin-35.margin-bottom {
  margin: 0 0 35px 0 !important;
}

.margin-40 {
  margin: 40px !important;
}
.margin-40.margin-top {
  margin: 40px 0 0 0 !important;
}
.margin-40.margin-left {
  margin: 0 0 0 40px !important;
}
.margin-40.margin-right {
  margin: 0 40px 0 0 !important;
}
.margin-40.margin-bottom {
  margin: 0 0 40px 0 !important;
}

.margin-45 {
  margin: 45px !important;
}
.margin-45.margin-top {
  margin: 45px 0 0 0 !important;
}
.margin-45.margin-left {
  margin: 0 0 0 45px !important;
}
.margin-45.margin-right {
  margin: 0 45px 0 0 !important;
}
.margin-45.margin-bottom {
  margin: 0 0 45px 0 !important;
}

.margin-50 {
  margin: 50px !important;
}
.margin-50.margin-top {
  margin: 50px 0 0 0 !important;
}
.margin-50.margin-left {
  margin: 0 0 0 50px !important;
}
.margin-50.margin-right {
  margin: 0 50px 0 0 !important;
}
.margin-50.margin-bottom {
  margin: 0 0 50px 0 !important;
}

.margin-55 {
  margin: 55px !important;
}
.margin-55.margin-top {
  margin: 55px 0 0 0 !important;
}
.margin-55.margin-left {
  margin: 0 0 0 55px !important;
}
.margin-55.margin-right {
  margin: 0 55px 0 0 !important;
}
.margin-55.margin-bottom {
  margin: 0 0 55px 0 !important;
}

.margin-60 {
  margin: 60px !important;
}
.margin-60.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-60.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-60.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-60.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-65 {
  margin: 65px !important;
}
.margin-65.margin-top {
  margin: 65px 0 0 0 !important;
}
.margin-65.margin-left {
  margin: 0 0 0 65px !important;
}
.margin-65.margin-right {
  margin: 0 65px 0 0 !important;
}
.margin-65.margin-bottom {
  margin: 0 0 65px 0 !important;
}

.margin-70 {
  margin: 70px !important;
}
.margin-70.margin-top {
  margin: 70px 0 0 0 !important;
}
.margin-70.margin-left {
  margin: 0 0 0 70px !important;
}
.margin-70.margin-right {
  margin: 0 70px 0 0 !important;
}
.margin-70.margin-bottom {
  margin: 0 0 70px 0 !important;
}

.margin-75 {
  margin: 75px !important;
}
.margin-75.margin-top {
  margin: 75px 0 0 0 !important;
}
.margin-75.margin-left {
  margin: 0 0 0 75px !important;
}
.margin-75.margin-right {
  margin: 0 75px 0 0 !important;
}
.margin-75.margin-bottom {
  margin: 0 0 75px 0 !important;
}

.margin-80 {
  margin: 80px !important;
}
.margin-80.margin-top {
  margin: 80px 0 0 0 !important;
}
.margin-80.margin-left {
  margin: 0 0 0 80px !important;
}
.margin-80.margin-right {
  margin: 0 80px 0 0 !important;
}
.margin-80.margin-bottom {
  margin: 0 0 80px 0 !important;
}

.margin-85 {
  margin: 85px !important;
}
.margin-85.margin-top {
  margin: 85px 0 0 0 !important;
}
.margin-85.margin-left {
  margin: 0 0 0 85px !important;
}
.margin-85.margin-right {
  margin: 0 85px 0 0 !important;
}
.margin-85.margin-bottom {
  margin: 0 0 85px 0 !important;
}

.margin-90 {
  margin: 90px !important;
}
.margin-90.margin-top {
  margin: 90px 0 0 0 !important;
}
.margin-90.margin-left {
  margin: 0 0 0 90px !important;
}
.margin-90.margin-right {
  margin: 0 90px 0 0 !important;
}
.margin-90.margin-bottom {
  margin: 0 0 90px 0 !important;
}

.margin-95 {
  margin: 95px !important;
}
.margin-95.margin-top {
  margin: 95px 0 0 0 !important;
}
.margin-95.margin-left {
  margin: 0 0 0 95px !important;
}
.margin-95.margin-right {
  margin: 0 95px 0 0 !important;
}
.margin-95.margin-bottom {
  margin: 0 0 95px 0 !important;
}

.margin-100 {
  margin: 100px !important;
}
.margin-100.margin-top {
  margin: 100px 0 0 0 !important;
}
.margin-100.margin-left {
  margin: 0 0 0 100px !important;
}
.margin-100.margin-right {
  margin: 0 100px 0 0 !important;
}
.margin-100.margin-bottom {
  margin: 0 0 100px 0 !important;
}

/* margin */
/* height */
.h-20 {
  height: 20px !important;
}

.h-25 {
  height: 25px;
}

.h-30 {
  height: 30px !important;
}

.h-35 {
  height: 35px !important;
}

.h-50 {
  height: 50px !important;
}

.h-100 {
  height: 100px !important;
}

.h-150 {
  height: 150px !important;
}

.h-200 {
  height: 200px !important;
}

.h-250 {
  height: 250px !important;
}

.h-300 {
  height: 300px !important;
}

.h-310 {
  height: 310px !important;
}

.h-320 {
  height: 320px !important;
}

.h-330 {
  height: 330px !important;
}

.h-340 {
  height: 340px !important;
}

.h-350 {
  height: 350px !important;
}

.h-360 {
  height: 360px !important;
}

.h-370 {
  height: 370px !important;
}

.h-380 {
  height: 380px !important;
}

.h-390 {
  height: 390px !important;
}

.h-400 {
  height: 400px !important;
}

.h-450 {
  height: 450px !important;
}

.h-500 {
  height: 500px !important;
}

.h-550 {
  height: 550px !important;
}

.h-600 {
  height: 600px !important;
}

.h-650 {
  height: 650px !important;
}

.h-700 {
  height: 700px !important;
}

.h-750 {
  height: 750px !important;
}

.h-800 {
  height: 800px !important;
}

/* height */
/* width */
.w-50 {
  width: 50px !important;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}

.w-200 {
  width: 200px !important;
}

.w-250 {
  width: 250px !important;
}

.w-300 {
  width: 300px !important;
}

.w-350 {
  width: 350px !important;
}

.w-400 {
  width: 400px !important;
}

.w-450 {
  width: 450px !important;
}

.w-500 {
  width: 500px !important;
}

.w-550 {
  width: 550px !important;
}

.w-600 {
  width: 600px !important;
}

.w-650 {
  width: 650px !important;
}

.w-700 {
  width: 700px !important;
}

.w-750 {
  width: 750px !important;
}

.w-800 {
  width: 800px !important;
}

/* width */
/* min height */
.min-h-50 {
  min-height: 50px !important;
}

.min-h-100 {
  min-height: 100px !important;
}

.min-h-150 {
  min-height: 150px !important;
}

.min-h-200 {
  min-height: 200px !important;
}

.min-h-250 {
  min-height: 250px !important;
}

.min-h-300 {
  min-height: 300px !important;
}

.min-h-350 {
  min-height: 350px !important;
}

.min-h-400 {
  min-height: 400px !important;
}

.min-h-450 {
  min-height: 450px !important;
}

.min-h-500 {
  min-height: 500px !important;
}

.min-h-550 {
  min-height: 550px !important;
}

.min-h-600 {
  min-height: 600px !important;
}

.min-h-650 {
  min-height: 650px !important;
}

.min-h-700 {
  min-height: 700px !important;
}

.min-h-750 {
  min-height: 750px !important;
}

.min-h-800 {
  min-height: 800px !important;
}

/* min height */
/* min width */
.min-w-0 {
  min-width: 0 !important;
}

.min-w-50 {
  min-width: 50px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-150 {
  min-width: 150px !important;
}

.min-w-200 {
  min-width: 200px !important;
}

.min-w-250 {
  min-width: 250px !important;
}

.min-w-300 {
  min-width: 300px !important;
}

.min-w-350 {
  min-width: 350px !important;
}

.min-w-400 {
  min-width: 400px !important;
}

.min-w-450 {
  min-width: 450px !important;
}

.min-w-500 {
  min-width: 500px !important;
}

.min-w-550 {
  min-width: 550px !important;
}

.min-w-600 {
  min-width: 600px !important;
}

.min-w-650 {
  min-width: 650px !important;
}

.min-w-700 {
  min-width: 700px !important;
}

.min-w-750 {
  min-width: 750px !important;
}

.min-w-800 {
  min-width: 800px !important;
}

/* min width */
/* max height */
.max-h-50 {
  max-height: 50px !important;
}

.max-h-100 {
  max-height: 100px !important;
}

.max-h-150 {
  max-height: 150px !important;
}

.max-h-200 {
  max-height: 200px !important;
}

.max-h-250 {
  max-height: 250px !important;
}

.max-h-300 {
  max-height: 300px !important;
}

.max-h-350 {
  max-height: 350px !important;
}

.max-h-400 {
  max-height: 400px !important;
}

.max-h-450 {
  max-height: 450px !important;
}

.max-h-500 {
  max-height: 500px !important;
}

.max-h-550 {
  max-height: 550px !important;
}

.max-h-600 {
  max-height: 600px !important;
}

.max-h-650 {
  max-height: 650px !important;
}

.max-h-700 {
  max-height: 700px !important;
}

.max-h-750 {
  max-height: 750px !important;
}

.max-h-800 {
  max-height: 800px !important;
}

/* max height */
/* max width */
.max-w-50 {
  max-width: 50px !important;
}

.max-w-100 {
  max-width: 100px !important;
}

.max-w-150 {
  max-width: 150px !important;
}

.max-w-200 {
  max-width: 200px !important;
}

.max-w-250 {
  max-width: 250px !important;
}

.max-w-300 {
  max-width: 300px !important;
}

.max-w-350 {
  max-width: 350px !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-450 {
  max-width: 450px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-550 {
  max-width: 550px !important;
}

.max-w-600 {
  max-width: 600px !important;
}

.max-w-650 {
  max-width: 650px !important;
}

.max-w-700 {
  max-width: 700px !important;
}

.max-w-750 {
  max-width: 750px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

/* max width */
/* white-space */
.ws-inherit {
  white-space: inherit !important;
}

.ws-initial {
  white-space: initial !important;
}

.ws-normal {
  white-space: normal !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.ws-pre {
  white-space: pre !important;
}

.ws-pre-line {
  white-space: pre-line !important;
}

.ws-pre-wrap {
  white-space: pre-wrap !important;
}

.ws-unset {
  white-space: unset !important;
}

/* white-space */
/* margin */
/* border */
.no-border {
  border: none !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.b-3 {
  border-width: 3px !important;
}

.b-4 {
  border-width: 4px !important;
}

.b-5 {
  border-width: 5px !important;
}

.b-6 {
  border-width: 6px !important;
}

.b-7 {
  border-width: 7px !important;
}

.b-8 {
  border-width: 8px !important;
}

.b-9 {
  border-width: 9px !important;
}

.b-10 {
  border-width: 10px !important;
}

.bl-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
}

.bl-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
}

.bl-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
}

.bl-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
}

.bl-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
}

.bl-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
}

.bl-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
}

.bl-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
}

.bl-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
}

.bl-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
}

.br-1 {
  border-width: 0 !important;
  border-right-width: 1px !important;
}

.br-2 {
  border-width: 0 !important;
  border-right-width: 2px !important;
}

.br-3 {
  border-width: 0 !important;
  border-right-width: 3px !important;
}

.br-4 {
  border-width: 0 !important;
  border-right-width: 4px !important;
}

.br-5 {
  border-width: 0 !important;
  border-right-width: 5px !important;
}

.br-6 {
  border-width: 0 !important;
  border-right-width: 6px !important;
}

.br-7 {
  border-width: 0 !important;
  border-right-width: 7px !important;
}

.br-8 {
  border-width: 0 !important;
  border-right-width: 8px !important;
}

.br-9 {
  border-width: 0 !important;
  border-right-width: 9px !important;
}

.br-10 {
  border-width: 0 !important;
  border-right-width: 10px !important;
}

.bt-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
}

.bt-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
}

.bt-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
}

.bt-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
}

.bt-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
}

.bt-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
}

.bt-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
}

.bt-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
}

.bt-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
}

.bt-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
}

.bb-1 {
  border-width: 0 !important;
  border-bottom-width: 1px !important;
}

.bb-2 {
  border-width: 0 !important;
  border-bottom-width: 2px !important;
}

.bb-3 {
  border-width: 0 !important;
  border-bottom-width: 3px !important;
}

.bb-4 {
  border-width: 0 !important;
  border-bottom-width: 4px !important;
}

.bb-5 {
  border-width: 0 !important;
  border-bottom-width: 5px !important;
}

.bb-6 {
  border-width: 0 !important;
  border-bottom-width: 6px !important;
}

.bb-7 {
  border-width: 0 !important;
  border-bottom-width: 7px !important;
}

.bb-8 {
  border-width: 0 !important;
  border-bottom-width: 8px !important;
}

.bb-9 {
  border-width: 0 !important;
  border-bottom-width: 9px !important;
}

.bb-10 {
  border-width: 0 !important;
  border-bottom-width: 10px !important;
}

.blr-1 {
  border-width: 0 !important;
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.blr-2 {
  border-width: 0 !important;
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.blr-3 {
  border-width: 0 !important;
  border-left-width: 3px !important;
  border-right-width: 3px !important;
}

.blr-4 {
  border-width: 0 !important;
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.blr-5 {
  border-width: 0 !important;
  border-left-width: 5px !important;
  border-right-width: 5px !important;
}

.blr-6 {
  border-width: 0 !important;
  border-left-width: 6px !important;
  border-right-width: 6px !important;
}

.blr-7 {
  border-width: 0 !important;
  border-left-width: 7px !important;
  border-right-width: 7px !important;
}

.blr-8 {
  border-width: 0 !important;
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.blr-9 {
  border-width: 0 !important;
  border-left-width: 9px !important;
  border-right-width: 9px !important;
}

.blr-10 {
  border-width: 0 !important;
  border-left-width: 10px !important;
  border-right-width: 10px !important;
}

.btb-1 {
  border-width: 0 !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.btb-2 {
  border-width: 0 !important;
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.btb-3 {
  border-width: 0 !important;
  border-top-width: 3px !important;
  border-bottom-width: 3px !important;
}

.btb-4 {
  border-width: 0 !important;
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.btb-5 {
  border-width: 0 !important;
  border-top-width: 5px !important;
  border-bottom-width: 5px !important;
}

.btb-6 {
  border-width: 0 !important;
  border-top-width: 6px !important;
  border-bottom-width: 6px !important;
}

.btb-7 {
  border-width: 0 !important;
  border-top-width: 7px !important;
  border-bottom-width: 7px !important;
}

.btb-8 {
  border-width: 0 !important;
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.btb-9 {
  border-width: 0 !important;
  border-top-width: 9px !important;
  border-bottom-width: 9px !important;
}

.btb-10 {
  border-width: 0 !important;
  border-top-width: 10px !important;
  border-bottom-width: 10px !important;
}

.b-dotted {
  border-style: dotted !important;
}

.b-dashed {
  border-style: dashed !important;
}

.b-solid {
  border-style: solid !important;
}

.b-double {
  border-style: double !important;
}

.b-groove {
  border-style: groove !important;
}

.b-ridge {
  border-style: ridge !important;
}

.b-inset {
  border-style: inset !important;
}

.b-outset {
  border-style: outset !important;
}

.b-none {
  border-style: none !important;
}

.b-hidden {
  border-style: hidden !important;
}

.b-mix {
  border-style: dotted dashed solid double !important;
}

/* border */
/* outline style */
.outline-dotted {
  outline-style: dotted;
}

.outline-dashed {
  outline-style: dashed;
}

.outline-solid {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-groove {
  outline-style: groove;
}

.outline-ridge {
  outline-style: ridge;
}

.outline-inset {
  outline-style: inset;
}

.outline-outset {
  outline-style: outset;
}

/* outline style */
/* h and small */
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: 400;
  font-size: 75%;
  color: #798892;
}

/* text align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-left-exact {
  text-align: left !important;
}

/* text align */
/* border */
.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

/* border */
/* float */
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/* float */
/* direction */
.direction-right {
  direction: rtl !important;
}

.direction-left {
  direction: ltr !important;
}

/* direction */
/* spacing */
.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.pb-0,
.py-0,
.p-0 {
  padding-bottom: 0 !important;
}

.pt-0,
.py-0,
.p-0 {
  padding-top: 0 !important;
}

.pl-0,
.px-0,
.p-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0,
.p-0 {
  padding-right: 0 !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-4 {
  padding-bottom: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.mb-0,
.my-0,
.m-0 {
  margin-bottom: 0 !important;
}

.mt-0,
.my-0,
.m-0 {
  margin-top: 0 !important;
}

.ml-0,
.mx-0,
.m-0 {
  margin-left: 0 !important;
}

.mr-0,
.mx-0,
.m-0 {
  margin-right: 0 !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-4 {
  margin-bottom: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

/* spacing */
/* overflow */
.s-overflow-x {
  overflow-x: scroll !important;
}

.s-overflow-y {
  overflow-y: scroll !important;
}

.s-overflow {
  overflow: scroll !important;
}

.a-overflow-x {
  overflow-x: auto !important;
}

.a-overflow-y {
  overflow-y: auto !important;
}

.a-overflow {
  overflow: auto !important;
}

.h-overflow-x {
  overflow-x: hidden !important;
}

.h-overflow-y {
  overflow-y: hidden !important;
}

.h-overflow {
  overflow: hidden !important;
}

/* overflow */
/* text align */
/* cursor */
.c-pointer {
  cursor: pointer !important;
}

.c-none {
  cursor: none !important;
}

.c-initial {
  cursor: initial !important;
}

/* cursor */
/* float */
.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

/* float */
.width-5rem {
  width: 5rem !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.flex-center {
  display: flex;
}
.flex-center.horizontal {
  justify-content: center;
}
.flex-center.vertical {
  align-items: center;
}
.flex-center.both {
  align-items: center;
  justify-content: center;
}
.flex-center.space-around {
  justify-content: space-around;
}
.flex-center.space-between {
  justify-content: space-between;
}

.zoom-09 {
  zoom: 0.9 !important;
}

.width-2rem {
  width: 2rem !important;
}

.width-3rem {
  width: 3rem;
}

.width-6rem {
  width: 6rem;
}

.width-8rem {
  width: 8rem;
}

.width-12rem {
  width: 12rem;
}

.h-100vh {
  height: 100vh !important;
}

.box-shadow-50 {
  box-shadow: 5px 0px 28px 10px rgba(0, 0, 0, 0.5) !important;
}

/**
 * google-material-color v1.2.6
 * https://github.com/danlevan/google-material-color
 */
.palette-Red.bg {
  background-color: #f44336;
}

.palette-Red.text {
  color: #f44336;
}

.palette-Red-50.bg {
  background-color: #ffebee;
}

.palette-Red-50.text {
  color: #ffebee;
}

.palette-Red-100.bg {
  background-color: #ffcdd2;
}

.palette-Red-100.text {
  color: #ffcdd2;
}

.palette-Red-200.bg {
  background-color: #ef9a9a;
}

.palette-Red-200.text {
  color: #ef9a9a;
}

.palette-Red-300.bg {
  background-color: #e57373;
}

.palette-Red-300.text {
  color: #e57373;
}

.palette-Red-400.bg {
  background-color: #ef5350;
}

.palette-Red-400.text {
  color: #ef5350;
}

.palette-Red-500.bg {
  background-color: #f44336;
}

.palette-Red-500.text {
  color: #f44336;
}

.palette-Red-600.bg {
  background-color: #e53935;
}

.palette-Red-600.text {
  color: #e53935;
}

.palette-Red-700.bg {
  background-color: #d32f2f;
}

.palette-Red-700.text {
  color: #d32f2f;
}

.palette-Red-800.bg {
  background-color: #c62828;
}

.palette-Red-800.text {
  color: #c62828;
}

.palette-Red-900.bg {
  background-color: #b71c1c;
}

.palette-Red-900.text {
  color: #b71c1c;
}

.palette-Red-A100.bg {
  background-color: #ff8a80;
}

.palette-Red-A100.text {
  color: #ff8a80;
}

.palette-Red-A200.bg {
  background-color: #ff5252;
}

.palette-Red-A200.text {
  color: #ff5252;
}

.palette-Red-A400.bg {
  background-color: #ff1744;
}

.palette-Red-A400.text {
  color: #ff1744;
}

.palette-Red-A700.bg {
  background-color: #d50000;
}

.palette-Red-A700.text {
  color: #d50000;
}

.palette-Pink.bg {
  background-color: #e91e63;
}

.palette-Pink.text {
  color: #e91e63;
}

.palette-Pink-50.bg {
  background-color: #fce4ec;
}

.palette-Pink-50.text {
  color: #fce4ec;
}

.palette-Pink-100.bg {
  background-color: #f8bbd0;
}

.palette-Pink-100.text {
  color: #f8bbd0;
}

.palette-Pink-200.bg {
  background-color: #f48fb1;
}

.palette-Pink-200.text {
  color: #f48fb1;
}

.palette-Pink-300.bg {
  background-color: #f06292;
}

.palette-Pink-300.text {
  color: #f06292;
}

.palette-Pink-400.bg {
  background-color: #ec407a;
}

.palette-Pink-400.text {
  color: #ec407a;
}

.palette-Pink-500.bg {
  background-color: #e91e63;
}

.palette-Pink-500.text {
  color: #e91e63;
}

.palette-Pink-600.bg {
  background-color: #d81b60;
}

.palette-Pink-600.text {
  color: #d81b60;
}

.palette-Pink-700.bg {
  background-color: #c2185b;
}

.palette-Pink-700.text {
  color: #c2185b;
}

.palette-Pink-800.bg {
  background-color: #ad1457;
}

.palette-Pink-800.text {
  color: #ad1457;
}

.palette-Pink-900.bg {
  background-color: #880e4f;
}

.palette-Pink-900.text {
  color: #880e4f;
}

.palette-Pink-A100.bg {
  background-color: #ff80ab;
}

.palette-Pink-A100.text {
  color: #ff80ab;
}

.palette-Pink-A200.bg {
  background-color: #ff4081;
}

.palette-Pink-A200.text {
  color: #ff4081;
}

.palette-Pink-A400.bg {
  background-color: #f50057;
}

.palette-Pink-A400.text {
  color: #f50057;
}

.palette-Pink-A700.bg {
  background-color: #c51162;
}

.palette-Pink-A700.text {
  color: #c51162;
}

.palette-Purple.bg {
  background-color: #9c27b0;
}

.palette-Purple.text {
  color: #9c27b0;
}

.palette-Purple-50.bg {
  background-color: #f3e5f5;
}

.palette-Purple-50.text {
  color: #f3e5f5;
}

.palette-Purple-100.bg {
  background-color: #e1bee7;
}

.palette-Purple-100.text {
  color: #e1bee7;
}

.palette-Purple-200.bg {
  background-color: #ce93d8;
}

.palette-Purple-200.text {
  color: #ce93d8;
}

.palette-Purple-300.bg {
  background-color: #ba68c8;
}

.palette-Purple-300.text {
  color: #ba68c8;
}

.palette-Purple-400.bg {
  background-color: #ab47bc;
}

.palette-Purple-400.text {
  color: #ab47bc;
}

.palette-Purple-500.bg {
  background-color: #9c27b0;
}

.palette-Purple-500.text {
  color: #9c27b0;
}

.palette-Purple-600.bg {
  background-color: #8e24aa;
}

.palette-Purple-600.text {
  color: #8e24aa;
}

.palette-Purple-700.bg {
  background-color: #7b1fa2;
}

.palette-Purple-700.text {
  color: #7b1fa2;
}

.palette-Purple-800.bg {
  background-color: #6a1b9a;
}

.palette-Purple-800.text {
  color: #6a1b9a;
}

.palette-Purple-900.bg {
  background-color: #4a148c;
}

.palette-Purple-900.text {
  color: #4a148c;
}

.palette-Purple-A100.bg {
  background-color: #ea80fc;
}

.palette-Purple-A100.text {
  color: #ea80fc;
}

.palette-Purple-A200.bg {
  background-color: #e040fb;
}

.palette-Purple-A200.text {
  color: #e040fb;
}

.palette-Purple-A400.bg {
  background-color: #d500f9;
}

.palette-Purple-A400.text {
  color: #d500f9;
}

.palette-Purple-A700.bg {
  background-color: #aa00ff;
}

.palette-Purple-A700.text {
  color: #aa00ff;
}

.palette-Deep-Purple.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple.text {
  color: #673ab7;
}

.palette-Deep-Purple-50.bg {
  background-color: #ede7f6;
}

.palette-Deep-Purple-50.text {
  color: #ede7f6;
}

.palette-Deep-Purple-100.bg {
  background-color: #d1c4e9;
}

.palette-Deep-Purple-100.text {
  color: #d1c4e9;
}

.palette-Deep-Purple-200.bg {
  background-color: #b39ddb;
}

.palette-Deep-Purple-200.text {
  color: #b39ddb;
}

.palette-Deep-Purple-300.bg {
  background-color: #9575cd;
}

.palette-Deep-Purple-300.text {
  color: #9575cd;
}

.palette-Deep-Purple-400.bg {
  background-color: #7e57c2;
}

.palette-Deep-Purple-400.text {
  color: #7e57c2;
}

.palette-Deep-Purple-500.bg {
  background-color: #673ab7;
}

.palette-Deep-Purple-500.text {
  color: #673ab7;
}

.palette-Deep-Purple-600.bg {
  background-color: #5e35b1;
}

.palette-Deep-Purple-600.text {
  color: #5e35b1;
}

.palette-Deep-Purple-700.bg {
  background-color: #512da8;
}

.palette-Deep-Purple-700.text {
  color: #512da8;
}

.palette-Deep-Purple-800.bg {
  background-color: #4527a0;
}

.palette-Deep-Purple-800.text {
  color: #4527a0;
}

.palette-Deep-Purple-900.bg {
  background-color: #311b92;
}

.palette-Deep-Purple-900.text {
  color: #311b92;
}

.palette-Deep-Purple-A100.bg {
  background-color: #b388ff;
}

.palette-Deep-Purple-A100.text {
  color: #b388ff;
}

.palette-Deep-Purple-A200.bg {
  background-color: #7c4dff;
}

.palette-Deep-Purple-A200.text {
  color: #7c4dff;
}

.palette-Deep-Purple-A400.bg {
  background-color: #651fff;
}

.palette-Deep-Purple-A400.text {
  color: #651fff;
}

.palette-Deep-Purple-A700.bg {
  background-color: #6200ea;
}

.palette-Deep-Purple-A700.text {
  color: #6200ea;
}

.palette-Indigo.bg {
  background-color: #3f51b5;
}

.palette-Indigo.text {
  color: #3f51b5;
}

.palette-Indigo-50.bg {
  background-color: #e8eaf6;
}

.palette-Indigo-50.text {
  color: #e8eaf6;
}

.palette-Indigo-100.bg {
  background-color: #c5cae9;
}

.palette-Indigo-100.text {
  color: #c5cae9;
}

.palette-Indigo-200.bg {
  background-color: #9fa8da;
}

.palette-Indigo-200.text {
  color: #9fa8da;
}

.palette-Indigo-300.bg {
  background-color: #7986cb;
}

.palette-Indigo-300.text {
  color: #7986cb;
}

.palette-Indigo-400.bg {
  background-color: #5c6bc0;
}

.palette-Indigo-400.text {
  color: #5c6bc0;
}

.palette-Indigo-500.bg {
  background-color: #3f51b5;
}

.palette-Indigo-500.text {
  color: #3f51b5;
}

.palette-Indigo-600.bg {
  background-color: #3949ab;
}

.palette-Indigo-600.text {
  color: #3949ab;
}

.palette-Indigo-700.bg {
  background-color: #303f9f;
}

.palette-Indigo-700.text {
  color: #303f9f;
}

.palette-Indigo-800.bg {
  background-color: #283593;
}

.palette-Indigo-800.text {
  color: #283593;
}

.palette-Indigo-900.bg {
  background-color: #1a237e;
}

.palette-Indigo-900.text {
  color: #1a237e;
}

.palette-Indigo-A100.bg {
  background-color: #8c9eff;
}

.palette-Indigo-A100.text {
  color: #8c9eff;
}

.palette-Indigo-A200.bg {
  background-color: #536dfe;
}

.palette-Indigo-A200.text {
  color: #536dfe;
}

.palette-Indigo-A400.bg {
  background-color: #3d5afe;
}

.palette-Indigo-A400.text {
  color: #3d5afe;
}

.palette-Indigo-A700.bg {
  background-color: #304ffe;
}

.palette-Indigo-A700.text {
  color: #304ffe;
}

.palette-Blue.bg {
  background-color: #2196f3;
}

.palette-Blue.text {
  color: #2196f3;
}

.palette-Blue-50.bg {
  background-color: #e3f2fd;
}

.palette-Blue-50.text {
  color: #e3f2fd;
}

.palette-Blue-100.bg {
  background-color: #bbdefb;
}

.palette-Blue-100.text {
  color: #bbdefb;
}

.palette-Blue-200.bg {
  background-color: #90caf9;
}

.palette-Blue-200.text {
  color: #90caf9;
}

.palette-Blue-300.bg {
  background-color: #64b5f6;
}

.palette-Blue-300.text {
  color: #64b5f6;
}

.palette-Blue-400.bg {
  background-color: #42a5f5;
}

.palette-Blue-400.text {
  color: #42a5f5;
}

.palette-Blue-500.bg {
  background-color: #2196f3;
}

.palette-Blue-500.text {
  color: #2196f3;
}

.palette-Blue-600.bg {
  background-color: #1e88e5;
}

.palette-Blue-600.text {
  color: #1e88e5;
}

.palette-Blue-700.bg {
  background-color: #1976d2;
}

.palette-Blue-700.text {
  color: #1976d2;
}

.palette-Blue-800.bg {
  background-color: #1565c0;
}

.palette-Blue-800.text {
  color: #1565c0;
}

.palette-Blue-900.bg {
  background-color: #0d47a1;
}

.palette-Blue-900.text {
  color: #0d47a1;
}

.palette-Blue-A100.bg {
  background-color: #82b1ff;
}

.palette-Blue-A100.text {
  color: #82b1ff;
}

.palette-Blue-A200.bg {
  background-color: #448aff;
}

.palette-Blue-A200.text {
  color: #448aff;
}

.palette-Blue-A400.bg {
  background-color: #2979ff;
}

.palette-Blue-A400.text {
  color: #2979ff;
}

.palette-Blue-A700.bg {
  background-color: #2962ff;
}

.palette-Blue-A700.text {
  color: #2962ff;
}

.palette-Light-Blue.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue.text {
  color: #03a9f4;
}

.palette-Light-Blue-50.bg {
  background-color: #e1f5fe;
}

.palette-Light-Blue-50.text {
  color: #e1f5fe;
}

.palette-Light-Blue-100.bg {
  background-color: #b3e5fc;
}

.palette-Light-Blue-100.text {
  color: #b3e5fc;
}

.palette-Light-Blue-200.bg {
  background-color: #81d4fa;
}

.palette-Light-Blue-200.text {
  color: #81d4fa;
}

.palette-Light-Blue-300.bg {
  background-color: #4fc3f7;
}

.palette-Light-Blue-300.text {
  color: #4fc3f7;
}

.palette-Light-Blue-400.bg {
  background-color: #29b6f6;
}

.palette-Light-Blue-400.text {
  color: #29b6f6;
}

.palette-Light-Blue-500.bg {
  background-color: #03a9f4;
}

.palette-Light-Blue-500.text {
  color: #03a9f4;
}

.palette-Light-Blue-600.bg {
  background-color: #039be5;
}

.palette-Light-Blue-600.text {
  color: #039be5;
}

.palette-Light-Blue-700.bg {
  background-color: #0288d1;
}

.palette-Light-Blue-700.text {
  color: #0288d1;
}

.palette-Light-Blue-800.bg {
  background-color: #0277bd;
}

.palette-Light-Blue-800.text {
  color: #0277bd;
}

.palette-Light-Blue-900.bg {
  background-color: #01579b;
}

.palette-Light-Blue-900.text {
  color: #01579b;
}

.palette-Light-Blue-A100.bg {
  background-color: #80d8ff;
}

.palette-Light-Blue-A100.text {
  color: #80d8ff;
}

.palette-Light-Blue-A200.bg {
  background-color: #40c4ff;
}

.palette-Light-Blue-A200.text {
  color: #40c4ff;
}

.palette-Light-Blue-A400.bg {
  background-color: #00b0ff;
}

.palette-Light-Blue-A400.text {
  color: #00b0ff;
}

.palette-Light-Blue-A700.bg {
  background-color: #0091ea;
}

.palette-Light-Blue-A700.text {
  color: #0091ea;
}

.palette-Cyan.bg {
  background-color: #00bcd4;
}

.palette-Cyan.text {
  color: #00bcd4;
}

.palette-Cyan-50.bg {
  background-color: #e0f7fa;
}

.palette-Cyan-50.text {
  color: #e0f7fa;
}

.palette-Cyan-100.bg {
  background-color: #b2ebf2;
}

.palette-Cyan-100.text {
  color: #b2ebf2;
}

.palette-Cyan-200.bg {
  background-color: #80deea;
}

.palette-Cyan-200.text {
  color: #80deea;
}

.palette-Cyan-300.bg {
  background-color: #4dd0e1;
}

.palette-Cyan-300.text {
  color: #4dd0e1;
}

.palette-Cyan-400.bg {
  background-color: #26c6da;
}

.palette-Cyan-400.text {
  color: #26c6da;
}

.palette-Cyan-500.bg {
  background-color: #00bcd4;
}

.palette-Cyan-500.text {
  color: #00bcd4;
}

.palette-Cyan-600.bg {
  background-color: #00acc1;
}

.palette-Cyan-600.text {
  color: #00acc1;
}

.palette-Cyan-700.bg {
  background-color: #0097a7;
}

.palette-Cyan-700.text {
  color: #0097a7;
}

.palette-Cyan-800.bg {
  background-color: #00838f;
}

.palette-Cyan-800.text {
  color: #00838f;
}

.palette-Cyan-900.bg {
  background-color: #006064;
}

.palette-Cyan-900.text {
  color: #006064;
}

.palette-Cyan-A100.bg {
  background-color: #84ffff;
}

.palette-Cyan-A100.text {
  color: #84ffff;
}

.palette-Cyan-A200.bg {
  background-color: #18ffff;
}

.palette-Cyan-A200.text {
  color: #18ffff;
}

.palette-Cyan-A400.bg {
  background-color: #00e5ff;
}

.palette-Cyan-A400.text {
  color: #00e5ff;
}

.palette-Cyan-A700.bg {
  background-color: #00b8d4;
}

.palette-Cyan-A700.text {
  color: #00b8d4;
}

.palette-Teal.bg {
  background-color: #4791cd;
}

.palette-Teal.text {
  color: #4791cd;
}

.palette-Teal-50.bg {
  background-color: #e0f2f1;
}

.palette-Teal-50.text {
  color: #e0f2f1;
}

.palette-Teal-100.bg {
  background-color: #b2dfdb;
}

.palette-Teal-100.text {
  color: #b2dfdb;
}

.palette-Teal-200.bg {
  background-color: #80cbc4;
}

.palette-Teal-200.text {
  color: #80cbc4;
}

.palette-Teal-300.bg {
  background-color: #4db6ac;
}

.palette-Teal-300.text {
  color: #4db6ac;
}

.palette-Teal-400.bg {
  background-color: #26a69a;
}

.palette-Teal-400.text {
  color: #26a69a;
}

.palette-Teal-500.bg {
  background-color: #4791cd;
}

.palette-Teal-500.text {
  color: #4791cd;
}

.palette-Teal-600.bg {
  background-color: #00897b;
}

.palette-Teal-600.text {
  color: #00897b;
}

.palette-Teal-700.bg {
  background-color: #00796b;
}

.palette-Teal-700.text {
  color: #00796b;
}

.palette-Teal-800.bg {
  background-color: #00695c;
}

.palette-Teal-800.text {
  color: #00695c;
}

.palette-Teal-900.bg {
  background-color: #004d40;
}

.palette-Teal-900.text {
  color: #004d40;
}

.palette-Teal-A100.bg {
  background-color: #a7ffeb;
}

.palette-Teal-A100.text {
  color: #a7ffeb;
}

.palette-Teal-A200.bg {
  background-color: #64ffda;
}

.palette-Teal-A200.text {
  color: #64ffda;
}

.palette-Teal-A400.bg {
  background-color: #1de9b6;
}

.palette-Teal-A400.text {
  color: #1de9b6;
}

.palette-Teal-A700.bg {
  background-color: #00bfa5;
}

.palette-Teal-A700.text {
  color: #00bfa5;
}

.palette-Green.bg {
  background-color: #4caf50;
}

.palette-Green.text {
  color: #4caf50;
}

.palette-Green-50.bg {
  background-color: #e8f5e9;
}

.palette-Green-50.text {
  color: #e8f5e9;
}

.palette-Green-100.bg {
  background-color: #c8e6c9;
}

.palette-Green-100.text {
  color: #c8e6c9;
}

.palette-Green-200.bg {
  background-color: #a5d6a7;
}

.palette-Green-200.text {
  color: #a5d6a7;
}

.palette-Green-300.bg {
  background-color: #81c784;
}

.palette-Green-300.text {
  color: #81c784;
}

.palette-Green-400.bg {
  background-color: #66bb6a;
}

.palette-Green-400.text {
  color: #66bb6a;
}

.palette-Green-500.bg {
  background-color: #4caf50;
}

.palette-Green-500.text {
  color: #4caf50;
}

.palette-Green-600.bg {
  background-color: #43a047;
}

.palette-Green-600.text {
  color: #43a047;
}

.palette-Green-700.bg {
  background-color: #388e3c;
}

.palette-Green-700.text {
  color: #388e3c;
}

.palette-Green-800.bg {
  background-color: #2e7d32;
}

.palette-Green-800.text {
  color: #2e7d32;
}

.palette-Green-900.bg {
  background-color: #1b5e20;
}

.palette-Green-900.text {
  color: #1b5e20;
}

.palette-Green-A100.bg {
  background-color: #b9f6ca;
}

.palette-Green-A100.text {
  color: #b9f6ca;
}

.palette-Green-A200.bg {
  background-color: #69f0ae;
}

.palette-Green-A200.text {
  color: #69f0ae;
}

.palette-Green-A400.bg {
  background-color: #00e676;
}

.palette-Green-A400.text {
  color: #00e676;
}

.palette-Green-A700.bg {
  background-color: #00c853;
}

.palette-Green-A700.text {
  color: #00c853;
}

.palette-Light-Green.bg {
  background-color: #8bc34a;
}

.palette-Light-Green.text {
  color: #8bc34a;
}

.palette-Light-Green-50.bg {
  background-color: #f1f8e9;
}

.palette-Light-Green-50.text {
  color: #f1f8e9;
}

.palette-Light-Green-100.bg {
  background-color: #dcedc8;
}

.palette-Light-Green-100.text {
  color: #dcedc8;
}

.palette-Light-Green-200.bg {
  background-color: #c5e1a5;
}

.palette-Light-Green-200.text {
  color: #c5e1a5;
}

.palette-Light-Green-300.bg {
  background-color: #aed581;
}

.palette-Light-Green-300.text {
  color: #aed581;
}

.palette-Light-Green-400.bg {
  background-color: #9ccc65;
}

.palette-Light-Green-400.text {
  color: #9ccc65;
}

.palette-Light-Green-500.bg {
  background-color: #8bc34a;
}

.palette-Light-Green-500.text {
  color: #8bc34a;
}

.palette-Light-Green-600.bg {
  background-color: #7cb342;
}

.palette-Light-Green-600.text {
  color: #7cb342;
}

.palette-Light-Green-700.bg {
  background-color: #689f38;
}

.palette-Light-Green-700.text {
  color: #689f38;
}

.palette-Light-Green-800.bg {
  background-color: #558b2f;
}

.palette-Light-Green-800.text {
  color: #558b2f;
}

.palette-Light-Green-900.bg {
  background-color: #33691e;
}

.palette-Light-Green-900.text {
  color: #33691e;
}

.palette-Light-Green-A100.bg {
  background-color: #ccff90;
}

.palette-Light-Green-A100.text {
  color: #ccff90;
}

.palette-Light-Green-A200.bg {
  background-color: #b2ff59;
}

.palette-Light-Green-A200.text {
  color: #b2ff59;
}

.palette-Light-Green-A400.bg {
  background-color: #76ff03;
}

.palette-Light-Green-A400.text {
  color: #76ff03;
}

.palette-Light-Green-A700.bg {
  background-color: #64dd17;
}

.palette-Light-Green-A700.text {
  color: #64dd17;
}

.palette-Lime.bg {
  background-color: #cddc39;
}

.palette-Lime.text {
  color: #cddc39;
}

.palette-Lime-50.bg {
  background-color: #f9fbe7;
}

.palette-Lime-50.text {
  color: #f9fbe7;
}

.palette-Lime-100.bg {
  background-color: #f0f4c3;
}

.palette-Lime-100.text {
  color: #f0f4c3;
}

.palette-Lime-200.bg {
  background-color: #e6ee9c;
}

.palette-Lime-200.text {
  color: #e6ee9c;
}

.palette-Lime-300.bg {
  background-color: #dce775;
}

.palette-Lime-300.text {
  color: #dce775;
}

.palette-Lime-400.bg {
  background-color: #d4e157;
}

.palette-Lime-400.text {
  color: #d4e157;
}

.palette-Lime-500.bg {
  background-color: #cddc39;
}

.palette-Lime-500.text {
  color: #cddc39;
}

.palette-Lime-600.bg {
  background-color: #c0ca33;
}

.palette-Lime-600.text {
  color: #c0ca33;
}

.palette-Lime-700.bg {
  background-color: #afb42b;
}

.palette-Lime-700.text {
  color: #afb42b;
}

.palette-Lime-800.bg {
  background-color: #9e9d24;
}

.palette-Lime-800.text {
  color: #9e9d24;
}

.palette-Lime-900.bg {
  background-color: #827717;
}

.palette-Lime-900.text {
  color: #827717;
}

.palette-Lime-A100.bg {
  background-color: #f4ff81;
}

.palette-Lime-A100.text {
  color: #f4ff81;
}

.palette-Lime-A200.bg {
  background-color: #eeff41;
}

.palette-Lime-A200.text {
  color: #eeff41;
}

.palette-Lime-A400.bg {
  background-color: #c6ff00;
}

.palette-Lime-A400.text {
  color: #c6ff00;
}

.palette-Lime-A700.bg {
  background-color: #aeea00;
}

.palette-Lime-A700.text {
  color: #aeea00;
}

.palette-Yellow.bg {
  background-color: #ffeb3b;
}

.palette-Yellow.text {
  color: #ffeb3b;
}

.palette-Yellow-50.bg {
  background-color: #fffde7;
}

.palette-Yellow-50.text {
  color: #fffde7;
}

.palette-Yellow-100.bg {
  background-color: #fff9c4;
}

.palette-Yellow-100.text {
  color: #fff9c4;
}

.palette-Yellow-200.bg {
  background-color: #fff59d;
}

.palette-Yellow-200.text {
  color: #fff59d;
}

.palette-Yellow-300.bg {
  background-color: #fff176;
}

.palette-Yellow-300.text {
  color: #fff176;
}

.palette-Yellow-400.bg {
  background-color: #ffee58;
}

.palette-Yellow-400.text {
  color: #ffee58;
}

.palette-Yellow-500.bg {
  background-color: #ffeb3b;
}

.palette-Yellow-500.text {
  color: #ffeb3b;
}

.palette-Yellow-600.bg {
  background-color: #fdd835;
}

.palette-Yellow-600.text {
  color: #fdd835;
}

.palette-Yellow-700.bg {
  background-color: #fbc02d;
}

.palette-Yellow-700.text {
  color: #fbc02d;
}

.palette-Yellow-800.bg {
  background-color: #f9a825;
}

.palette-Yellow-800.text {
  color: #f9a825;
}

.palette-Yellow-900.bg {
  background-color: #f57f17;
}

.palette-Yellow-900.text {
  color: #f57f17;
}

.palette-Yellow-A100.bg {
  background-color: #ffff8d;
}

.palette-Yellow-A100.text {
  color: #ffff8d;
}

.palette-Yellow-A200.bg {
  background-color: #ffff00;
}

.palette-Yellow-A200.text {
  color: #ffff00;
}

.palette-Yellow-A400.bg {
  background-color: #ffea00;
}

.palette-Yellow-A400.text {
  color: #ffea00;
}

.palette-Yellow-A700.bg {
  background-color: #ffd600;
}

.palette-Yellow-A700.text {
  color: #ffd600;
}

.palette-Amber.bg {
  background-color: #ffc107;
}

.palette-Amber.text {
  color: #ffc107;
}

.palette-Amber-50.bg {
  background-color: #fff8e1;
}

.palette-Amber-50.text {
  color: #fff8e1;
}

.palette-Amber-100.bg {
  background-color: #ffecb3;
}

.palette-Amber-100.text {
  color: #ffecb3;
}

.palette-Amber-200.bg {
  background-color: #ffe082;
}

.palette-Amber-200.text {
  color: #ffe082;
}

.palette-Amber-300.bg {
  background-color: #ffd54f;
}

.palette-Amber-300.text {
  color: #ffd54f;
}

.palette-Amber-400.bg {
  background-color: #ffca28;
}

.palette-Amber-400.text {
  color: #ffca28;
}

.palette-Amber-500.bg {
  background-color: #ffc107;
}

.palette-Amber-500.text {
  color: #ffc107;
}

.palette-Amber-600.bg {
  background-color: #ffb300;
}

.palette-Amber-600.text {
  color: #ffb300;
}

.palette-Amber-700.bg {
  background-color: #ffa000;
}

.palette-Amber-700.text {
  color: #ffa000;
}

.palette-Amber-800.bg {
  background-color: #ff8f00;
}

.palette-Amber-800.text {
  color: #ff8f00;
}

.palette-Amber-900.bg {
  background-color: #ff6f00;
}

.palette-Amber-900.text {
  color: #ff6f00;
}

.palette-Amber-A100.bg {
  background-color: #ffe57f;
}

.palette-Amber-A100.text {
  color: #ffe57f;
}

.palette-Amber-A200.bg {
  background-color: #ffd740;
}

.palette-Amber-A200.text {
  color: #ffd740;
}

.palette-Amber-A400.bg {
  background-color: #ffc400;
}

.palette-Amber-A400.text {
  color: #ffc400;
}

.palette-Amber-A700.bg {
  background-color: #ffab00;
}

.palette-Amber-A700.text {
  color: #ffab00;
}

.palette-Orange.bg {
  background-color: #ff9800;
}

.palette-Orange.text {
  color: #ff9800;
}

.palette-Orange-50.bg {
  background-color: #fff3e0;
}

.palette-Orange-50.text {
  color: #fff3e0;
}

.palette-Orange-100.bg {
  background-color: #ffe0b2;
}

.palette-Orange-100.text {
  color: #ffe0b2;
}

.palette-Orange-200.bg {
  background-color: #ffcc80;
}

.palette-Orange-200.text {
  color: #ffcc80;
}

.palette-Orange-300.bg {
  background-color: #ffb74d;
}

.palette-Orange-300.text {
  color: #ffb74d;
}

.palette-Orange-400.bg {
  background-color: #ffa726;
}

.palette-Orange-400.text {
  color: #ffa726;
}

.palette-Orange-500.bg {
  background-color: #ff9800;
}

.palette-Orange-500.text {
  color: #ff9800;
}

.palette-Orange-600.bg {
  background-color: #fb8c00;
}

.palette-Orange-600.text {
  color: #fb8c00;
}

.palette-Orange-700.bg {
  background-color: #f57c00;
}

.palette-Orange-700.text {
  color: #f57c00;
}

.palette-Orange-800.bg {
  background-color: #ef6c00;
}

.palette-Orange-800.text {
  color: #ef6c00;
}

.palette-Orange-900.bg {
  background-color: #e65100;
}

.palette-Orange-900.text {
  color: #e65100;
}

.palette-Orange-A100.bg {
  background-color: #ffd180;
}

.palette-Orange-A100.text {
  color: #ffd180;
}

.palette-Orange-A200.bg {
  background-color: #ffab40;
}

.palette-Orange-A200.text {
  color: #ffab40;
}

.palette-Orange-A400.bg {
  background-color: #ff9100;
}

.palette-Orange-A400.text {
  color: #ff9100;
}

.palette-Orange-A700.bg {
  background-color: #ff6d00;
}

.palette-Orange-A700.text {
  color: #ff6d00;
}

.palette-Deep-Orange.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange.text {
  color: #ff5722;
}

.palette-Deep-Orange-50.bg {
  background-color: #fbe9e7;
}

.palette-Deep-Orange-50.text {
  color: #fbe9e7;
}

.palette-Deep-Orange-100.bg {
  background-color: #ffccbc;
}

.palette-Deep-Orange-100.text {
  color: #ffccbc;
}

.palette-Deep-Orange-200.bg {
  background-color: #ffab91;
}

.palette-Deep-Orange-200.text {
  color: #ffab91;
}

.palette-Deep-Orange-300.bg {
  background-color: #ff8a65;
}

.palette-Deep-Orange-300.text {
  color: #ff8a65;
}

.palette-Deep-Orange-400.bg {
  background-color: #ff7043;
}

.palette-Deep-Orange-400.text {
  color: #ff7043;
}

.palette-Deep-Orange-500.bg {
  background-color: #ff5722;
}

.palette-Deep-Orange-500.text {
  color: #ff5722;
}

.palette-Deep-Orange-600.bg {
  background-color: #f4511e;
}

.palette-Deep-Orange-600.text {
  color: #f4511e;
}

.palette-Deep-Orange-700.bg {
  background-color: #e64a19;
}

.palette-Deep-Orange-700.text {
  color: #e64a19;
}

.palette-Deep-Orange-800.bg {
  background-color: #d84315;
}

.palette-Deep-Orange-800.text {
  color: #d84315;
}

.palette-Deep-Orange-900.bg {
  background-color: #bf360c;
}

.palette-Deep-Orange-900.text {
  color: #bf360c;
}

.palette-Deep-Orange-A100.bg {
  background-color: #ff9e80;
}

.palette-Deep-Orange-A100.text {
  color: #ff9e80;
}

.palette-Deep-Orange-A200.bg {
  background-color: #ff6e40;
}

.palette-Deep-Orange-A200.text {
  color: #ff6e40;
}

.palette-Deep-Orange-A400.bg {
  background-color: #ff3d00;
}

.palette-Deep-Orange-A400.text {
  color: #ff3d00;
}

.palette-Deep-Orange-A700.bg {
  background-color: #dd2c00;
}

.palette-Deep-Orange-A700.text {
  color: #dd2c00;
}

.palette-Brown.bg {
  background-color: #795548;
}

.palette-Brown.text {
  color: #795548;
}

.palette-Brown-50.bg {
  background-color: #efebe9;
}

.palette-Brown-50.text {
  color: #efebe9;
}

.palette-Brown-100.bg {
  background-color: #d7ccc8;
}

.palette-Brown-100.text {
  color: #d7ccc8;
}

.palette-Brown-200.bg {
  background-color: #bcaaa4;
}

.palette-Brown-200.text {
  color: #bcaaa4;
}

.palette-Brown-300.bg {
  background-color: #a1887f;
}

.palette-Brown-300.text {
  color: #a1887f;
}

.palette-Brown-400.bg {
  background-color: #8d6e63;
}

.palette-Brown-400.text {
  color: #8d6e63;
}

.palette-Brown-500.bg {
  background-color: #795548;
}

.palette-Brown-500.text {
  color: #795548;
}

.palette-Brown-600.bg {
  background-color: #6d4c41;
}

.palette-Brown-600.text {
  color: #6d4c41;
}

.palette-Brown-700.bg {
  background-color: #5d4037;
}

.palette-Brown-700.text {
  color: #5d4037;
}

.palette-Brown-800.bg {
  background-color: #4e342e;
}

.palette-Brown-800.text {
  color: #4e342e;
}

.palette-Brown-900.bg {
  background-color: #3e2723;
}

.palette-Brown-900.text {
  color: #3e2723;
}

.palette-Grey.bg {
  background-color: #9e9e9e;
}

.palette-Grey.text {
  color: #9e9e9e;
}

.palette-Grey-50.bg {
  background-color: #fafafa;
}

.palette-Grey-50.text {
  color: #fafafa;
}

.palette-Grey-100.bg {
  background-color: #f5f5f5;
}

.palette-Grey-100.text {
  color: #f5f5f5;
}

.palette-Grey-200.bg {
  background-color: #eeeeee;
}

.palette-Grey-200.text {
  color: #eeeeee;
}

.palette-Grey-300.bg {
  background-color: #e0e0e0;
}

.palette-Grey-300.text {
  color: #e0e0e0;
}

.palette-Grey-400.bg {
  background-color: #bdbdbd;
}

.palette-Grey-400.text {
  color: #bdbdbd;
}

.palette-Grey-500.bg {
  background-color: #9e9e9e;
}

.palette-Grey-500.text {
  color: #9e9e9e;
}

.palette-Grey-600.bg {
  background-color: #757575;
}

.palette-Grey-600.text {
  color: #757575;
}

.palette-Grey-700.bg {
  background-color: #616161;
}

.palette-Grey-700.text {
  color: #616161;
}

.palette-Grey-800.bg {
  background-color: #424242;
}

.palette-Grey-800.text {
  color: #424242;
}

.palette-Grey-900.bg {
  background-color: #212121;
}

.palette-Grey-900.text {
  color: #212121;
}

.palette-Blue-Grey.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey.text {
  color: #607d8b;
}

.palette-Blue-Grey-50.bg {
  background-color: #eceff1;
}

.palette-Blue-Grey-50.text {
  color: #eceff1;
}

.palette-Blue-Grey-100.bg {
  background-color: #cfd8dc;
}

.palette-Blue-Grey-100.text {
  color: #cfd8dc;
}

.palette-Blue-Grey-200.bg {
  background-color: #b0bec5;
}

.palette-Blue-Grey-200.text {
  color: #b0bec5;
}

.palette-Blue-Grey-300.bg {
  background-color: #90a4ae;
}

.palette-Blue-Grey-300.text {
  color: #90a4ae;
}

.palette-Blue-Grey-400.bg {
  background-color: #78909c;
}

.palette-Blue-Grey-400.text {
  color: #78909c;
}

.palette-Blue-Grey-500.bg {
  background-color: #607d8b;
}

.palette-Blue-Grey-500.text {
  color: #607d8b;
}

.palette-Blue-Grey-600.bg {
  background-color: #546e7a;
}

.palette-Blue-Grey-600.text {
  color: #546e7a;
}

.palette-Blue-Grey-700.bg {
  background-color: #455a64;
}

.palette-Blue-Grey-700.text {
  color: #455a64;
}

.palette-Blue-Grey-800.bg {
  background-color: #37474f;
}

.palette-Blue-Grey-800.text {
  color: #37474f;
}

.palette-Blue-Grey-900.bg {
  background-color: #263238;
}

.palette-Blue-Grey-900.text {
  color: #263238;
}

.palette-Black.bg {
  background-color: #000000;
}

.palette-Black.text {
  color: #000000;
}

.palette-Black-500.bg {
  background-color: #000000;
}

.palette-Black-500.text {
  color: #000000;
}

.palette-Black-Text.bg {
  background-color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Text.text {
  color: rgba(0, 0, 0, 0.87);
}

.palette-Black-Secondary-Text.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Secondary-Text.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.bg {
  background-color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Icons.text {
  color: rgba(0, 0, 0, 0.54);
}

.palette-Black-Disabled.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Disabled.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.bg {
  background-color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Hint-Text.text {
  color: rgba(0, 0, 0, 0.26);
}

.palette-Black-Dividers.bg {
  background-color: rgba(0, 0, 0, 0.12);
}

.palette-Black-Dividers.text {
  color: rgba(0, 0, 0, 0.12);
}

.palette-White.bg {
  background-color: #ffffff;
}

.palette-White.text {
  color: #ffffff;
}

.palette-White-500.bg {
  background-color: #ffffff;
}

.palette-White-500.text {
  color: #ffffff;
}

.palette-White-Text.bg {
  background-color: #ffffff;
}

.palette-White-Text.text {
  color: #ffffff;
}

.palette-White-Secondary-Text.bg {
  background-color: rgba(255, 255, 255, 0.7);
}

.palette-White-Secondary-Text.text {
  color: rgba(255, 255, 255, 0.7);
}

.palette-White-Icons.bg {
  background-color: #ffffff;
}

.palette-White-Icons.text {
  color: #ffffff;
}

.palette-White-Disabled.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Disabled.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.bg {
  background-color: rgba(255, 255, 255, 0.3);
}

.palette-White-Hint-Text.text {
  color: rgba(255, 255, 255, 0.3);
}

.palette-White-Dividers.bg {
  background-color: rgba(255, 255, 255, 0.12);
}

.palette-White-Dividers.text {
  color: rgba(255, 255, 255, 0.12);
}

.palette-system-primary.bg {
  background-color: #409ace;
}

.palette-system-primary.text {
  color: #409ace;
}

.ui-tabview .ui-tabview-nav > li.ui-state-default {
  padding: 0px 5%;
  color: #bbbbbb;
  border-bottom: #bbbbbb;
  background: transparent;
  outline: none;
}

.ui-tabview .ui-tabview-nav > li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background: transparent;
}

.ui-tabview .ui-tabview-nav > li.ui-state-active {
  background: transparent;
  color: #636363;
  border-bottom: 2px solid #0a6ebd;
}

.ui-tabview .ui-tabview-nav {
  border: 0;
}

.ui-tabview .ui-tabview-nav li a {
  display: inline-block;
  color: #bbbbbb;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  -webkit-transition: all;
  -o-transition: all;
  transition: all;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
}

.ui-tabview .ui-tabview-nav li.ui-tabview-selected a,
.ui-tabview .ui-tabview-nav li.ui-state-disabled a,
.ui-tabview .ui-tabview-nav li.ui-state-processing a {
  cursor: pointer;
  color: #555555;
}

.tab-content-body {
  margin-bottom: 25px;
  position: relative;
}

.main-tab-view .ui-tabview {
  background: inherit;
  padding: 0px;
}
.main-tab-view .ui-tabview .ui-tabview-panel {
  background: #fff;
  margin: 0;
}
.main-tab-view .ui-tabview .ui-tabview-nav li a {
  border-bottom: 2px solid;
  padding-left: 25px;
  padding-right: 25px;
}
.main-tab-view .ui-tabview .ui-tabview-nav > li.ui-state-active {
  background: transparent;
  color: #6a6c6f;
  border: none !important;
}
.main-tab-view .ui-tabview .ui-tabview-nav li.ui-tabview-selected a {
  border-bottom: 2px solid #409ace;
}

.rtl .ui-tabview .ui-tabview-nav > li.ui-state-default {
  float: right;
}

/*$body-bg: #F0F3F4; // default bg color*/
.form-group-default {
  background-color: transparent;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
}

.modal-lg {
  max-width: 80%;
}

.modal-md-lg {
  max-width: 70%;
}

.modal-md {
  max-width: 50%;
}

.modal-sm {
  max-width: 25%;
}

.ui-autocomplete {
  width: 100% !important;
}

.ui-multiselect-label {
  margin-bottom: 0px !important;
  padding: 0px 10px;
  height: 35px;
}

.ui-multiselect.ui-state-focus,
.ui-multiselect.ui-state-focus {
  box-shadow: none !important;
}

.ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: 8px;
}

.ui-multiselect .ui-state-default {
  color: #409ace;
}

.ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  width: 100%;
}

.ui-multiselect.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border-radius: 0px;
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.ui-multiselect-label.ui-placeholder {
  color: #959595 !important;
}

.ui-multiselect.ui-state-disabled,
.ui-multiselect.ui-state-disabled .ui-multiselect-label,
.ui-multiselect.ui-state-disabled .ui-corner-right {
  background-color: #e1e1e1 !important;
  opacity: 1;
  cursor: not-allowed !important;
  color: #8e8e8e !important;
}

.ui-corner-all {
  border-radius: 0px !important;
}

textarea {
  -webkit-box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #212121 !important;
  background-color: #ffffff !important;
  background-image: none !important;
  color: #212121 !important;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.form-group.row.form-group-default .form-control {
  height: 35px;
  margin-top: 0;
}

.form-group {
  margin-bottom: 0;
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.form-horizontal .form-group-default {
  background: inherit;
  border: none !important;
  overflow: inherit;
}
.form-horizontal .form-group.row.form-group-default {
  padding-top: 0px;
}
.form-horizontal .form-group.row.form-group-default label {
  border: none !important;
}
.form-horizontal .form-group.row.form-group-default .switch-small {
  margin-top: 5px;
}
.form-horizontal .form-group.row.form-group-default input {
  padding: 0px 10px;
  box-shadow: 0px 0px 5px 0px #e1e1e1;
  background-color: white;
  border-radius: 0px;
  height: 35px;
}
.form-horizontal .form-group.row.form-group-default .col-lg-3,
.form-horizontal .form-group.row.form-group-default .col-md-6,
.form-horizontal .form-group.row.form-group-default .col-sm-12 {
  padding: 5px 10px 5px 10px;
}
.form-horizontal .form-control[disabled],
.form-horizontal fieldset[disabled] .form-control {
  background-color: #e1e1e1 !important;
}

.has-error > input,
.has-error > p-inputNumber .p-inputnumber-input {
  border: 0 !important;
  border-bottom: 1px solid #f95372 !important;
}

.has-error > p-calendar .ui-inputtext {
  border: 0 !important;
  border-bottom: 1px solid #f95372 !important;
}

.has-error > p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 0 !important;
  border-bottom: 1px solid #f95372 !important;
}

.has-error .p-dropdown {
  border: 0 !important;
  border-bottom: 1px solid #e52222 !important;
  border-radius: 0;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: #4b4b4b !important;
}

.has-error > textarea {
  border: 0 !important;
  border-bottom: 1px solid #f95372 !important;
}

form {
  display: block;
  margin-top: 0em;
}

.m-t-10 {
  margin-top: 10px;
}

p {
  display: block;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}

.input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
  padding: 6px 9px;
  display: table-cell;
  border-radius: 3px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s, background 0.2s linear 0s;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.input-right {
  float: right;
  direction: rtl;
}

.input-group-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-left: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  letter-spacing: normal;
}

.windows h3 {
  line-height: 33px;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

input[type=radio],
input[type=checkbox] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  border: 1px solid #d6d6d6;
}

.label {
  border-radius: 0;
}

.label-primary {
  background: #00abff;
}

.label-info {
  background: #4dc4ff;
}

.label-success {
  background: #82e27d;
}

.label-warning {
  background: #e7ba08;
}

.label-danger {
  background: #f95372;
}

.form-horizontal {
  padding: 0px 15px;
  width: 100%;
}
.form-horizontal label {
  line-height: 34px;
  margin-bottom: 0;
  padding-top: 0 !important;
}

.form-group label {
  margin-bottom: 5px;
  color: #212121;
}

.form-control {
  color: #212121;
  /*border: 1px solid $input-border;*/
  /*border-radius: 5px;*/
  /*background-color: $default;*/
  /*box-shadow: none;*/
}
.form-control::-webkit-input-placeholder {
  color: #212121;
  opacity: 0.7;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #212121;
  opacity: 0.7;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #212121;
  opacity: 0.7;
}
.form-control:-ms-input-placeholder {
  color: #212121;
  opacity: 0.7;
}
.form-control:focus {
  /*color: $content-text;
      box-shadow: none;*/
  /*border-color: $primary-bg;*/
  /*background: $input-background;*/
}

select.form-control {
  padding-left: 8px;
}

select.form-control:not([multiple]) option {
  color: #7d7d7d;
}

select.form-control[multiple] option {
  color: #212121;
}

textarea {
  width: 100%;
}

.form-inline .form-group input {
  width: 100%;
}
.form-inline .form-group label {
  margin-right: 12px;
}
.form-inline button[type=submit] {
  margin-left: 12px;
}

textarea {
  border: 1px solid #475258;
}

.switch-container {
  display: inline-block;
}
.switch-container.primary .bootstrap-switch.bootstrap-switch-on {
  border-color: #00abff;
}
.switch-container.success .bootstrap-switch.bootstrap-switch-on {
  border-color: #82e27d;
}
.switch-container.warning .bootstrap-switch.bootstrap-switch-on {
  border-color: #e7ba08;
}
.switch-container.danger .bootstrap-switch.bootstrap-switch-on {
  border-color: #f95372;
}
.switch-container.info .bootstrap-switch.bootstrap-switch-on {
  border-color: #4dc4ff;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}
.input-group > span {
  border-radius: 0;
}

.nowrap {
  white-space: nowrap;
}

.cut-with-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

label.custom-input-primary > span:before {
  color: #00abff;
}
label.custom-input-primary > span:hover:before {
  border-color: #00abff;
}

label.custom-input-success > span:before {
  color: #82e27d;
}
label.custom-input-success > span:hover:before {
  border-color: #82e27d;
}

label.custom-input-warning > span:before {
  color: #e7ba08;
}
label.custom-input-warning > span:hover:before {
  border-color: #e7ba08;
}

label.custom-input-danger > span:before {
  color: #f95372;
}
label.custom-input-danger > span:hover:before {
  border-color: #f95372;
}

.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 0;
}

.input-demo {
  line-height: 25px;
}

td.col-button {
  text-align: center;
}

.input-group-addon {
  line-height: inherit;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}
.has-feedback label ~ .form-control-feedback {
  top: 19px;
}

.bootstrap-select .btn-default:focus {
  color: #212121;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
  border-color: rgba(255, 255, 255, 0.49);
}
.form-control[disabled]::-webkit-input-placeholder,
.form-control[readonly]::-webkit-input-placeholder,
fieldset[disabled] .form-control::-webkit-input-placeholder {
  color: #212121;
  opacity: 0.5;
}
.form-control[disabled]:-moz-placeholder,
.form-control[readonly]:-moz-placeholder,
fieldset[disabled] .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #212121;
  opacity: 0.5;
}
.form-control[disabled]::-moz-placeholder,
.form-control[readonly]::-moz-placeholder,
fieldset[disabled] .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #212121;
  opacity: 0.5;
}
.form-control[disabled]:-ms-input-placeholder,
.form-control[readonly]:-ms-input-placeholder,
fieldset[disabled] .form-control:-ms-input-placeholder {
  color: #212121;
  opacity: 0.5;
}

.form-control-rounded {
  border-radius: 16px;
}

.help-block {
  color: #212121;
  vertical-align: sub;
}

.help-block.error-block {
  display: none;
}
.has-error .help-block.error-block.basic-block {
  display: block;
}

.input-group-addon-danger {
  background: #f95372;
  color: #212121;
  border-color: #f95372;
}

.input-group-addon-warning {
  background: #e7ba08;
  color: #212121;
  border-color: #e7ba08;
}

.input-group-addon-success {
  background: #82e27d;
  color: #212121;
  border-color: #82e27d;
}

.input-group-addon-primary {
  background: #00abff;
  color: #212121;
  border-color: #00abff;
}

.checkbox-demo-row {
  margin-bottom: 12px;
}

.dropdown-menu {
  border-radius: 5px;
}

.dropdown button.btn.btn-default.dropdown-toggle {
  color: #212121;
}

.has-success {
  position: relative;
}
.has-success .control-label {
  color: #212121;
}
.has-success .form-control {
  border: 1px solid #9be897;
}
.has-success .form-control:focus {
  box-shadow: none;
  border-color: #82e27d;
}
.has-success label.custom-checkbox {
  color: #9be897;
}
.has-success label.custom-checkbox > span:before {
  color: #9be897;
}
.has-success label.custom-checkbox > span:hover:before {
  border-color: #9be897;
}
.has-success .form-control-feedback {
  color: #9be897;
}
.has-success .input-group-addon {
  background-color: #9be897;
  color: #212121;
}

.has-warning {
  position: relative;
}
.has-warning .control-label {
  color: #212121;
}
.has-warning .form-control {
  border: 1px solid #ecc839;
}
.has-warning .form-control:focus {
  box-shadow: none;
  border-color: #e7ba08;
}
.has-warning label.custom-checkbox {
  color: #ecc839;
}
.has-warning label.custom-checkbox > span:before {
  color: #ecc839;
}
.has-warning label.custom-checkbox > span:hover:before {
  border-color: #ecc839;
}
.has-warning .form-control-feedback {
  color: #ecc839;
}
.has-warning .input-group-addon {
  background-color: #ecc839;
  color: #212121;
}

.has-error {
  position: relative;
}
.has-error .control-label {
  color: #212121;
}
.has-error .form-control {
  border: 1px solid #fa758e;
}
.has-error .form-control:focus {
  box-shadow: none;
  border-color: #f95372;
}
.has-error label.custom-checkbox {
  color: #fa758e;
}
.has-error label.custom-checkbox > span:before {
  color: #fa758e;
}
.has-error label.custom-checkbox > span:hover:before {
  border-color: #fa758e;
}
.has-error .form-control-feedback {
  color: #fa758e;
}
.has-error .input-group-addon {
  background-color: #fa758e;
  color: #212121;
}

.bootstrap-tagsinput {
  color: #212121;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  box-shadow: none;
  max-width: 100%;
  line-height: 26px;
  width: 100%;
}
.bootstrap-tagsinput.form-control {
  display: block;
  width: 100%;
}
.bootstrap-tagsinput .tag {
  border-radius: 3px;
  padding: 4px 8px;
}
.bootstrap-tagsinput .tag [data-role=remove]:hover {
  box-shadow: none;
}
.bootstrap-tagsinput input {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  line-height: 22px;
  min-width: 53px;
}
.bootstrap-tagsinput input::-webkit-input-placeholder {
  color: #212121;
  opacity: 0.8;
}
.bootstrap-tagsinput input:-moz-placeholder {
  /* Firefox 18- */
  color: #212121;
  opacity: 0.8;
}
.bootstrap-tagsinput input::-moz-placeholder {
  /* Firefox 19+ */
  color: #212121;
  opacity: 0.8;
}
.bootstrap-tagsinput input:-ms-input-placeholder {
  color: #212121;
  opacity: 0.8;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #02b5f7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.has-success .input-group-addon {
  border: none;
}

.input-group > span.addon-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-group > span.addon-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  margin-left: 0;
}
.input-group-btn > .btn {
  line-height: 1.56;
}

.with-primary-addon:focus {
  border-color: #00abff;
}

.with-warning-addon:focus {
  border-color: #e7ba08;
}

.with-success-addon:focus {
  border-color: #82e27d;
}

.with-danger-addon:focus {
  border-color: #f95372;
}

rating-inputs span {
  vertical-align: middle;
}

.col-icon {
  width: 38px;
  text-align: center;
}

.icon-header {
  text-align: left;
}

.dialog-center > .ui-dialog {
  width: 95%;
  top: 5% !important;
  margin: 1% 3%;
  left: 0 !important;
  height: 90% !important;
}

.ui-dialog .ui-dialog-content {
  height: 90%;
}

.chart {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 90px;
  text-align: center;
}

.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 90px;
  z-index: 2;
  color: #283e4a;
}

.percent:after {
  content: "%";
  margin-left: 0.1em;
}

.modal-content {
  background-color: #fff;
}

.ui-dropdown {
  width: 100% !important;
}

.empty-form-control {
  background: transparent;
  border: 0;
  width: 100%;
  outline: none;
}

input:focus,
.ui-dropdown:focus {
  box-shadow: 0px 0px 5px #1f89ce !important;
}

.custom-form-group .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 5px 10px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}
.custom-form-group .ui-calendar {
  margin: 0 !important;
}
.custom-form-group .ui-calendar.ui-widget-header .ui-state-default,
.custom-form-group .ui-calendar > .ui-state-default {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 5px 10px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}
.custom-form-group label.ui-chkbox-label {
  margin-bottom: 0.1rem;
  padding-left: 0.2rem;
}

.size-18 {
  font-size: 18px !important;
}

.al-main {
  font-size: 14px;
}

.ui-helper-reset {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Segoe UI light", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

html,
body {
  background-color: #eef0f5;
  font-size: 14px;
  font-family: "Segoe UI light", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: #ababab;
}

a {
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

.windows body,
.windows p {
  letter-spacing: normal;
}

.upper-case {
  text-transform: uppercase;
}

span.logo-title {
  color: #fff;
}

.text-right {
  text-align: right;
}

.col-center {
  text-align: center !important;
}

.ui-dropdown {
  width: 100% !important;
  min-width: 0 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.3em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0em solid transparent;
  border-left: 0em solid transparent;
}

.search-result {
  position: fixed;
  left: 50px;
  z-index: 1000;
  top: 135px;
  width: 300px;
  padding: 20px 5px 10px 15px;
}
.search-result .row {
  margin: 0 -5px;
  cursor: pointer;
}
.search-result .col-sm-12 {
  border-bottom: 0 !important;
  padding: 0 10px !important;
}
small {
  font-size: 90%;
}

.without-padding {
  padding: 0 !important;
}

.shortcut-notification {
  margin: 0 0 0 0;
  width: 60px;
  position: fixed;
  top: 100px;
  overflow-y: auto;
  min-height: 0;
  z-index: 1001 !important;
  overflow-x: hidden;
  margin-left: 0px;
  border-right: 0;
  background: transparent;
  z-index: 1002;
  left: 0px;
  overflow: hidden;
}
.shortcut-notification.home-notification {
  top: 280px;
}
.shortcut-notification ul {
  padding: 10px 1px;
  margin: 0 0 0px;
  border-bottom: 0;
}
.shortcut-notification li {
  display: block;
  width: 100%;
  height: 35px;
  border-radius: 0;
  text-align: center;
  line-height: 35px;
  background-color: transparent;
  margin-right: 0;
  color: #1976d2;
  vertical-align: top;
  cursor: pointer;
  transition: background-color;
  transition-duration: 250ms;
  margin: 0;
  text-align: center;
}
.shortcut-notification li:hover {
  color: gray;
  background-color: #fff;
}
.shortcut-notification li a {
  color: #1976d2;
}
.shortcut-notification li a:hover {
  color: gray;
}

.welcome-card {
  width: 700px;
}

.menu-collapsed .welcome-item {
  padding: 20px 0;
}
.menu-collapsed .menu-collapsed-p-0 .ui-tabview-nav li {
  padding: 0px 5% !important;
}
.menu-collapsed .row-content {
  padding: 5px 0px 0 30px;
}
.menu-collapsed .user-Profile {
  display: none !important;
}
.menu-collapsed .welcome-card {
  width: 865px;
}
.menu-collapsed #patient-picture {
  left: 45px;
}
.menu-collapsed .chart-details.active {
  right: auto !important;
  left: -66px;
}
.menu-collapsed #admission-charts-details.active {
  width: calc(100% - 60px);
}
.menu-collapsed .arow-item {
  display: none !important;
}
.menu-collapsed #menus .collapsed {
  display: none;
}
.menu-collapsed .menu-panel-title a i {
  margin-right: 15px !important;
}

.row-content {
  padding: 5px 0px 0 30px;
}

.row-content {
  padding: 5px 0px 0 100px;
}

.shortcut-notification-modal .modal-body .row {
  padding: 0px 25px;
}
.shortcut-notification-modal .modal-dialog {
  margin: 0 0;
  height: 100%;
  width: 20%;
}
.shortcut-notification-modal .modal-header {
  padding: 15px 0 0 0;
}
.shortcut-notification-modal .modal-header .close:focus {
  outline: 0;
}
.shortcut-notification-modal .modal-content {
  border-radius: 0;
  height: 100%;
}
.shortcut-notification-modal .form-control {
  border-bottom: 1px solid lightgray;
  border-radius: 0;
}
.shortcut-notification-modal .form-control:focus {
  border-color: transparent;
  border-bottom: 1px solid #4791cd;
}
.shortcut-notification-modal .ui-inputtext {
  width: 100%;
  border-bottom: 1px solid lightgray;
  border-radius: 0px;
  padding: 10px 0px 7px 15px !important;
}
.shortcut-notification-modal .ui-inputtext:focus {
  -moz-box-shadow: 0px 0px 0px #1f89ce;
  -webkit-box-shadow: 0px 0px 0px #1f89ce;
  box-shadow: 0px 0px 0px #1f89ce;
  border-bottom: 1px solid #4791cd;
}
.shortcut-notification-modal input.undefined.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  margin: 5px 0 0 0 !important;
}
.shortcut-notification-modal .ui-datepicker.ui-widget {
  padding: 0;
  width: 100%;
}
.shortcut-notification-modal .ui-calendar {
  width: 100%;
}

.ui-calendar {
  width: 100%;
  margin: 10px 0 0 0 !important;
}

.ui-inputtext {
  width: 100% !important;
}

/* accordion */
.modal.in .modal-dialog {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0);
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5 !important;
}

.modal-footer {
  border-top: 1px solid #e5e5e5 !important;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
  width: 100%;
  padding: 0;
  font-size: 14px;
}

.modal-body {
  box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0);
  will-change: box-shadow;
  display: block;
  position: relative;
  padding: 10px 15px;
  border-radius: 2px;
}

/* accordion */
/* button */
.btn-icon {
  border-radius: 50%;
  width: 20px;
  line-height: 20px;
  height: 20px;
  padding: 0;
  text-align: center;
  color: black;
  background-color: transparent;
}

/* button */
/* Form Layout */
.form-group-default {
  background-color: transparent;
  position: relative;
  padding-top: 7px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
}
.form-group-default .ui-autocomplete {
  width: 100% !important;
}

.form-group.row.form-group-default .form-control {
  height: 35px;
  margin-top: 0;
}

.form-group {
  margin-bottom: 0;
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.form-horizontal .form-group-default {
  background: inherit;
  border: none !important;
  overflow: inherit;
}
.form-horizontal .form-group.row.form-group-default {
  padding-top: 0px;
}
.form-horizontal .form-group.row.form-group-default label {
  border: none !important;
}
.form-horizontal .form-group.row.form-group-default .switch-small {
  margin-top: 5px;
}
.form-horizontal .form-group.row.form-group-default input {
  padding: 0px 10px;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
  background-color: white;
  border-radius: 0px;
  height: 35px;
  border: 1px solid #d4d4d4;
}
.form-horizontal .form-group.row.form-group-default .col-lg-3,
.form-horizontal .form-group.row.form-group-default .col-md-6,
.form-horizontal .form-group.row.form-group-default .col-sm-12 {
  padding: 5px 10px 5px 10px;
}
.form-horizontal .form-control[disabled],
.form-horizontal fieldset[disabled] .form-control {
  background-color: #e1e1e1 !important;
}
.form-horizontal p-calendar {
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}
.form-horizontal .ui-datepicker {
  position: absolute !important;
}
.form-horizontal .ui-calendar {
  margin: 0 !important;
}
.form-horizontal .ui-dropdown {
  width: 100% !important;
}
.form-horizontal .ui-dropdown-panel {
  position: absolute !important;
}
.form-horizontal .ui-dropdown-label {
  margin-bottom: 0px !important;
  padding: 0px 10px;
  height: 35px;
}
.form-horizontal .ui-dropdown.ui-state-focus,
.form-horizontal .ui-multiselect.ui-state-focus {
  box-shadow: none !important;
}
.form-horizontal .ui-dropdown .ui-dropdown-trigger .fa {
  margin-top: 8px;
}
.form-horizontal .ui-dropdown .ui-state-default {
  color: #409ace;
}
.form-horizontal .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border-radius: 0px;
  box-shadow: 0px 0px 5px 0px #d4d4d4;
}
.form-horizontal .ui-dropdown-label.ui-placeholder {
  color: #959595 !important;
}
.form-horizontal .ui-dropdown.ui-state-disabled,
.form-horizontal .ui-dropdown.ui-state-disabled .ui-dropdown-label,
.form-horizontal .ui-dropdown.ui-state-disabled .ui-corner-right {
  background-color: #e1e1e1 !important;
  opacity: 1;
  cursor: not-allowed !important;
  color: #8e8e8e !important;
}
.form-horizontal .ui-multiselect-panel {
  position: absolute !important;
}
.form-horizontal .ui-multiselect-label {
  margin-bottom: 0px !important;
  padding: 0px 10px;
  height: 35px;
}
.form-horizontal .ui-multiselect.ui-state-focus,
.form-horizontal .ui-multiselect.ui-state-focus {
  box-shadow: none !important;
}
.form-horizontal .ui-multiselect .ui-multiselect-trigger .fa {
  margin-top: 8px;
}
.form-horizontal .ui-multiselect .ui-state-default {
  color: #409ace;
}
.form-horizontal .ui-multiselect.ui-widget.ui-state-default.ui-corner-all {
  width: 100%;
}
.form-horizontal .ui-multiselect.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border-radius: 0px;
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}
.form-horizontal .ui-multiselect-label.ui-placeholder {
  color: #959595 !important;
}
.form-horizontal .ui-multiselect.ui-state-disabled,
.form-horizontal .ui-multiselect.ui-state-disabled .ui-multiselect-label,
.form-horizontal .ui-multiselect.ui-state-disabled .ui-corner-right {
  background-color: #e1e1e1 !important;
  opacity: 1;
  cursor: not-allowed !important;
  color: #8e8e8e !important;
}
.form-horizontal .ui-corner-all {
  border-radius: 0px !important;
}
.form-horizontal textarea {
  -webkit-box-shadow: none !important;
}
.form-horizontal input:-webkit-autofill,
.form-horizontal input:-webkit-autofill:hover,
.form-horizontal input:-webkit-autofill:focus,
.form-horizontal textarea:-webkit-autofill,
.form-horizontal textarea:-webkit-autofill:hover,
.form-horizontal textarea:-webkit-autofill:focus,
.form-horizontal select:-webkit-autofill,
.form-horizontal select:-webkit-autofill:hover,
.form-horizontal select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #ababab !important;
  background-color: #ffffff !important;
  background-image: none !important;
  color: #ababab !important;
}
.form-horizontal textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  box-shadow: 0px 0px 5px 0px #e1e1e1;
}

.has-error input {
  border: 0 !important;
  border-bottom: 1px solid #e52222 !important;
}
.has-error p-calendar .ui-inputtext {
  border: 0 !important;
  border-bottom: 1px solid #e52222 !important;
}
.has-error p-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 0 !important;
  border-bottom: 1px solid #e52222 !important;
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  background-color: #4b4b4b !important;
}

form {
  display: block;
  margin-top: 0em;
}

.m-t-10 {
  margin-top: 10px;
}

p {
  display: block;
  letter-spacing: 0.01em;
  line-height: 22px;
  margin: 0px 0px 10px 0px;
  font-style: normal;
  white-space: normal;
}

.input-group-addon {
  background: #f0f0f0;
  border: 1px solid rgba(0, 0, 0, 0.07);
  color: rgba(98, 98, 98, 0.47);
  padding: 6px 9px;
  display: table-cell;
  border-radius: 3px;
  transition: border 0.2s linear 0s, box-shadow 0.2s linear 0s, color 0.2s linear 0s, box-shadow 0.2s linear 0s, background 0.2s linear 0s;
}

.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.input-right {
  float: right;
  direction: rtl;
}

.input-group-addon:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.07);
  border-left: 0;
  -webkit-border-top-right-radius: 2px;
  -moz-border-radius-topright: 2px;
  border-top-right-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-right-radius: 2px;
}

.windows h1,
.windows h2,
.windows h3,
.windows h4,
.windows h5 {
  letter-spacing: normal;
}

.windows h3 {
  line-height: 33px;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

input[type=radio],
input[type=checkbox] {
  margin-top: 1px 0 0;
  line-height: normal;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox label:before {
  top: 1.4px;
  border-radius: 3px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}

.checkbox.check-success input[type=checkbox]:checked + label:before {
  border-color: #10cfbd;
}

.checkbox input[type=checkbox]:checked + label:before {
  border-width: 8.5px;
}

.checkbox.check-success input[type=checkbox]:checked + label::after,
.checkbox.check-primary input[type=checkbox]:checked + label::after,
.checkbox.check-complete input[type=checkbox]:checked + label::after,
.checkbox.check-warning input[type=checkbox]:checked + label::after,
.checkbox.check-danger input[type=checkbox]:checked + label::after,
.checkbox.check-info input[type=checkbox]:checked + label::after {
  color: #ffffff;
}

.checkbox input[type=checkbox]:checked + label::after {
  content: "";
  color: #fff;
}

.checkbox label:after {
  border-radius: 3px;
}

.checkbox label::after {
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 3.2px;
  top: 0px;
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
}

.radio label:before,
.checkbox label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  position: absolute;
  left: 0px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
}

.checkbox input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkbox label {
  transition: border 0.2s linear 0s, color 0.2s linear 0s;
  white-space: nowrap;
}

.radio label,
.checkbox label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 25px !important;
  margin-right: 15px;
}

textarea.form-control {
  min-height: 96px !important;
}

textarea.ui-inputtext.ui-corner-all.ui-state-default.ui-widget {
  border: 1px solid #d6d6d6;
}

/* Form Layout */
/* Primeng */
.ui-widget-header .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-state-default {
  border: 0px solid #d6d6d6;
}

.ui-widget-content {
  border: 0px;
}

/*.ui-datepicker {
    position: static;
}*/
.ui-calendar button {
  height: 24px !important;
}

.ui-dropdown-panel .ui-dropdown-filter-container {
  width: 100%;
}

.ui-dropdown:not(.ui-state-disabled):hover {
  background: #ffffff !important;
}

/*.ui-dropdown-panel {
    position: static !important;
}*/
.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):hover {
  border-color: #ffffff !important;
}

/*.ui-autocomplete-panel {
    position: static !important;
}*/
/* Primeng */
/* Tables */
.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td {
  background: #f6f6f6;
}

.ui-datatable .ui-datatable-thead > tr > th,
.ui-datatable .ui-datatable-tfoot > tr > td,
.ui-datatable .ui-datatable-data > tr > td {
  border: 1px solid #ddd;
}

.ui-datatable .ui-paginator {
  padding: 0.325em;
  border-top: 0 none;
}
.ui-datatable .ui-paginator a.ui-paginator-first.ui-paginator-element.ui-state-default.ui-corner-all {
  border: 0;
  background: transparent;
}

.ui-paginator .ui-paginator-page.ui-state-active {
  background: #2399e5;
  color: #ffffff;
  border-color: #2399e5;
}

.ui-datatable .ui-paginator .ui-paginator-page {
  width: 3em !important;
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
  background: #fafafb;
  border-color: #d9d9d9;
}

.ui-datatable-header.ui-widget-header {
  color: #586069;
  background: #dddddd !important;
  border: 1px solid #d5d8dc;
  border-radius: 3px 3px 0px 0px;
}

.ui-widget-header {
  background: linear-gradient(to bottom, transparent 0, transparent 0);
}

.ui-lightbox-caption.ui-widget-header {
  background: #fff;
}

.ui-paginator-bottom.ui-paginator.ui-widget.ui-widget-header.ui-unselectable-text {
  color: #586069;
  background-color: #ffffff;
  border: 1px solid #c8e1ff;
  border-top: 0px !important;
}

.ui-datatable th.ui-state-active {
  color: black;
}

.ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd {
  background-color: #ffffff;
}
.ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd:hover {
  background-color: #e9e9e9;
}

.ui-datatable tbody > tr.ui-widget-content:hover {
  background-color: #e9e9e9;
}

.ui-dropdown-trigger.ui-state-default.ui-corner-right {
  border: 0;
  border: 0 !important;
}
.ui-dropdown-trigger.ui-state-default.ui-corner-right:hover {
  background-color: #ddd;
}

.ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 1px solid #d4d4d4;
}

.ui-datatable tfoot td.ui-state-default {
  background: #f1f8ff;
  border-color: #d9d9d9;
}

.col-button {
  width: 10%;
  text-align: center;
}

.ui-slider .ui-slider-handle {
  background: url("/app/system/slider_handles.png") 0 0 no-repeat !important;
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight {
  background-color: #186ba0 !important;
  color: #ffffff !important;
}

/* Tables */
/* Table Group */
/* Table Group */
/*picture Uploader*/
.picture-group {
  border: 1px dashed #b8b8b8;
  width: 202px;
  height: 202px;
  position: relative;
  cursor: pointer;
}
.picture-group .picture-wrapper {
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture-group img {
  max-width: 100%;
  max-height: 100%;
}
.picture-group i {
  display: none;
  position: absolute;
  background: #f4f4f4;
  cursor: pointer;
  color: #00abff;
  top: -11px;
  right: -11px;
  height: 26px;
  border-radius: 50%;
}
.picture-group i:before {
  line-height: 26px;
}
.picture-group i:hover {
  color: #f95372;
}
.picture-group a.change-picture {
  display: none;
  width: 202px;
  background: rgba(0, 0, 0, 0.7);
  transition: all 200ms ease-in-out;
  color: #fff;
  text-decoration: none;
  position: absolute;
  bottom: -1px;
  left: -1px;
  line-height: 32px;
  text-align: center;
}
.picture-group:hover i {
  display: block;
}
.picture-group:hover .change-picture {
  display: block;
}
.picture-group .loading {
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}
.picture-group .spinner {
  width: 60px;
  height: 60px;
  position: relative;
}
.picture-group .double-bounce1,
.picture-group .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.picture-group .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*picture Uploader*/
/* Panel Header */
.form-group.row.form-group-default.row-header {
  background: #eeeeee;
}

.row-header .ui-panel-content-wrapper {
  background: #ffffff;
}
.row-header .ui-panel .ui-panel-content {
  border: 0;
  background: none;
  padding: 1em 0.75em;
}
.row-header .ui-panel-titlebar.ui-widget-header.ui-helper-clearfix.ui-corner-all {
  background: #ffffff;
}
.second-row-header .form-horizontal label {
  line-height: 14px;
}

.row-image-header {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
}

/* Panel Header */
/* checkbox */
.ui-chkbox .ui-chkbox-box {
  border: 1px solid #d6d6d6;
  height: 1.225em;
}

/* checkbox */
/* tree */
label.tree-label {
  padding-top: 5px;
  white-space: initial;
}

.tree-row {
  vertical-align: middle;
  align-content: stretch;
}

/* tree */
/* Radio Button */
.ui-radiobutton-box.ui-widget.ui-state-default {
  border: 2px solid #d6d6d6;
}

.ui-radiobutton-box {
  line-height: 0.875em;
}

/* Radio Button */
.red-border {
  border-right: 3px solid red;
}

.green-border {
  border-right: 3px solid darkgreen;
}

.blue-border {
  border-right: 3px solid blue;
}

/*Toggle Button */
.fixed-action-btn.horizontal {
  padding: 0 0 0 15px;
}

.fixed-action-btn {
  bottom: 0;
  padding-top: 15px;
  margin-bottom: 0;
  z-index: 998;
  position: absolute;
  display: inline-block;
  right: 24px;
}

.red {
  background-color: #f44336 !important;
}

.btn-large {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: 0.5px;
  transition: 0.2s ease-out;
  cursor: pointer;
}

.btn-floating {
  display: inline-block;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #26a69a;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}

.fixed-action-btn.active ul {
  visibility: visible;
}

.fixed-action-btn ul {
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: 64px;
  margin: 0;
  visibility: hidden;
}

.fixed-action-btn.horizontal ul {
  text-align: right;
  right: 64px;
  top: 60px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 150px;
  left: auto;
  width: 600px;
  background-color: #f4f4f4;
}

.fixed-action-btn.horizontal ul li {
  display: inline-block;
  margin: 15px 15px 0 0;
}

.transparent {
  background-color: transparent;
}

.system-color.text {
  color: #4791cd;
}
.system-color.text:hover {
  color: #03a9f4 !important;
}

.white.text {
  color: #fff !important;
}
.white.text:hover {
  color: #fff !important;
}

.light-blue.bg {
  background-color: #03a9f4;
}

.orange-400.bg {
  background-color: #ffa726;
}

.blue-grey.bg {
  background-color: #607d8b;
}

.red-400.bg {
  background-color: #ef5350;
}

.green-400.bg {
  background-color: #66bb6a;
}

.purple-300.bg {
  background-color: #ba68c8;
}

/* Toggle Button */
/* tooltip */
.test-tooltip {
  background-color: red !important;
  color: black !important;
}

/* tooltip */
/* card */
@media screen and (min-width: 1620px) {
  .row.shift-up > * {
    margin-top: -573px !important;
  }
}
@media screen and (max-width: 1620px) {
  .card.feed-panel.large-card {
    height: 824px !important;
  }
}
.user-stats-card .card-title {
  padding: 0 0 15px !important;
}

.blurCalendar {
  height: 360px !important;
}

.col-xl-2.col-lg-6.hvr-bounce-in.main-card {
  padding: 0;
}

.card.medication-card {
  color: black !important;
}

.card {
  position: relative !important;
  display: block !important;
  margin-bottom: 0rem !important;
  background-color: #fff !important;
  border: 1px solid #d7e0f1 !important;
  border-radius: 0 !important;
  min-height: 110px;
}
.card:not(.text-dark) {
  color: #f4f4f4 !important;
}
.card.card-static-height {
  min-height: 460px;
  max-height: 460px;
}
.card .card-header {
  padding: 0.625rem 0.875rem !important;
  background-color: rgba(51, 122, 183, 0.82) !important;
  border-bottom: 1px solid rgba(51, 122, 183, 0.82) !important;
  height: auto !important;
  color: #f4f4f4 !important;
  opacity: 0.8;
}
.card .card-header:hover {
  color: rgba(51, 122, 183, 0.82) !important;
  background: #f4f4f4 !important;
}
.card .card-icon {
  opacity: 1;
}
.card .title {
  padding: 5px 0;
}

/* card */
p-panel {
  width: 100%;
}

/* Calnedar */
.fc-header.fc-widget-header .fc-title {
  color: black !important;
}

.fc-content .fc-time {
  color: #fff;
}

/*.fc-scroller.fc-day-grid-container {
    height: 100% !important;
}*/
.fc-title {
  color: #fff !important;
}

.fc-day-grid-event .fc-time {
  color: #fff !important;
}

a.fc-more {
  margin: 1px 3px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  color: red !important;
}

.fc-basic-view .fc-body .fc-row {
  height: 100% !important;
}

/* Calnedar */
button.close {
  padding: 0 15px;
}

.shortuct-item {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: transparent;
  color: #6a6c6f !important;
  border: 0;
  border-bottom: 1px solid #ddd;
}
.shortuct-item:hover {
  background-color: transparent;
  text-decoration: underline;
  color: #5faee3 !important;
}
.shortuct-item:focus {
  outline: 0 !important;
}

.close-modal {
  width: 100%;
  margin: 15px 0 0 0;
  color: #fff;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
}

/* Action Button */
.item-container {
  right: 20px;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #4791cd;
  border-radius: 90px;
  transition: width 1s, right 1s;
  overflow: hidden;
  /*box-shadow: 0px 5px 18px -2px rgba(0,0,0,0.7);*/
  float: right;
}
.item-container:hover {
  width: 600px;
  right: 0px;
}

.item {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 90px;
  margin-right: 22.5px;
  z-index: 1;
  float: left;
  background-color: rgba(103, 113, 110, 0);
}

.highlight {
  position: absolute;
  width: 0;
  height: 0;
  margin: 45px;
  border-radius: 90px;
  transition: 0.1s;
  top: 0;
  left: 0;
}

.nav {
  position: relative;
  color: #2e3936;
  top: 45px;
  left: 45px;
  transition: font 0s;
  z-index: 3;
  color: #fff;
}

.anchor {
  top: 0;
  right: 0;
  z-index: 4;
  position: absolute;
  color: #fff;
  transition: transform 0.2s;
  transition-delay: 0.2s;
  width: 40px;
  height: 40px;
}

.anchor i {
  margin-left: 0px;
  margin-top: 10px;
}

.item-container:hover .anchor {
  transform: rotate(45deg);
}

.item-container:hover .nav {
  transition: 0.1s;
  top: 12px;
  left: 0;
}

.item:hover {
  background-color: #ffffff;
  color: black;
  transition: 0.2s;
}
.item:hover .item-action {
  color: #4791cd;
}

.item:active .highlight {
  z-index: 2;
  margin: 0;
  width: 40px;
  height: 40px;
}

/* Action Button */
/* pick list*/
.ui-picklist-filter-container.ui-widget-content {
  border: 1px solid #d5d5d5;
}

ul.ui-widget-content.ui-picklist-list.ui-picklist-source.ui-corner-bottom,
ul.ui-widget-content.ui-picklist-list.ui-picklist-target.ui-corner-bottom {
  border: 1px solid #d5d5d5;
}

.picklist-row {
  margin: 15px 5px;
}

/* pick list*/
.gray-background .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.gray-background .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.gray-background .ui-panel-titlebar {
  background-color: #9e9e9e !important;
  color: #fff !important;
}

.gray-background .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.gray-background .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.gray-background .ui-panel-titlebar {
  background-color: #9e9e9e !important;
  color: #fff !important;
}

.light-blue-background .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.light-blue-background .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.light-blue-background .ui-panel-titlebar {
  background-color: #477f7e !important;
  color: #fff !important;
}

.orange-background .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.orange-background .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.orange-background .ui-panel-titlebar {
  background-color: #e9af15 !important;
  color: black !important;
}

.green-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.green-background-item .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.green-background-item .ui-panel-titlebar {
  background: #87ce4b !important;
  color: #fff !important;
}
.green-background-item .header-title {
  background-color: #54b050;
  color: #fff;
  padding: 5px;
}
.green-background-item .button-color {
  background-color: #54b050;
  border: #54b050;
}

.blue-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.blue-background-item .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.blue-background-item .ui-panel-titlebar {
  background: #64c5f9 !important;
  color: #fff !important;
}
.blue-background-item .header-title {
  background-color: #4991ce;
  color: #fff;
  padding: 5px;
}
.blue-background-item .button-color {
  background-color: #4991ce;
  border: 1px solid #4991ce;
}

.gray-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.gray-background-item .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.gray-background-item .ui-panel-titlebar {
  background: #898887 !important;
  color: #fff !important;
}
.gray-background-item .header-title {
  background-color: #f6f5f5;
  color: #d2d2d2;
  padding: 5px;
}

.orange-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}
.orange-background-item .ui-panel {
  border: 1px solid #d5d5d5 !important;
}
.orange-background-item .ui-panel-titlebar {
  background: #f1b217 !important;
  color: #fff !important;
}
.orange-background-item .header-title {
  background-color: #eb8327;
  color: #fff;
  padding: 5px;
}
.orange-background-item .button-color {
  background-color: #f1b217;
  border: 1px solid #f1b217;
}

.info-details {
  position: fixed;
  min-height: 100%;
  background: #fff;
  color: #fff;
  z-index: 1040;
  left: 0;
  top: 50px;
  width: 350px;
  transition: visibility 0.1s, width 0.1s linear;
}

.info-collapse {
  width: 0 !important;
  transition: visibility 0.1s, width 0.1s linear;
}
.info-collapse .hide-info {
  visibility: hidden;
  transition: visibility 0.1s, width 0.1s linear;
}
.info-collapse .info-title {
  visibility: hidden;
  transition: visibility 0.1s, width 0.1s linear;
}
.info-collapse .info-action {
  visibility: hidden;
  transition: visibility 0.1s, width 0.1s linear;
}
.info-collapse .info-action h3 {
  visibility: hidden;
}
.info-collapse .info-action .list-group.vertical-steps {
  visibility: hidden;
  width: 0;
  transition: visibility 0.1s, width 0.1s linear;
}

.info-action {
  color: black;
  margin: 0;
}
.info-action .col-sm-12 {
  border-bottom: 1px solid black;
  padding: 10px 0px;
  margin: 0;
  cursor: pointer;
}

.hide-info {
  padding: 10px 0 0 0;
  margin: 0 -20px;
  border-radius: 50%;
}
.hide-info i {
  color: #757575;
}
.hide-info .button-menu-toggle {
  border: 0;
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.hide-info .button-menu-toggle:hover {
  background-color: #e0e0e0;
}

.info-title {
  padding: 20px 0 0 0;
  color: black;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  color: rgba(102, 92, 92, 0.8) !important;
}

.notification-image {
  width: 15px;
}

.ui-steps .ui-steps-item {
  width: 25%;
}

.ui-steps.steps-custom {
  margin-bottom: 30px;
}

.ui-steps.steps-custom .ui-steps-item .ui-menuitem-link {
  height: 10px;
  padding: 0;
  background: #1976d2;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-number {
  background-color: #ffc107;
  color: transparent;
  display: inline-block;
  border-radius: 50%;
  margin-top: -5px;
  margin-bottom: 0px;
  height: 20px;
  width: 20px;
}

.ui-steps.steps-custom .ui-steps-item .ui-steps-title {
  color: #555555;
}

.list-group-item {
  cursor: pointer;
}

.red-label label {
  margin: 0 !important;
  color: #f6675d !important;
}

.yellow-label label {
  margin: 0 !important;
  color: #e6dc07 !important;
}

.green-label label {
  margin: 0 !important;
  color: #67bd6a !important;
}

.light-gray-label label {
  margin: 0 !important;
  color: #b6d8e8 !important;
}

.dark-red-label label {
  margin: 0 !important;
  color: #bb4a42 !important;
}

.ui-radiobutton-icon {
  margin-top: 0px !important;
  line-height: 0 !important;
}

.finger-print {
  border: 0;
  padding: 10px;
  width: 280px;
}

.dynamic-row .ui-inputtext {
  border: 1px solid #ddd !important;
}

.ui-fieldset-content .row {
  border: 1px solid #ddd !important;
}

img.ui-panel-images {
  width: 100% !important;
}

.ui-galleria-filmstrip-wrapper {
  width: 450px !important;
  height: 40px !important;
}

.ui-galleria-caption {
  top: 0 !important;
  bottom: auto !important;
  width: 100% !important;
}

/*.visit-admissions {
    position: absolute;
    width: 300px;
    left: 0;
    height: calc(100vh - 100px);
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;

    .header {
        margin: 0;
        border-bottom: 1px solid #ddd;
        padding: 10px 0;

        a {
            cursor: pointer;

            .internal-row {
                .ui-calendar {
                    margin: 0 !important;
                }

                .ui-widget-header .ui-state-default, .ui-widget-content .ui-state-default, .ui-state-default {
                    border: 1px solid #ddd !important;
                }
            }

            &:active {
                color: crimson;
            }
        }
    }
}*/
.kardex-table .ui-datatable .ui-datatable-thead > tr > th,
.kardex-table .ui-datatable .ui-datatable-tfoot > tr > td,
.kardex-table .ui-datatable .ui-datatable-data > tr > td {
  padding: 10px !important;
}

.component-result {
  overflow-x: auto;
  height: 90vh;
  padding: 0px;
  margin-top: 10px;
  padding: 1rem 1.1rem;
}
.component-result a {
  width: 100%;
  padding: 0;
  cursor: pointer;
}

/*main*/
/*:not(#search-bar)*/
main.ng2.scrollable-container {
  overflow: hidden;
}
main.ng2.scrollable-container #search-bar {
  pointer-events: auto;
  opacity: 1;
}
main.ng2.scrollable-container #search-item,
main.ng2.scrollable-container .header-item {
  display: none;
}

sidebar-notification-cmp #search-bar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.97);
  z-index: 1010;
  padding: 20px;
  overflow: auto;
  width: 100%;
}
sidebar-notification-cmp #search-bar .search-title {
  display: none;
}
sidebar-notification-cmp #search-bar form {
  width: 100%;
  position: relative;
  max-height: 100%;
  margin-top: 20px;
  padding: 0;
  margin-bottom: 0px;
}

.selected-home-item {
  background: #fff;
}
.selected-home-item span {
  color: #5faee3 !important;
}

.breadcrumb {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  color: #282828;
  text-transform: uppercase;
  width: 100%;
}
.breadcrumb .active {
  color: #5faee3 #5faee3;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #282828;
  content: ">";
  padding-right: 1rem;
  padding-left: 1rem;
}

.hm-icon {
  line-height: 36px;
}

.ui-datatable tbody {
  outline: 0;
}

.action i {
  cursor: pointer;
  color: #3a8fc8;
}

.action-menu {
  position: absolute;
  top: 0 !important;
  left: auto !important;
  width: 200px;
  z-index: 1060;
  float: left;
  padding: 10px 0;
  margin: 0px;
  list-style: none;
  text-align: left;
  background-color: #edf0f5;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 10px #00000033;
  background-clip: padding-box;
  padding: 0 10px;
  right: 0 !important;
  will-change: transform !important;
  transform: translate3d(-10px, 0px, 10px) !important;
}
.action-menu li {
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #dddddd;
}
.action-menu li a {
  color: #000 !important;
}
.action-menu li i {
  padding: 0 10px;
}
.action-menu li:last-child {
  border-bottom: 0;
}

#patient-name {
  color: black;
}

.group-box {
  border: 1px solid #ddd;
  padding: 10px 0;
}

.table-header-with-icon a {
  color: #282828;
}
.table-header-with-icon a i {
  padding: 0 5px 5px 10px;
  color: #5e5e5e;
}

.ui-datatable tbody > tr.ui-widget-content.ui-state-highlight .action i {
  color: #fff;
}

.background-white {
  background-color: #fff !important;
}

.uppercase {
  text-transform: uppercase;
}

.without-padding {
  padding: 0 !important;
}

.without-padding-bottom {
  padding-bottom: 0 !important;
}

.with-padding {
  padding: 15px;
}

.with-horizontal-padding {
  padding: 15px 0;
}

.with-cursor {
  cursor: pointer;
}

.red-color {
  color: #ed2024;
}

.yellow-color {
  color: #eedc00;
}

.blue-color {
  color: #80c4f2;
}

.green-color {
  color: #82e27d;
}

.dark-green-color {
  color: #5bb95d;
}

.orange-color {
  color: #f7a12a;
}

.black-color {
  color: black;
}

/* NEW FEATURES */
.dropdown-button {
  font-size: 14px !important;
}

.ui-button-text-only .ui-button-text {
  padding: 1em !important;
}

.dashboard-calendar .fc-center {
  padding-right: 30px;
}
.dashboard-calendar .fc-corner-right {
  position: absolute !important;
  text-align: right;
  right: 0;
  width: calc(100% - 150px);
  margin-left: 100px !important;
  font-family: "Segoe UI light", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-size: 14px !important;
}

.ui-confirmdialog {
  z-index: 2000 !important;
}

.ui-button-text-only .ui-button-text {
  padding: 1em !important;
}

.amcharts-chart-div a {
  display: none !important;
}

.without-margin {
  margin: 0 !important;
}

.ui-datepicker-buttonbar .ui-button-text {
  padding: 0.25em 1em !important;
}

.hide-part-of-element input {
  background: transparent;
  border: 0;
  outline: none;
  width: 50px;
}
.hide-part-of-element:hover input {
  width: 100%;
  background-color: transparent;
}
.hide-part-of-element:hover span::after {
  content: "";
}
.hide-part-of-element span::after {
  content: " ....";
}

button.ui-spinner-button {
  border: 0 !important;
  background: transparent !important;
}
button.ui-spinner-button span {
  color: #409ace !important;
}
button.ui-spinner-button:hover {
  background: #409ace !important;
}
button.ui-spinner-button:hover span {
  color: #fff !important;
}

.ui-autocomplete .ui-autocomplete-input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}

.ui-column-filter.ui-dropdown label.ui-dropdown-label {
  cursor: pointer;
  text-align: left;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}
.ui-column-filter.ui-dropdown .ui-dropdown-trigger.ui-state-default.ui-corner-right {
  padding-top: 0.5em;
  height: 90%;
  margin: 1px 1px;
}

.ui-column-spinner-filter input {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  transition: background 0.2s linear 0s;
}

.action-dropdown-left ul {
  position: absolute;
  background: #edf0f5;
  right: 175px;
  width: 250px;
  top: 0;
}
.action-dropdown-left ul li {
  text-decoration: none;
}

.ui-table .ui-table-tbody > tr:hover {
  background-color: #e0e0e0 !important;
  color: #212121 !important;
}

.view-caption-action {
  display: none;
}

.view-caption-boder-bottom {
  border-bottom: 0;
}

@media screen and (max-width: 40em) {
  .view-caption-action {
    display: block;
  }

  .view-caption-boder-bottom {
    border-bottom: 1px solid #ffffff;
  }
}
.column-small-width {
  width: 6rem;
}

.ui-table-wrapper a {
  color: #2399e5 !important;
}
.ui-table-wrapper .ui-calendar {
  margin: 0 !important;
}

.ui-spinner.ui-widget.ui-corner-all {
  width: 100%;
}

.header-item-link a {
  color: #409ace !important;
  text-decoration: underline !important;
}

.ui-chkbox-box.ui-state-active,
.ui-radiobutton-box.ui-state-active {
  color: #fff !important;
}

.light-blue-background-item .ui-panel-titlebar {
  background: #607d8b !important;
  color: #fff !important;
  border-bottom: 1px solid #fff;
}
.light-blue-background-item .button-color {
  background-color: #607d8b;
  border: 1px solid #607d8b;
}

.light-blue-background-item .header-title {
  background-color: #eceff1;
  color: #000;
  padding: 5px;
}

.light-blue-background-item .ui-panel .ui-panel-content {
  padding: 0 !important;
}

.light-blue-background-item .ui-panel-content-wrapper {
  border: 1px solid #d5d5d5 !important;
}

.sub-string-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.absolute-calendar-icon .ui-datepicker-trigger {
  position: absolute;
  right: 5px !important;
  top: 5px;
  left: auto !important;
}

.user-Profile .user-name .dropdown-backdrop {
  position: relative !important;
}

.ui-growl {
  z-index: 13000 !important;
}

.form-group-calendar .ui-calendar {
  margin-top: 0 !important;
}
.form-group-calendar .ui-calendar .ui-inputtext {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 35px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.form-group-dropdown .ui-dropdown.ui-widget.ui-state-default.ui-corner-all.ui-helper-clearfix {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.form-group-dropdown .ui-dropdown-label {
  background-color: #ffffff;
  background-image: none;
  border: 0px solid rgba(0, 0, 0, 0.07);
  -webkit-appearance: none;
  color: #2c2c2c;
  outline: 0;
  height: 27px;
  padding: 9px 12px;
  line-height: normal;
  vertical-align: middle;
  min-height: 27px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.ui-tooltip {
  z-index: 99999 !important;
}

.href-color {
  color: #0275d8 !important;
}

.disable-calendar {
  pointer-events: none;
}
.disable-calendar input {
  background-color: #e1e1e1 !important;
  opacity: 1;
  cursor: not-allowed !important;
  color: #8e8e8e !important;
}

.col-button > .ui-cell-data > button,
.col-button > .ui-column-title > button {
  padding: 0 0.5rem;
}

.second-dialog {
  top: 25%;
  max-width: 50%;
}

.disable-items:not(.silent-loader) * {
  pointer-events: none !important;
}

.disable-loader .loader-default-wrapper {
  display: none !important;
}

.silent-loader .loader-default-wrapper {
  display: none !important;
}