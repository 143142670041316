﻿@import "./conf/conf";

.ui-tabview .ui-tabview-nav > li.ui-state-default {
    padding: 0px 5%;
    color: #bbbbbb;
    border-bottom: #bbbbbb;
    background: transparent;
    outline: none;
}

.ui-tabview .ui-tabview-nav > li:not(.ui-state-active):not(.ui-state-disabled):hover {
    background: transparent;
}

.ui-tabview .ui-tabview-nav > li.ui-state-active {
    background: transparent;
    color: #636363;
    border-bottom: 2px solid #0a6ebd;
}

.ui-tabview .ui-tabview-nav {
    border: 0;
}

.ui-tabview .ui-tabview-nav li a {
    display: inline-block;
    color: #bbbbbb;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    -webkit-transition: all;
    -o-transition: all;
    transition: all;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
}

.ui-tabview .ui-tabview-nav li.ui-tabview-selected a,
.ui-tabview .ui-tabview-nav li.ui-state-disabled a,
.ui-tabview .ui-tabview-nav li.ui-state-processing a {
    cursor: pointer;
    color: #555555;
}

.tab-content-body {
    margin-bottom: 25px;
    position: relative;
}

.main-tab-view {
    .ui-tabview {
        background: inherit;
        padding: 0px;

        .ui-tabview-panel {
            background: #fff;
            margin: 0;
        }
    }

    .ui-tabview .ui-tabview-nav li a {
        border-bottom: 2px solid;
        padding-left: 25px;
        padding-right: 25px;
    }

    .ui-tabview .ui-tabview-nav > li.ui-state-active {
        background: transparent;
        color: #6a6c6f;
        border: none !important;
    }

    .ui-tabview .ui-tabview-nav li.ui-tabview-selected a {
        border-bottom: 2px solid $primary-color;
    }
}

.rtl {
    .ui-tabview .ui-tabview-nav > li.ui-state-default {
        float: right;
    }
}
